import React, {
  PropsWithChildren, useEffect, useMemo, useState,
} from 'react';
import { Route, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from '../views/Home';
import SalePage from '../views/Sale';
import BurgerMenu from '../shared/components/BurgerMenu/BurgerMenu';
import Footer from '../shared/components/Footer';
import Header from '../shared/components/Header';
import { BG_IMAGES, ROUTES } from '../shared/helpers/constans';

import { useSaleServiceContext } from '../providers/sale-provider/sale-provider';

const Container = styled.div<PropsWithChildren<{loading?: string}>>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(${(BG_IMAGES.normal)});
  background-position: center 5.25rem;
  background-repeat: no-repeat;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    background-position: center 8rem;
  `}
`;

const Pages = styled.div`
  flex: 1;
  flex-direction: column;
  position: relative;
  max-width: 100vw;
  z-index: 1;
`;

const AppRoutes: React.FC = () => {
  const { loading, setCurrentSaleId } = useSaleServiceContext();
  const { pathname } = useLocation();
  const id = useMemo(() => pathname.substring(pathname.lastIndexOf('/'))[1], [pathname]);

  useEffect(() => {
    setCurrentSaleId(Number(id));
  }, [id, setCurrentSaleId]);

  // TODO: discuss redirect page
  const [isOpened, setIsOpened] = useState<boolean>(false);
  return (
    <Container
      loading={loading ? loading.toString() : undefined}
    >
      <Pages>
        <BurgerMenu isOpened={isOpened} />
        <ToastContainer />
        <Header
          isOpened={isOpened}
          setIsOpened={setIsOpened}
          id={id}
        />
        <Route exact path={ROUTES.HOME}>
          <Home />
        </Route>
        <Route path={ROUTES.SWAP_BY_ID}>
          <SalePage />
        </Route>
        {/* <Redirect to={ROUTES.HOME} /> */}
      </Pages>
      <Footer
        loading={loading}
      />
    </Container>
  );
};

export default AppRoutes;
