import React from 'react';
import SignOutModal, { ISignOutModal } from 'shared/components/Modals/SignOutModal/SignOutModal';
import SearchModal, { ISearchModal } from 'shared/components/Modals/SearchModal';
import FilterModal, { IFilterModal } from 'shared/components/Modals/FilterModal/FilterModal';

export enum EModals {
  SIGN_OUT_MODAL = 'SIGN_OUT_MODAL',
  SEARCH_MODAL = 'SEARCH_MODAL',
  FILTER_MODAL = 'FILTER_MODAL',
  EMPTY = 'EMPTY',
}

export type IModalsProps = {
  [EModals.SIGN_OUT_MODAL]: any;
  [EModals.SEARCH_MODAL]: any;
  [EModals.FILTER_MODAL]: any;
  [EModals.EMPTY]: any;
};
export type ModalProps<T extends EModals> = Omit<IModalsProps[T], 'closeModal'>;

type IModals = {
  [EModals.SIGN_OUT_MODAL]: React.FC<ISignOutModal>;
  [EModals.SEARCH_MODAL]: React.FC<ISearchModal>;
  [EModals.FILTER_MODAL]: React.FC<IFilterModal>;
  [EModals.EMPTY]: any;
};

export const MODALS: IModals = {
  [EModals.SIGN_OUT_MODAL]: SignOutModal,
  [EModals.SEARCH_MODAL]: SearchModal,
  [EModals.FILTER_MODAL]: FilterModal,
  [EModals.EMPTY]: null,
};

export type ModalContextType = {
  modal: EModals;
  props: IModalsProps[EModals];
  showModal: <T extends EModals>(modal: T, props: ModalProps<T>) => void;
  closeModal: () => void;
};

export interface IInternalProviderModalState {
  modal: EModals;
  props: IModalsProps[EModals];
}

export const initialModalState: IInternalProviderModalState = {
  modal: EModals.EMPTY,
  props: null,
};
