import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { getUserDataByVesting } from '../helpers/vesting';
import {
  ETypeVesting, IParticipantModel, IUserDataByVesting, IVesting,
} from '../interfaces/interface';

const UseVestingDataStorage = (
  depositDecimal: number,
  vesting: IVesting[],
  vestingType: ETypeVesting | null,
  purchase: string | number,
  participantData: IParticipantModel,
  isClaimAvailable: boolean,
  isUpdated: boolean,
  setIsUpdated: Dispatch<SetStateAction<boolean>>,
) => {
  const oldUserDataByVesting = getUserDataByVesting(
    depositDecimal,
    vesting,
    vestingType,
    purchase,
    isClaimAvailable,
    participantData,
  );
  const [userDataByVesting, setUserDataByVesting] = useState<IUserDataByVesting>(oldUserDataByVesting);

  useEffect(() => {
    const newUserDataByVesting = getUserDataByVesting(
      depositDecimal,
      vesting,
      vestingType,
      purchase,
      isClaimAvailable,
      participantData,
    );
    setUserDataByVesting(newUserDataByVesting);
    setIsUpdated(false);
  }, [isClaimAvailable, participantData, purchase, vesting, vestingType, isUpdated, setIsUpdated]);
  return ({
    userDataByVesting,
  });
};

export default UseVestingDataStorage;
