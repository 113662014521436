import React from 'react';
import SaleLabel from './styles';

export interface ILabelModel {
  saleStatus: string;
}

const StatusLabel: React.FC<ILabelModel> = (
  {
    saleStatus,
  },
) => {
  return (
    <>
      <SaleLabel.Status roundStatus={saleStatus}>
        {saleStatus}
      </SaleLabel.Status>
    </>
  );
};

export default StatusLabel;
