import { ReactComponent as BookIcon } from 'assets/images/book.svg';
import { ReactComponent as LightningIcon } from 'assets/images/lightning.svg';
import { ReactComponent as LinkIcon } from 'assets/images/link.svg';
import { ReactComponent as ListIcon } from 'assets/images/list.svg';
import { IBurgerMenu } from './interfaces';

export const burgerMenuList: IBurgerMenu[] = [
  {
    title: 'General.BurgerMenu.About',
    logo: LightningIcon,
    link: '',
  },
  {
    title: 'General.BurgerMenu.Whitepaper',
    logo: BookIcon,
    link: '',
  },
  {
    title: 'General.BurgerMenu.RisksAndRules',
    logo: ListIcon,
    link: '',
  },
];

export const launchpadsList: IBurgerMenu[] = [
  {
    title: 'General.BurgerMenu.OpenMars',
    logo: LinkIcon,
    link: 'https://mars.bocachica.io/',
  },
];
