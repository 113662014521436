import React, { Dispatch, SetStateAction } from 'react';
import SaleStyles from 'views/Sale/styles';
import { IAmountInputError } from 'views/Sale/interfaces';
import DepositSectionStyle from './styles';
import AmountInputContainer from '../AmountInputContainer/AmountInputContainer';
import { IParticipantModel, ISaleModel } from '../../interfaces/interface';
import { EStatuses } from '../../helpers/constans';
import HandleSaleActionButton from '../HandleSaleActionButton/HandleSaleActionButton';
import Timer from '../Timer';
import AdditionalInfoText from './AdditionalInfoText';
import Translate from '../Translate/Translate';
import { detectTimestamp } from '../../helpers/util';

interface IDepositSection {
  amount: string;
  participantData: IParticipantModel | null;
  isParticipant: boolean;
  setAmount: Dispatch<SetStateAction<string>>
  currentSale: ISaleModel;
  purchase: number | string;
  error: IAmountInputError
  setError: Dispatch<SetStateAction<IAmountInputError>>
  setInputError: Dispatch<SetStateAction<IAmountInputError>>
  newStatus: EStatuses
  isChecked: boolean;
  deposit: () => Promise<void>;
  checkRisks: ({ target }: { target: HTMLInputElement; }) => void;
  sale: ISaleModel
}
const DepositSection: React.FC<IDepositSection> = ({
  participantData,
  currentSale,
  setAmount,
  purchase,
  amount,
  error,
  setError,
  setInputError,
  isParticipant,
  newStatus,
  isChecked,
  deposit,
  checkRisks,
  sale,
}) => {
  return (
    <DepositSectionStyle.Container>
      <DepositSectionStyle.ActionContainer>
        <AmountInputContainer
          participantData={participantData}
          isParticipant={isParticipant}
          currentSale={currentSale}
          setAmount={setAmount}
          amount={amount}
          purchase={purchase}
          error={error}
          setError={setError}
          setInputError={setInputError}
          newStatus={newStatus}
        />
        {
          participantData && (
            <AdditionalInfoText
              amount={amount}
              participantData={participantData}
              sale={sale}
              error={error}
            />
          )
        }
        <HandleSaleActionButton
          currentSale={currentSale}
          deposit={deposit}
          error={error}
          value={amount}
          isChecked={isChecked}
          newStatus={currentSale.status}
        />
        {
          newStatus !== EStatuses.CLOSED && (
            <Timer
              status={newStatus}
              time={detectTimestamp(sale.startDate, sale.endDate, newStatus)}
            />
          )
        }
      </DepositSectionStyle.ActionContainer>
      <SaleStyles.Approve>
        <SaleStyles.AcceptInput>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={checkRisks}
          />
          <svg viewBox="0 0 21 21">
            <polyline points="5 10.75 8.5 14.25 16 6" />
          </svg>
        </SaleStyles.AcceptInput>
        <SaleStyles.ApproveDscr>
          <Translate _key="Sale.Approve" />
        </SaleStyles.ApproveDscr>
      </SaleStyles.Approve>
    </DepositSectionStyle.Container>
  );
};

export default DepositSection;
