import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import {
  ETypeVesting, IParticipantModel, IUserDataByVesting, IVesting,
} from '../interfaces/interface';
import { detectActiveVesting } from '../helpers/vesting';
import { ONE_SECOND } from '../helpers/constans';

const useVestingStatus = (
  vesting: IVesting[],
  vestingType: ETypeVesting | null,
  depositDecimal: number,
  purchase: string | number,
  isClaimAvailable: boolean,
  participantData: IParticipantModel,
  userDataByVesting: IUserDataByVesting,
  setIsUpdated: Dispatch<SetStateAction<boolean>>,
) => {
  const [time, setTime] = useState<number>(0);

  const [vestingEnded, setVestingEnded] = useState<boolean>(false);

  useEffect(() => {
    const activeVesting = detectActiveVesting(userDataByVesting.vestingArray, vestingType);
    const previousVestingEndedTimestamp = vesting.every((el) => el.timestamp < Date.now());
    setTime(activeVesting?.timestamp || 0);
    setVestingEnded(previousVestingEndedTimestamp);
    const updateStatus = () => {
      const updatedActiveVesting = detectActiveVesting(userDataByVesting.vestingArray, vestingType);
      if (!updatedActiveVesting || updatedActiveVesting.timestamp >= Date.now()) return;
      if (updatedActiveVesting) {
        setTime(updatedActiveVesting.timestamp);
      } else {
        const vestingEndedTimestamp = userDataByVesting.vestingArray.every((el) => el.timestamp < Date.now());
        setVestingEnded(vestingEndedTimestamp);
      }
      setIsUpdated(true);
    };
    updateStatus();
    const interval = setInterval(updateStatus, ONE_SECOND);
    return () => clearInterval(interval);
  }, [
    isClaimAvailable,
    participantData,
    purchase,
    vesting,
    vestingType,
    userDataByVesting.vestingArray,
    vestingEnded,
    setIsUpdated,
  ]);
  return ({
    time, vestingEnded,
  });
};

export default useVestingStatus;
