import React from 'react';
import styled from 'styled-components';
import { NUMBER_PLACEHOLDER_ROW, NUMBER_PLACEHOLDER_VALUE } from '../../helpers/constans';
import DescriptionParagraph from './DescriptionParagraph';

const Container = styled.div`
  padding: 1rem;
  box-shadow: 0 0.25rem 0.5rem -0.25rem ${({ theme }) => theme.shadowWrapper};
  border-radius: 1rem;
  margin: 0 auto;
  width: 43.125rem;
  background: ${({ theme }) => theme.globalWhite};
  &:first-child {
    margin-top: 0;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%
 `}
`;

const ImageContainer = styled.div`
  height: 3rem;
  width: 100%;
  background-color: ${({ theme }) => theme.opacityGrey};
  border-radius: 0.25rem;
`;

const Title = styled.div`
  margin-top: 1.375rem;
  background-color: ${({ theme }) => theme.opacityGrey};
  border-radius: 0.25rem;
  width: 8.063rem;
  height: 0.75rem;
`;

const IconRow = styled.div`
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.opacityGrey};
`;

const Stage = styled.div`
  width: 3.938rem;
  height: 0.5rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.opacityGrey};
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 3rem;
  margin-top: 2rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: auto;
    flex-direction: column;
 `}
`;

const Input = styled.div`
  width: 24.625rem;
  height: 100%;
  border: 2px solid ${({ theme }) => theme.opacityGrey};
  border-radius: 0.25rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 3rem;
 `}
`;

const Button = styled.div`
  width: 15rem;
  height: 100%;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.opacityGrey};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 3rem;
    margin-top: 1.5rem;
 `}
`;

const InfoWrapper = styled.div`
  padding: 0 1rem 2rem 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0;
 `}
`;

const InfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 3rem;
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 9.813rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
      width: unset;
      flex: 50%
    `}
  &:nth-child(n+3) {
    ${({ theme }) => theme.mediaWidth.upToSmall`
      margin-top: 1.5rem;
    `}
  }
  &:nth-of-type(3n+0) {
    align-items: flex-end;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      align-items: flex-start;
    `}
  }
  &:nth-of-type(2),
  &:nth-of-type(5) {
    align-items: center;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      align-items: flex-end;
    `}
}
  &:nth-last-child(-n+3) {
    margin-top: 1.5rem;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      align-items: flex-end;
    `}
  }
  div:nth-child(4n+2),
  div:nth-child(4n+3) {
    ${({ theme }) => theme.mediaWidth.upToSmall`
      align-items: flex-end;
    `}
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
      align-items: flex-start;
    `}
  &:nth-last-child(2) {
    ${({ theme }) => theme.mediaWidth.upToSmall`
      align-items: flex-start;
    `}
  }
`;

const InfoName = styled.div`
  width: 2.688rem;
  height: 0.5rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.opacityGrey};
`;

const InfoValue = styled.div`
  width: 4.875rem;
  height: 0.75rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.opacityGrey};
  margin-top: 0.75rem;
`;

const TimeRow = styled.div`
  margin-top: 3rem;
  width: 100%;
  height: 1rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.opacityGrey};
`;

const TimeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  & div {
    &:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
`;

const BigSkeleton: React.FC = () => {
  return (
    <Container>
      <ImageContainer />
      <Title />
      <IconRow>
        <Icon />
        <Stage />
      </IconRow>
      <InputContainer>
        <Input />
        <Button />
      </InputContainer>
      <InfoWrapper>
        <DescriptionParagraph />
        <InfoBlock>
          {NUMBER_PLACEHOLDER_ROW.map((row, index) => (
            <InfoItem key={`row-index-${row + index}`}>
              <InfoName />
              <InfoValue />
            </InfoItem>
          ))}
        </InfoBlock>
        <TimeRow />
        <TimeWrapper>
          {NUMBER_PLACEHOLDER_VALUE.map((row, index) => (
            <InfoItem key={`row-index-${row + index}`}>
              <InfoName />
              <InfoValue />
            </InfoItem>
          ))}
        </TimeWrapper>
        <TimeRow />
        <DescriptionParagraph />
      </InfoWrapper>
    </Container>
  );
};

export default BigSkeleton;
