import React from 'react';
import useDisableBodyScroll from 'shared/hooks/useDisableBodyScroll';
import { socials } from 'shared/helpers/constans';
import { burgerMenuList, launchpadsList } from './util';

import BurgerStyles from './styles';
import Translate from '../Translate/Translate';
import SocialNetwork from '../SocialNetwork';

interface IBurgerMenu {
  isOpened: boolean,
}

const openLink = (url: string) => {
  window.open(url, '_blank', 'noopener noreferrer');
};

const BurgerMenu: React.FC<IBurgerMenu> = ({ isOpened }) => {
  useDisableBodyScroll(isOpened);
  return (
    <BurgerStyles.Container isOpen={isOpened}>
      <BurgerStyles.Column>
        {burgerMenuList.map(({ title, logo: LogoComponent, link }) => (
          <BurgerStyles.Row key={title} onClick={() => openLink(link)}>
            <LogoComponent />
            <Translate _key={title} />
          </BurgerStyles.Row>
        ))}
      </BurgerStyles.Column>
      <BurgerStyles.Line />
      <BurgerStyles.Column>
        {launchpadsList.map(({ title, logo: LogoComponent, link }) => (
          <BurgerStyles.Row key={title} onClick={() => openLink(link)}>
            <LogoComponent />
            <Translate _key={title} />
          </BurgerStyles.Row>
        ))}
      </BurgerStyles.Column>
      <SocialNetwork socials={socials} />
    </BurgerStyles.Container>
  );
};

export default BurgerMenu;
