import Big from 'big.js';
import {
  ERoundType, EStatuses, ONE_MINUTE, THOUSANDS_MULTIPLIER, TIMESTAMP,
} from './constans';
import {
  EFilterByParticipation,
  EFilterByResult,
  ICheckSaleUpdate,
  IClaimModel,
  IFilter, IParseDate,
  ISaleModel,
} from '../interfaces/interface';

export function chooseCorrectRoundType(type: string): ERoundType {
  if (type === ERoundType.AMOUNT) {
    return ERoundType.AMOUNT;
  }
  return ERoundType.SUBSCRIPTION;
}

export const detectStatus = (
  startDate: number,
  endDate: number,
  saleType: string | undefined,
  currentDeposit: string | number | undefined,
  targetDeposit: number | string | undefined,
) => {
  const nowDate = Math.floor(Date.now() / THOUSANDS_MULTIPLIER);
  if (nowDate > endDate || (saleType === ERoundType.AMOUNT && Big(currentDeposit || 0).gte(targetDeposit || 0))) {
    return EStatuses.CLOSED;
  }
  if (startDate > nowDate) return EStatuses.PENDING;
  if (endDate > nowDate) return EStatuses.ACTIVE;
  return EStatuses.CLOSED;
};

export const removeTrailingZeros = (amount: string) => {
  if (amount.includes('.') || amount.includes(',')) {
    return amount.replace(/\.?0*$/, '');
  }
  return amount;
};

export function lamportsToAmount(
  lamports: number | string,
  decimals: number,
  precision?: number,
): string {
  const amount = new Big(lamports).div(new Big(10).pow(decimals));
  return precision ? amount.toPrecision(precision).toString() : amount.toString();
}

export const parseTokenAmount = (value: string, decimals: number): string => value
  && Big(value).times(Big(10).pow(decimals)).toFixed(0);

export const displayAmount = (amount: string | number, decimals?: number) => {
  const formateAmount = lamportsToAmount(amount, decimals || 0);
  const amountBig = new Big(formateAmount);
  if (amountBig.eq('0')) return '0';
  if (amountBig.lte('0.01')) return '>0.01';
  return `${removeTrailingZeros(amountBig.toFixed(3))}`;
};

export const displayBalance = (amount: string) => {
  const amountBig = new Big(amount);
  if (amountBig.eq('0')) return '0';
  if (amountBig.lte('0.0001')) return '>0.0001';
  return `${(amountBig.toFixed(3))}`;
};

export function transformPublicKey(publicKey: string): string | undefined {
  return publicKey ? `${publicKey?.slice(0, 4)}...${publicKey?.slice(publicKey.length - 4, publicKey.length)}` : '';
}

export const formatAmount = (number:number) => (number > 9 ? number.toString() : `0${number}`);

export const formatCountdown = (diffInSeconds: any) => {
  if (diffInSeconds <= 1) {
    return {
      days: formatAmount(0),
      hours: formatAmount(0),
      minutes: formatAmount(0),
      seconds: formatAmount(0),
    };
  }
  const days = Math.floor(diffInSeconds / TIMESTAMP.ONE_DAY);
  const hours = Math.floor((diffInSeconds - days * TIMESTAMP.ONE_DAY) / TIMESTAMP.ONE_HOUR);
  const minutes = Math.floor(
    (diffInSeconds - days * TIMESTAMP.ONE_DAY - hours * TIMESTAMP.ONE_HOUR) / TIMESTAMP.ONE_MINUTE,
  );
  const seconds = diffInSeconds - days * TIMESTAMP.ONE_DAY - hours
    * TIMESTAMP.ONE_HOUR - minutes * TIMESTAMP.ONE_MINUTE;
  return {
    days: formatAmount(days),
    hours: formatAmount(hours),
    minutes: formatAmount(minutes),
    seconds: formatAmount(seconds),
  };
};

export const detectTimestamp = (startDate: number, endDate: number, status: string) => {
  if (status === EStatuses.PENDING) {
    return startDate;
  }
  return endDate;
};

export const calculatePrice = (
  targetDeposit: number | string,
  targetDistribute: number,
) => {
  return new Big(targetDeposit).div(new Big(targetDistribute)).toString();
};

export const getTypeClaim = (
  status: string,
  isClaimAvailable: boolean,
  isRefundAvailable: boolean,
  claimValue: Big,
  refundValue: Big,
  isRefunded: boolean,
): IClaimModel => {
  let claimAvailable = false;
  let refundAvailable = false;
  if (status !== EStatuses.CLOSED) return { claimAvailable, refundAvailable };
  if (claimValue.gt(0) && isClaimAvailable) claimAvailable = true;
  if (refundValue.gt(0) && isRefundAvailable && !isRefunded) refundAvailable = true;
  return { claimAvailable, refundAvailable };
};

export const toArray = (map: { [key: string]: any }) => Object.values(map);

export const getSalesByFilter = (
  sales: ISaleModel[],
  filter: IFilter,
) => {
  const { filterByStatus, filterByParticipation, filterByResult } = filter;
  const filters: { key: string, value:(EStatuses | EFilterByParticipation | EFilterByResult) }[] = [];
  if (filterByStatus !== EStatuses.ALL) filters.push({ key: 'filterByStatus', value: filterByStatus });
  if (filterByParticipation !== EFilterByParticipation.ALL) {
    filters.push({ key: 'filterByParticipation', value: filterByParticipation });
  }
  if (filterByResult !== EFilterByResult.ALL) filters.push({ key: 'filterByResult', value: filterByResult });

  if (!filters.length) return sales;

  return sales.filter((sale) => !filters.some(
    ({ key, value }) => {
      return sale[key as keyof typeof sale] !== value.toLowerCase();
    },
  ));
};

export const getUserDataFilter = (typeClaim: IClaimModel) => {
  const { claimAvailable, refundAvailable } = typeClaim;
  if (claimAvailable && refundAvailable) return EFilterByResult.BOTH;
  if (claimAvailable) return EFilterByResult.CLAIM;
  if (refundAvailable) return EFilterByResult.REFUND;
  return EFilterByResult.ALL;
};

export const ParseDate = (
  date: string | number,
): IParseDate => {
  const newDate = new Date(date);
  const month = newDate.toLocaleString('en', { month: 'short' });
  const dateObj = {
    year: newDate.getUTCFullYear(),
    month: formatAmount(newDate.getUTCMonth()),
    day: formatAmount(newDate.getUTCDate()),
    hours: formatAmount(newDate.getUTCHours()),
    minutes: formatAmount(newDate.getUTCMinutes()),
  };
  return {
    year: dateObj.year,
    month,
    day: dateObj.day,
    hours: dateObj.hours,
    minutes: dateObj.minutes,
  };
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const checkSaleShouldUpdate = (sale: ICheckSaleUpdate | null, status: EStatuses) => {
  if (!sale) return false;
  const dateNow = Date.now();
  if (status === EStatuses.ACTIVE) return true;
  const dateBeforeStart = sale.startDate - ONE_MINUTE;
  const dateAfterEnd = sale.endDate + ONE_MINUTE;
  if (status === EStatuses.PENDING && dateBeforeStart < dateNow) return true;
  if (status === EStatuses.CLOSED && dateAfterEnd > dateNow) return true;
  if (
    sale.distributionIn && sale.distributionIn.active && sale.distributionIn.timestamp < dateNow
  ) return true;
  return false;
};

export const calcStrokeDasharray = (radius: number) => 2 * Math.PI * radius;
