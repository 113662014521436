import React from 'react';
import styled from 'styled-components';
import { EStatuses } from 'shared/helpers/constans';
import { ISaleModel } from 'shared/interfaces/interface';
import { useWallet } from '@solana/wallet-adapter-react';
import { ReactComponent as WalletLogo } from 'assets/images/wallet.svg';
import { ReactComponent as ArrowUp } from 'assets/images/arrow-up.svg';
import { EWalletNames } from 'shared/helpers/constant/wallet';
import { IAmountInputError } from 'views/Sale/interfaces';
import Translate from '../Translate/Translate';

export interface IBtnActionModel {
  currentSale: ISaleModel | null;
  deposit: () => Promise<void>;
  error: IAmountInputError;
  value: string;
  isChecked: boolean;
  newStatus: EStatuses;
}

export const ArrowBtnIcon = styled(ArrowUp)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem;
`;

export const Button = styled.button`
  width: 15rem;
  height: 3rem;
  border: unset;
  border-radius: 0.331rem;
  color: ${({ theme }) => theme.globalWhite};
  font-family: ${({ theme }) => theme.interSemiBold}, serif;
  font-size: 1rem;
  cursor: pointer;
  background: ${({ theme }) => theme.globalBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: self-end;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    margin-top: 1.5rem;
 `}
  &:disabled {
    background: ${({ theme }) => theme.globalGrey};
  }
`;

export const WalletIcon = styled(WalletLogo)`
  width: 1.313rem;
  height: 1.125rem;
  margin-right: 0.813rem;
`;

const HandleSaleActionButton: React.FC<IBtnActionModel> = (
  {
    currentSale,
    deposit,
    error,
    value,
    isChecked,
    newStatus,
  },
) => {
  const { publicKey, select } = useWallet();
  const walletConnect = (walletName: any) => select(walletName);
  const disabledDepositButton = newStatus !== EStatuses.ACTIVE || !error.valid || !value || !isChecked;
  if (publicKey && currentSale) {
    return (
      <Button
        disabled={disabledDepositButton}
        onClick={deposit}
      >
        <ArrowBtnIcon />
        <Translate _key="Sale.Deposit" />
      </Button>
    );
  }
  return (
    <Button
      onClick={() => walletConnect(EWalletNames.PHANTOM)}
    >
      <WalletIcon />
      <Translate _key="General.Header.ConnectWallet" />
    </Button>
  );
};

export default HandleSaleActionButton;
