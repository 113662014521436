import React from 'react';
import styled from 'styled-components';

const Description = styled.div`
  margin-top: 2rem;
  & div {
    margin-top: 1rem;
    width: 100%;
    height: 0.5rem;
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.opacityGrey};
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      width: 16.523rem;
    }
  }
`;

const DescriptionParagraph: React.FC = () => {
  return (
    <Description>
      <div />
      <div />
      <div />
    </Description>
  );
};

export default DescriptionParagraph;
