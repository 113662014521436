import React from 'react';
import { EVestingStatus } from '../../interfaces/interface';
import VestingStyles from './styles';
import ProgressCircle from './ProgressCircle';

interface ICurrentLogo{
  status: EVestingStatus,
  endPeriod: number,
  startPeriod?: number,
}

const CurrentLogo: React.FC<ICurrentLogo> = (
  {
    status,
    startPeriod,
    endPeriod,
  },
) => {
  switch (status) {
    case EVestingStatus.EMPTY:
      return <VestingStyles.ClaimedIcon />;
    case EVestingStatus.AVAILABLE_TO_CLAIM:
      return <VestingStyles.NotClaimedIcon />;

    default: {
      if (status === EVestingStatus.ACTIVE && startPeriod) {
        return (
          <ProgressCircle
            startPeriod={startPeriod}
            endPeriod={endPeriod}
          />
        );
      }
      return <VestingStyles.Circle />;
    }
  }
};

export default CurrentLogo;
