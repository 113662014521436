import React, { useEffect, useState } from 'react';
import Round from 'shared/components/Round/Round';
import {
  ISaleModel,
}
  from 'shared/interfaces/interface';
import { useSaleServiceContext } from 'providers/sale-provider/sale-provider';
import ModalActionRow from 'shared/components/ModalActionRow/ModalActionRow';
import { getSalesByFilter } from 'shared/helpers/util';
import {
  Container,
  RoundsWrapper,
}
  from './styles';
import { NUMBER_PLACEHOLDER_CARD } from '../../shared/helpers/constans';
import SmallSkeleton from '../../shared/components/Placeholder/SmallSkeleton';

const Home: React.FC = () => {
  const { sales, filterType, loading } = useSaleServiceContext();
  const [currentSales, setCurrentSales] = useState<ISaleModel[]>(sales);
  useEffect(() => {
    const newSales = getSalesByFilter(
      sales,
      filterType,
    );
    if (!newSales) return;
    setCurrentSales(newSales);
  }, [sales, filterType]);
  return (
    <Container>
      <ModalActionRow
        loading={loading}
      />
      <RoundsWrapper>
        {
          loading
            ? NUMBER_PLACEHOLDER_CARD.map((card) => <SmallSkeleton key={card} />)
            : currentSales.map((sale) => (
              <Round
                key={sale.saleId}
                sale={sale}
              />
            ))
        }
      </RoundsWrapper>
      <div />
    </Container>
  );
};

export default Home;
