import React from 'react';
import styled from 'styled-components';
import { ButtonConnect } from 'shared/components/Buttons';
import { useWallet } from '@solana/wallet-adapter-react';
import { ModalProvider } from 'providers/modal-provider/modal-provider';
import { EDimensions } from 'shared/helpers/constans';
import BlockhainDomainsImage from 'shared/components/BlockchainDomainsImage/BlockchainDomainsImage';

import { useSaleServiceContext } from 'providers/sale-provider/sale-provider';
import { useSendTransactionContext } from 'providers/sendTransactionProvider/sendTransactionProvider';
import { EModals } from 'providers/modal-provider/interfaces/interfaces';
import headerStyles from './styles';
import { EWalletNames } from '../../helpers/constant/wallet';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import LeftHeaderSide from './LeftHeaderSide/LeftHeaderSide';
import { transformPublicKey } from '../../helpers/util';
import Translate from '../Translate/Translate';

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  max-width: 90rem;
  margin: 0 auto;
  padding: 1.5rem 2rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    padding: 1.25rem 1rem 1.688rem;
  `}
`;

const ActionWrapper = styled.div`
  display: flex; 
  justify-content: space-between;
  align-items: center;
`;

interface IHeader {
  isOpened: boolean;
  setIsOpened: (v: boolean) => void;
  id: string | undefined;
}

const Header: React.FC<IHeader> = ({
  isOpened,
  setIsOpened,
  id,
}) => {
  const { balance } = useSendTransactionContext();
  const {
    sales,
    loading,
  } = useSaleServiceContext();
  const { showModal } = ModalProvider();
  const dimension = useWindowDimensions();
  const { publicKey, select } = useWallet();
  const walletConnect = (walletName: any) => select(walletName);
  const currentSale = sales.find((s) => s.saleId === Number(id));
  const checkLoading = loading ? loading.toString() : undefined;
  const publicNewKey = publicKey ? transformPublicKey(publicKey?.toString()) : <Translate _key="General.Header.ConnectWallet" />;

  const signInModal = () => {
    showModal(EModals.SIGN_OUT_MODAL, {
      currency: currentSale ? currentSale.currency : '',
      balance,
    });
  };

  const chooseConnectAction = () => {
    if (publicKey) {
      return signInModal();
    }
    return walletConnect(EWalletNames.PHANTOM);
  };
  return (
    <HeaderWrapper>
      { dimension !== EDimensions.SMALL
        && (
        <LeftHeaderSide
          isOpened={isOpened}
          setIsOpened={setIsOpened}
          checkLoading={checkLoading}
        />
        )}
      {
        dimension === EDimensions.SMALL && (
          <headerStyles.MobileWrapper>
            <LeftHeaderSide
              isOpened={isOpened}
              setIsOpened={setIsOpened}
              checkLoading={checkLoading}
            />
            <ButtonConnect
              onClick={chooseConnectAction}
              loading={checkLoading}
              disabled={loading}
            >
              <headerStyles.WalletLogo />
              {
                publicNewKey
              }
            </ButtonConnect>
          </headerStyles.MobileWrapper>
        )
      }
      { dimension !== EDimensions.SMALL
        && (
        <BlockhainDomainsImage
          loading={loading}
        />
        )}
      <ActionWrapper>
        {
          dimension !== EDimensions.SMALL && (
            <ButtonConnect
              onClick={chooseConnectAction}
              loading={checkLoading}
              disabled={loading}
            >
              <headerStyles.WalletLogo />
              {
                publicNewKey
              }
            </ButtonConnect>
          )
        }
      </ActionWrapper>
      { dimension === EDimensions.SMALL && !id
        && (
          <BlockhainDomainsImage
            loading={loading}
          />
        )}
    </HeaderWrapper>
  );
};

export default Header;
