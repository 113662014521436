import React, { useCallback, useState } from 'react';
import ModalWrapper from 'shared/components/Modals/ModalWrapper';
import useWindowDimensions from 'shared/hooks/useWindowDimensions';

import { useSaleServiceContext } from 'providers/sale-provider/sale-provider';
import styles from './styles';
import { EDimensions } from '../../../helpers/constans';
import Translate from '../../Translate/Translate';
import SearchResultRow from '../../SearchResultRow/SearchResultRow';
import { ISaleModel } from '../../../interfaces/interface';

export interface ISearchModal {
  closeModal: () => void;
}

const SearchModal: React.FC<ISearchModal> = ({
  closeModal,
}) => {
  const dimension = useWindowDimensions();
  const { sales } = useSaleServiceContext();
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredSales, setFilteredSales] = useState<ISaleModel[]>(sales);

  const onChange = useCallback((search: string) => {
    const salesBySearch = search !== ''
      ? sales.filter((sale) => {
        return sale.name.toLowerCase().includes(search.toLowerCase());
      })
      : sales;
    setSearchValue(search);
    setFilteredSales(salesBySearch);
  }, [sales]);

  return (
    <ModalWrapper
      closeModal={closeModal}
      isCentered={dimension !== EDimensions.SMALL}
      isFullWidth={dimension === EDimensions.SMALL}
      isFullHeight={dimension === EDimensions.SMALL}
    >
      <styles.Header>
        <styles.StyledInput>
          <styles.Search />
          <styles.Input
            value={searchValue}
            onChange={(value) => onChange(value.target.value)}
          />
          {searchValue && <styles.ClearSearch onClick={() => onChange('')} />}
        </styles.StyledInput>
        <styles.Close onClick={closeModal} />
      </styles.Header>
      <styles.Body>
        {
          filteredSales.length === 0 && (
            <styles.NoResultsWrapper>
              <styles.NoResultsIcon />
              <styles.NoResultsTitle>
                <Translate _key="NoResults.Title" />
                &nbsp;
                {`'${searchValue}'`}
              </styles.NoResultsTitle>
              <styles.NoResultsLabel>
                <Translate _key="NoResults.Label" />
              </styles.NoResultsLabel>
            </styles.NoResultsWrapper>
          )
        }
        <SearchResultRow
          searchValue={searchValue}
          close={closeModal}
          sales={filteredSales}
        />
      </styles.Body>
    </ModalWrapper>

  );
};

export default SearchModal;
