import { IDistributeScheduleModel, IParticipantModel } from 'shared/interfaces/interface';
import { BN } from '@project-serum/anchor';
import {
  ERoundType, FULL_CAPACITY,
} from 'shared/helpers/constans';
import { PublicKey } from '@solana/web3.js';
import Big from 'big.js';
import { parseTokenAmount } from '../../shared/helpers/util';

export const maxValue = (
  isParticipant: boolean,
  tokenOwner: number,
  purchaseAmount: number | string,
  depositedAmount: number,
  decimal: number,
  maxDeposit: number,
): string => {
  if (!tokenOwner || !purchaseAmount) return '0';
  let maxValueAmount;
  const deposited = isParticipant
    ? depositedAmount : 0;
  const currentBalance = new Big(parseTokenAmount(tokenOwner.toString(), decimal));
  const availableToDeposit = new Big(maxDeposit).minus(deposited ?? '0');
  if (currentBalance.lte(availableToDeposit.toFixed())) {
    maxValueAmount = currentBalance;
  } else {
    maxValueAmount = availableToDeposit;
  }
  if (!maxValueAmount) return '0';
  return maxValueAmount.toString();
};

const calculateSubscriptionAmount = (
  sale: {
    currentDeposit: number;
    targetDeposit: number;
    targetDistribute: number;
  },
  depositedAmount: number,
) => {
  return (depositedAmount * sale.targetDeposit * sale.targetDeposit)
      / sale.targetDistribute
      / sale.currentDeposit;
};
export const calculateAmountToClaim = (
  participant: IParticipantModel | null,
  sale: {
    saleType: string;
    currentDeposit: number;
    targetDeposit: number;
    targetDistribute: number;
    depositDecimal: number;
  },
) => {
  let amountToClaim: number | BN;
  if (!participant) return '0';
  const { depositedAmount } = participant;
  if (sale.saleType === ERoundType.AMOUNT || (sale.saleType === ERoundType.SUBSCRIPTION && sale.currentDeposit <= sale.targetDeposit)) {
    amountToClaim = (depositedAmount * Number(sale.targetDeposit)) / Number(sale.targetDistribute);
  } else {
    amountToClaim = calculateSubscriptionAmount(sale, depositedAmount);
  }
  return amountToClaim;
};

export const calculateEntireQuota = (
  distributeSchedule: IDistributeScheduleModel[],
) => {
  let prevTimestamp = 0;
  let entireQuota = 0;
  const currentTimestamp = Math.floor(Date.now() / 1000);

  // If distribute schedule is empty - 100% quota is available

  if (distributeSchedule.length === 0) {
    return FULL_CAPACITY;
  }

  for (let i = 0; i < distributeSchedule.length; i += 1) {
    const period = distributeSchedule[i];
    if (currentTimestamp >= period.timestamp) {
      // Summarizing quota of all past periods
      entireQuota += period.quota;
    } else {
      // Summarizing quota of all available steps in current period
      const timestampStep = (period.timestamp - prevTimestamp) / period.steps as number;
      const quotaPerStep = period.quota / period.steps as number;

      while (currentTimestamp > prevTimestamp) {
        entireQuota += quotaPerStep;
        prevTimestamp += timestampStep;
      }
      break;
    }
    prevTimestamp = period.timestamp;
  }
  return entireQuota;
};

export const displayClaimAmount = (
  participant: IParticipantModel | null,
  sale: {
    saleType: string;
    currentDeposit: number;
    targetDeposit: number;
    distributeSchedule: IDistributeScheduleModel[]
    targetDistribute: number;
    depositDecimal: number;
  },

) => {
  if (!participant) return '0';
  const entireAmountToClaim = Number(calculateAmountToClaim(participant, sale));
  const claimedAmount = participant.claimedAmount || 0;
  const entireQuota = Number(
    calculateEntireQuota(sale.distributeSchedule),
  );
  return (entireAmountToClaim * entireQuota) / FULL_CAPACITY - claimedAmount;
};

export const calculateAmountToRefund = (
  participant: IParticipantModel | null,
  sale: {
    saleType: string;
    currentDeposit: number;
    targetDeposit: number;
  },
): number | string => {
  let amountToRefund: number | BN;
  if (!participant) return '0';
  const { depositedAmount } = participant;
  if (sale.saleType === ERoundType.AMOUNT || (sale.saleType === ERoundType.SUBSCRIPTION && sale.currentDeposit <= sale.targetDeposit)) {
    amountToRefund = 0;
  } else {
    amountToRefund = (depositedAmount * (sale.currentDeposit - sale.targetDeposit)) / sale.currentDeposit;
  }
  return amountToRefund;
};

export const calculateUserAmounts = (
  participant: IParticipantModel | null,
  sale: {
  saleType: string;
  currentDeposit: number;
  targetDeposit: number;
  distributeSchedule: IDistributeScheduleModel[]
  targetDistribute: number;
  depositDecimal: number;
} | null,
  publicKey: PublicKey | null,
) => {
  if (!publicKey || !sale) {
    return {
      refAmount: '0',
      claimAmount: '0',
      purchaseAmount: '0',
    };
  }
  const refundValue = calculateAmountToRefund(participant, sale);
  const refund = refundValue && !participant?.isRefunded ? refundValue : 0;
  return {
    refAmount: refund,
    claimAmount: displayClaimAmount(participant, sale),
    purchaseAmount: calculateAmountToClaim(participant, sale),
  };
};
