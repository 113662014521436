import { useEffect, useState } from 'react';
import { formatCountdown } from '../helpers/util';
import { ONE_SECOND } from '../helpers/constans';

const useCountdown = (deadline: number) => {
  const [countdownInSeconds, setCountdownInSeconds] = useState<number>(0);
  useEffect(() => {
    const timerId = setInterval(() => {
      const formattedDate = Math.floor(Date.now() / 1000);
      if (formattedDate > deadline) return;
      setCountdownInSeconds(deadline - formattedDate);
    }, ONE_SECOND);
    return () => clearInterval(timerId);
  }, [deadline]);
  return formatCountdown(countdownInSeconds);
};

export default useCountdown;
