import styled from 'styled-components';
import { PropsWithChildren } from 'react';
import { EStatuses } from '../../helpers/constans';

const Status = styled.p<PropsWithChildren<any>>`
  padding: 0.281rem 0.375rem;
  border-radius: 0.25rem;
  font-family: ${({ theme }) => theme.interSemiBold}, serif;
  font-size: 0.75rem;
  max-height: 1.5rem;
  background: ${({ theme, roundStatus }) => {
    switch (roundStatus) {
      case EStatuses.PENDING:
        return theme.bgPending;
      case EStatuses.ACTIVE:
        return theme.bgActive;
      case EStatuses.CLOSED:
        return theme.bgFinished;
      default:
        return theme.bgFinished;
    }
  }};
  color: ${({ theme, roundStatus }) => {
    switch (roundStatus) {
      case EStatuses.PENDING:
        return theme.pending;
      case EStatuses.ACTIVE:
        return theme.active;
      case EStatuses.CLOSED:
        return theme.finished;
      default:
        return theme.finished;
    }
  }};
`;

export default {
  Status,
};
