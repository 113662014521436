import React from 'react';

import { calculatePrice, displayAmount } from 'shared/helpers/util';
import { IParticipantModel, ISaleModel } from 'shared/interfaces/interface';
import { ERoundType } from 'shared/helpers/constans';
import ValueItemStyles from './styles';
import Translate from '../Translate/Translate';

export interface IValueSaleModel {
  participantData: IParticipantModel | null;
  currentSale: ISaleModel;
  purchase: number | string;
  refund: number | string;
}

const ValueSale: React.FC<IValueSaleModel> = (
  {
    participantData,
    currentSale,
    purchase,
    refund,
  },
) => {
  const { ticker } = currentSale.project;
  const { depositDecimal } = currentSale;
  return (
    <ValueItemStyles.Wrapper>
      <ValueItemStyles.Item>
        <ValueItemStyles.Title>
          <Translate _key="General.Home.Sale.Deposited" />
        </ValueItemStyles.Title>
        <ValueItemStyles.Data>
          { participantData
            ? displayAmount(participantData.depositedAmount, depositDecimal) : '0'}
          {' '}
          {currentSale.currency}
        </ValueItemStyles.Data>
        {
          (ERoundType.SUBSCRIPTION === currentSale.saleType
            && currentSale.typeClaim.refundAvailable)
          && (
            <ValueItemStyles.Subtitle>
              Refund:
              {' '}
              {refund}
              {' '}
              {currentSale.currency}
            </ValueItemStyles.Subtitle>
          )
        }
      </ValueItemStyles.Item>
      <ValueItemStyles.Item>
        <ValueItemStyles.Title>
          <Translate _key="General.Home.Sale.Purchased" />
        </ValueItemStyles.Title>
        <ValueItemStyles.Data>
          {displayAmount(purchase, depositDecimal)}
          {' '}
          {ticker}
        </ValueItemStyles.Data>
      </ValueItemStyles.Item>
      <ValueItemStyles.Item>
        <ValueItemStyles.Title>
          <Translate _key="General.Home.Sale.MinAllocation" />
        </ValueItemStyles.Title>
        <ValueItemStyles.Data>
          {displayAmount(currentSale.minDeposit, depositDecimal)}
          {' '}
          {currentSale.currency}
        </ValueItemStyles.Data>
      </ValueItemStyles.Item>
      <ValueItemStyles.Item>
        <ValueItemStyles.Title>
          <Translate _key="General.Home.Sale.MaxAllocation" />
        </ValueItemStyles.Title>
        <ValueItemStyles.Data>
          {displayAmount(currentSale.maxDeposit, depositDecimal)}
          {' '}
          {currentSale.currency}
        </ValueItemStyles.Data>
      </ValueItemStyles.Item>
      <ValueItemStyles.Item>
        <ValueItemStyles.Title>
          <Translate
            _key="General.Home.Sale.Price"
            interpolation={{
              ticker,
            }}
          />
        </ValueItemStyles.Title>
        <ValueItemStyles.Data>
          { currentSale
            ? calculatePrice(currentSale.targetDeposit, currentSale.targetDistribute)
            : '0'}
          {' '}
          {currentSale.currency}
          <p>
            ≈ 3.35
          </p>
        </ValueItemStyles.Data>
      </ValueItemStyles.Item>
      <ValueItemStyles.Item>
        <ValueItemStyles.Title>
          <Translate _key="General.Home.Sale.Target" />
        </ValueItemStyles.Title>
        <ValueItemStyles.Data>
          {displayAmount(currentSale.targetDeposit, depositDecimal)}
          {' '}
          {currentSale.currency}
        </ValueItemStyles.Data>
      </ValueItemStyles.Item>
    </ValueItemStyles.Wrapper>
  );
};

export default ValueSale;
