import React, { Fragment } from 'react';

import VestingScheduleStyle from './styles';
import { ETypeVesting, IVesting } from '../../interfaces/interface';
import Translate from '../Translate/Translate';

interface IVestingSchedule {
  vesting: IVesting[],
  vestingType: ETypeVesting | null,
}
interface IVestingScheduleWrapper {
  children: JSX.Element | JSX.Element[],
  label?: any
}

export function VestingScheduleWrapper({
  children,
  label,
}: IVestingScheduleWrapper) {
  return (
    <VestingScheduleStyle.Container>
      <VestingScheduleStyle.Title>
        <Translate _key="Sale.Vesting.Schedule" />
      </VestingScheduleStyle.Title>
      <VestingScheduleStyle.Box>
        {label && (
          <VestingScheduleStyle.Label>
            <Translate _key={label} />
          </VestingScheduleStyle.Label>
        )}
        <VestingScheduleStyle.Vesting>
          {children}
        </VestingScheduleStyle.Vesting>
      </VestingScheduleStyle.Box>
    </VestingScheduleStyle.Container>
  );
}

export default function VestingSchedule({ vesting, vestingType }: IVestingSchedule) {
  switch (vestingType) {
    case ETypeVesting.OneTime:
      return (
        <VestingScheduleWrapper>
          {vesting.map((row) => (
            <Fragment key={row.timestamp}>
              <VestingScheduleStyle.Row isOneTimeVesting>
                <span>
                  <Translate
                    _key="Sale.Vesting.UnlockDate"
                  />
                </span>
                <span>
                  <Translate
                    _key="Sale.Vesting.Date"
                    interpolation={{
                      day: row.dateObj.day,
                      month: row.dateObj.month,
                      year: row.dateObj.year,
                    }}
                  />
                </span>
              </VestingScheduleStyle.Row>
              <VestingScheduleStyle.Division />
            </Fragment>
          ))}
        </VestingScheduleWrapper>
      );
    default:
      return (
        <VestingScheduleWrapper label="Sale.Vesting.UnlockDate">
          {vesting.map((row, index) => (
            <Fragment key={row.timestamp}>
              <VestingScheduleStyle.Row>
                <span>
                  <Translate
                    _key="Sale.Vesting.UnlockRow"
                    interpolation={{
                      number: index + 1,
                      percent: row.quota / 1000,
                    }}
                  />
                </span>
                <span>
                  <Translate
                    _key="Sale.Vesting.Date"
                    interpolation={{
                      day: row.dateObj.day,
                      month: row.dateObj.month,
                      year: row.dateObj.year,
                    }}
                  />
                </span>
              </VestingScheduleStyle.Row>
              <VestingScheduleStyle.Division />
            </Fragment>
          ))}
        </VestingScheduleWrapper>
      );
  }
}
