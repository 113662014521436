import React from 'react';
import { useTranslation } from 'react-i18next';

interface TranslateProps {
  _key: string;
  // eslint-disable-next-line react/require-default-props
  interpolation?: object;
}

const Translate: React.FC<TranslateProps> = ({ _key, interpolation }) => {
  const { t } = useTranslation();
  return <>{ t(_key, interpolation) }</>;
};

export default Translate;
