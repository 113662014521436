import React from 'react';
import {
  useWallet,
}
  from '@solana/wallet-adapter-react';
import {
  transformPublicKey,
}
  from 'shared/helpers/util';

import Translate from '../../Translate/Translate';
import ModalWrapper from '../ModalWrapper';

import SignOutStyles from './styles';
import Buttons from '../../Buttons';

export interface ISignOutModal {
  closeModal: () => void;
  currency: string;
  balance: number
}

const SignOutModal: React.FC<ISignOutModal> = ({
  closeModal,
  currency,
  balance,
}) => {
  const { publicKey, disconnect } = useWallet();
  const wallet = transformPublicKey(publicKey?.toString() || '');

  const confirmHandler = async () => {
    await disconnect();
    closeModal();
  };

  const balanceArray = [
    {
      title: 'General.Modal.Balance',
      value: `${balance.toPrecision(5)} ${currency}`,
    },
  ];

  return (
    <ModalWrapper closeModal={closeModal} isCentered>
      <SignOutStyles.Header>
        <p><Translate _key="General.Modal.User" /></p>
        <SignOutStyles.Close onClick={closeModal} />
      </SignOutStyles.Header>
      <SignOutStyles.WalletWrapper>
        <SignOutStyles.NearIcon />
        <p>{wallet}</p>
      </SignOutStyles.WalletWrapper>
      <SignOutStyles.BalanceWrapper>
        {balanceArray.map(({ title, value }) => (
          <SignOutStyles.Row key={title}>
            <p><Translate _key={title} /></p>
            <p>{value}</p>
          </SignOutStyles.Row>
        ))}
      </SignOutStyles.BalanceWrapper>
      <SignOutStyles.Footer>
        <Buttons.Secondary onClick={confirmHandler}>
          <Translate _key="General.Modal.Disconnect" />
        </Buttons.Secondary>
      </SignOutStyles.Footer>
    </ModalWrapper>
  );
};

export default SignOutModal;
