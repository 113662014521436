import React from 'react';
import { useHistory } from 'react-router-dom';
import { EStatuses, toSwap } from 'shared/helpers/constans';
import { calculatePrice, displayAmount } from 'shared/helpers/util';
import RoundStyles from './styles';
import SaleLayoutInfo from '../SaleLayoutInfo/SaleLayoutInfo';
import Translate from '../Translate/Translate';
import useDateAndStatus from '../../hooks/useDateAndStatus';
import AdditionalSection from './AdditionalSection';
import { ISaleModel } from '../../interfaces/interface';

export interface IRoundInterface {
  sale: ISaleModel;
}

const Round:React.FC<IRoundInterface> = (
  {
    sale,
  },
) => {
  const history = useHistory();
  const {
    startDate,
    endDate,
    saleType,
    status,
    saleId,
    targetDeposit,
    currentDeposit,
    project,
    currency,
    targetDistribute,
    depositDecimal,
    tokenIcon,
    name,
  } = sale;
  const { newStatus } = useDateAndStatus(
    startDate,
    endDate,
    status,
    saleType,
    currentDeposit,
    targetDeposit,
  );
  return (
    <RoundStyles.RoundItem
      onClick={() => history.push(toSwap(saleId))}
      isOpen={newStatus === EStatuses.ACTIVE}
    >
      <SaleLayoutInfo
        name={name}
        tokenIcon={tokenIcon}
        saleStatus={newStatus}
        currency={currency}
        saleType={saleType}
        saleId={saleId}
      />
      <RoundStyles.Footer>
        <RoundStyles.SaleInfoWrapper>
          <RoundStyles.SaleInfoBlock>
            <RoundStyles.NameValue>
              <Translate
                _key="General.Home.Sale.Price"
                interpolation={{
                  ticker: project.ticker,
                }}
              />
            </RoundStyles.NameValue>
            <RoundStyles.CurrencyValue>
              <span>
                {calculatePrice(targetDeposit, targetDistribute)}
                {' '}
                {currency}
              </span>
            </RoundStyles.CurrencyValue>
          </RoundStyles.SaleInfoBlock>
          <RoundStyles.SaleInfoBlock>
            <RoundStyles.NameValue>
              <Translate
                _key="General.Home.Sale.Target"
              />
            </RoundStyles.NameValue>
            <RoundStyles.CurrencyValue>
              {displayAmount(targetDeposit, depositDecimal)}
              {' '}
              {currency}
            </RoundStyles.CurrencyValue>
          </RoundStyles.SaleInfoBlock>
        </RoundStyles.SaleInfoWrapper>
        <RoundStyles.RightSide>
          <AdditionalSection sale={sale} status={newStatus} />
          <div>
            <RoundStyles.Arrow />
          </div>
        </RoundStyles.RightSide>
      </RoundStyles.Footer>
    </RoundStyles.RoundItem>
  );
};

export default Round;
