import styled from 'styled-components';
import { ReactComponent as Wallet } from 'assets/images/wallet-dep.svg';
import { PropsWithChildren } from 'react';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `}
`;

const FieldBox = styled.div`
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `}
`;

const TokenAccountRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${({ theme }) => theme.interRegular}, serif;
  color: ${({ theme }) => theme.globalBlack};
  font-size: 0.75rem;
  margin-bottom: 0.375rem;
`;

const WalletIcon = styled(Wallet)`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
`;

const TokenBalance = styled.div`
  display: flex;
  align-items: center;
`;

const MaxValueBtn = styled.button`
  border: unset;
  outline: unset;
  background: transparent;
  color: ${({ theme }) => theme.globalGrey};
  font-family: ${({ theme }) => theme.interSemiBold}, serif;
  cursor: pointer;
`;

const FieldWrapper = styled.div`
  margin-top: 0.375rem;
  position: relative;
  & > input[type=text] {
    -moz-appearance: textfield
  }
  & > input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0
  }
`;

const MoneyInput = styled.input<PropsWithChildren<any>>`
  font-family: ${({ theme }) => theme.interSemiBold}, serif;
  color: ${({ theme }) => theme.globalBlack};
  text-transform: uppercase;
  background-color: unset;
  padding-left: 1rem;
  width: 100%;
  outline: none;
  transition: box-shadow .2s, border .2s;
  border: 1px solid ${({ theme, valid }) => {
    if (!valid) {
      return theme.negative;
    }
    return theme.globalGrey;
  }};
  &:focus-within, hover {
    box-shadow: 40% ${({ theme }) => theme.globalBlue};
    border: 2px solid ${({ theme }) => theme.globalBlue};
  }
  border-radius: 0.5rem;
  height: 3rem;
  font-size: 1rem;
  &::placeholder {
    color: ${({ theme }) => theme.globalBlack};
  }
`;

const Currency = styled.span`
  color: ${({ theme }) => theme.globalBlack};
  font-family: ${({ theme }) => theme.interSemiBold}, serif;
  font-size: 1rem;
  margin-right: 1rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;

export default {
  Wrapper,
  FieldBox,
  TokenAccountRow,
  FieldWrapper,
  MoneyInput,
  WalletIcon,
  TokenBalance,
  MaxValueBtn,
  Currency,
};
