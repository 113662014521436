import styled from 'styled-components';

const Time = styled.div`
  min-width: 8.75rem;
  font-weight: 600;
  display: flex;
`;

const Divider = styled.p`
  width: .063rem;
  height: 1.188rem;
  background: ${({ theme }) => theme.primaryGrey};
  margin: 0 .5rem;
  border-radius: 1px;
  display: block;
`;

export default {
  Time,
  Divider,
};
