import React, {
  useMemo, useState,
} from 'react';
import Big from 'big.js';
import VestingStyles from './styles';
import Buttons from '../Buttons';
import Translate from '../Translate/Translate';
import {
  ETypeVesting, IDistributionIn, IParticipantModel, IVesting,
} from '../../interfaces/interface';
import { EStatuses, ZERO } from '../../helpers/constans';
import useVestingStatus from '../../hooks/useVestingStatus';
import Timer from '../Timer';
import VestingList from './VestingList';
import useVestingDataStorage from '../../hooks/useVestingDataStorage';

interface IVestingSection {
  vesting: IVesting[];
  vestingType: ETypeVesting | null;
  makeClaim: () => Promise<void>;
  purchase: string | number;
  participantData: IParticipantModel;
  isClaimAvailable: boolean;
  status: EStatuses;
  rewardTicker: string;
  depositDecimal: number;
  distributionIn: IDistributionIn | null;
}

const VestingSection: React.FC<IVestingSection> = (
  {
    vesting,
    vestingType,
    makeClaim,
    purchase,
    participantData,
    isClaimAvailable,
    status,
    rewardTicker,
    depositDecimal,
    distributionIn,
  },
) => {
  const [isUpdated, setIsUpdated] = useState<boolean>(true);

  const {
    userDataByVesting,
  } = useVestingDataStorage(
    depositDecimal,
    vesting,
    vestingType,
    purchase,
    participantData,
    isClaimAvailable,
    isUpdated,
    setIsUpdated,

  );

  const {
    time,
    vestingEnded,
  } = useVestingStatus(
    vesting,
    vestingType,
    depositDecimal,
    purchase,
    isClaimAvailable,
    participantData,
    userDataByVesting,
    setIsUpdated,
  );
  const isActiveClaim = useMemo(() => (
    Big(userDataByVesting.availableTokens).gt(ZERO)
  ), [userDataByVesting.availableTokens]);

  const allClaimed = useMemo(() => (
    Big(userDataByVesting.totalLocked).eq(ZERO) && Big(userDataByVesting.availableTokens).eq(ZERO)
  ), [userDataByVesting.availableTokens, userDataByVesting.totalLocked]);

  if (distributionIn?.active) {
    return (
      <VestingStyles.DistributionContainer>
        <Timer
          time={distributionIn.timestamp}
          isDistributionIn={distributionIn.active}
        />
      </VestingStyles.DistributionContainer>
    );
  }

  return (
    <VestingStyles.VestingSection>
      <VestingStyles.SectionTitle>
        <Translate _key="Sale.TokenVesting" />
      </VestingStyles.SectionTitle>
      <VestingStyles.AmountWrapper>
        {
          userDataByVesting.userTokensArray.map((item) => (
            <div key={item.title}>
              <VestingStyles.AmountTitle>
                <Translate _key={item.title} />
              </VestingStyles.AmountTitle>
              <VestingStyles.AmountValue>
                {
                  item.value
                }
              </VestingStyles.AmountValue>
            </div>
          ))
        }
      </VestingStyles.AmountWrapper>
      {
        vestingType === ETypeVesting.Stepwise && (
          <VestingList
            vesting={userDataByVesting.vestingArray}
            rewardTicker={rewardTicker}
            depositDecimal={depositDecimal}
          />
        )
      }
      {
        !allClaimed && (
          <Buttons.Primary
            onClick={makeClaim}
            isApply
            disabled={!isActiveClaim}
          >
            <Translate _key="Sale.Claim" />
          </Buttons.Primary>
        )
      }
      {
        !vestingEnded && (
          <Timer
            status={status}
            time={time}
            vestingType={vestingType}
          />
        )
      }
    </VestingStyles.VestingSection>
  );
};

export default VestingSection;
