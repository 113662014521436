import React from 'react';
import RefundStyles from './styles';
import Translate from '../Translate/Translate';
import Buttons from '../Buttons';
import { displayAmount } from '../../helpers/util';

interface IRefundModel {
  refund: string;
  currency: string;
  depositDecimal: number;
  makeRefund: () => Promise<void>;
}

const RefundSection: React.FC<IRefundModel> = (
  {
    refund,
    currency,
    makeRefund,
    depositDecimal,
  },
) => {
  return (
    <RefundStyles.Container>
      <RefundStyles.SectionTitle>
        <Translate _key="Sale.Refund.Title" />
      </RefundStyles.SectionTitle>
      <RefundStyles.AmountTitle>
        <Translate _key="Sale.Refund.Label" />
      </RefundStyles.AmountTitle>
      <RefundStyles.AmountValue>
        <Translate
          _key="Sale.PriceValue"
          interpolation={{
            price: displayAmount(refund, depositDecimal),
            currency,
          }}
        />
      </RefundStyles.AmountValue>
      <Buttons.Primary
        onClick={makeRefund}
        isApply
      >
        <Translate _key="Sale.Refund.Title" />
      </Buttons.Primary>
    </RefundStyles.Container>
  );
};

export default RefundSection;
