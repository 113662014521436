import React, { useMemo } from 'react';

import { EModals } from 'providers/modal-provider/interfaces/interfaces';
import { ModalProvider } from 'providers/modal-provider/modal-provider';
import { useWallet } from '@solana/wallet-adapter-react';
import isEqual from 'lodash/isEqual';
import { useSaleServiceContext } from 'providers/sale-provider/sale-provider';
import ModalRowStyles from './styles';
import Translate from '../Translate/Translate';
import { DEFAULT_FILTER } from '../../helpers/constans';

export interface IModalActionRow {
  loading: boolean;
}
const ModalActionRow: React.FC<IModalActionRow> = ({ loading }) => {
  const { showModal } = ModalProvider();
  const { filterType } = useSaleServiceContext();
  const {
    publicKey,
  } = useWallet();

  const openFilterModal = () => {
    showModal(EModals.FILTER_MODAL, {
      publicKey,
    });
  };

  const openSearchModal = () => {
    showModal(EModals.SEARCH_MODAL, {});
  };

  const isDefaultFilter = useMemo(() => isEqual(DEFAULT_FILTER, filterType), [filterType]);

  return (
    <ModalRowStyles.ActionWrapper
      loading={loading ? loading.toString() : undefined}
    >
      <ModalRowStyles.SearchImg onClick={openSearchModal} />
      <span>
        <Translate _key="General.Home.Title" />
      </span>
      <ModalRowStyles.LogoContainer onClick={openFilterModal}>
        <ModalRowStyles.FilterImg />
        {!isDefaultFilter && <ModalRowStyles.RedDot />}
      </ModalRowStyles.LogoContainer>
    </ModalRowStyles.ActionWrapper>
  );
};

export default ModalActionRow;
