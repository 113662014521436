import styled from 'styled-components';
import { PropsWithChildren } from 'react';

const Container = styled.div<PropsWithChildren<{loading?: string}>>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  margin-top: 3rem;
  font-family: ${({ theme }) => theme.robotoRegular}, serif;
  opacity: ${({ loading }) => (loading ? '0' : '1')};
  & > p {
    display: flex;
    justify-content: center;
    flex: 1;
    font-weight: 400;
    font-size: .75rem;
    color: ${({ theme }) => theme.globalGrey};
    margin: 0;
  }
  `;

export default {
  Container,
};
