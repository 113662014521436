import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 3rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 1.5rem;
  `}
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 9.813rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
      width: unset;
      flex: 50%
    `}
  &:nth-child(n+3) {
    ${({ theme }) => theme.mediaWidth.upToSmall`
      margin-top: 1.5rem;
    `}
  }
  &:nth-of-type(3n+0) {
    text-align: right;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      text-align: left;
    `}
  }
  &:nth-of-type(2),
  &:nth-of-type(5) {
    text-align: center;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      text-align: right;
    `}
}
  &:nth-last-child(-n+3) {
    padding-top: 1.5rem;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      padding-top: 0;
      text-align: right;
    `}
  }
  div:nth-child(4n+2),
  div:nth-child(4n+3) {
    ${({ theme }) => theme.mediaWidth.upToSmall`
      text-align: right;
    `}
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
      text-align: left;
    `}
  &:nth-last-child(2) {
    ${({ theme }) => theme.mediaWidth.upToSmall`
      text-align: left;
    `}
  }
`;

const Title = styled.span`
  color: ${({ theme }) => theme.primaryGrey};
  font-family: ${({ theme }) => theme.interMedium}, serif;
  font-size: 0.75rem;
`;

const Data = styled.span`
  color: ${({ theme }) => theme.globalBlack};
  font-family: ${({ theme }) => theme.interSemiBold}, serif;
  font-size: 1rem;
  padding-top: 0.375rem;
  & p {
    display: none;
    color: ${({ theme }) => theme.primaryGrey};
    font-size: 0.75rem;
    font-family: ${({ theme }) => theme.interMedium}, serif;
  }
`;

const Subtitle = styled.span`
  color: ${({ theme }) => theme.primaryGrey};
  font-size: 0.75rem;
  font-family: ${({ theme }) => theme.interMedium}, serif;
  padding-top: 0.25rem;
`;

export default {
  Wrapper,
  Item,
  Title,
  Data,
  Subtitle,
};
