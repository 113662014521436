import { IConfig } from '../config';

export default {
  network: 'solana',
  networkId: 'Devnet',
  nodeUrl: 'https://api.devnet.solana.com',
  environment: 'devnet',
  programId: 'DFE6nCUoeRizqAJs56r7Vd7NTZyMNeHZN9PvuH3vvq2H',
  launchpadExchange: 'GTvQKoR4sTVey5tdVTuuTvmG3pSF9McMr9uGoaVUxmUC',
  hapiNetwork: 'GqZKWhUe7ymmZRxTY3LzW19HzT5cDEQE7m3zdtNW6MsD',
  hapiProgramId: 'hapiAwBQLYRXrjGn6FLCgC8FpQd2yWbKMqS6AYZ48g6',
} as IConfig;
