import React from 'react';
import Big from 'big.js';
import { calculateAmountToClaim } from 'views/Sale/helper';
import { IAmountInputError } from 'views/Sale/interfaces';
import DepositSectionStyle from './styles';
import ErrorWarning from '../Errors/Errors';
import Translate from '../Translate/Translate';
import {
  displayAmount, parseTokenAmount,
} from '../../helpers/util';
import { IParticipantModel, ISaleModel } from '../../interfaces/interface';
import { ZERO } from '../../helpers/constans';

interface IAdditionalInfoText {
  participantData: IParticipantModel;
  sale: ISaleModel;
  error: IAmountInputError;
  amount: string
}

const AdditionalInfoText: React.FC<IAdditionalInfoText> = (
  {
    participantData,
    sale,
    error,
    amount,
  },
) => {
  const { depositDecimal, project } = sale;
  const formatAmount = parseTokenAmount(amount || ZERO, depositDecimal);
  const deposited = Big(participantData.depositedAmount)
    .plus(formatAmount).toFixed();

  const purchaseAmount = calculateAmountToClaim(
    {
      ...participantData,
      depositedAmount: Number(deposited),
    },
    {
      ...sale,
      currentDeposit: Number(Big(sale.currentDeposit).plus(formatAmount).toFixed()),
    },
  );

  const formattedPurchase = displayAmount(purchaseAmount, sale.depositDecimal);
  const finalAllocation = Big(purchaseAmount).eq(ZERO) ? '' : formattedPurchase;
  return (
    <>
      <ErrorWarning
        error={error}
      />
      {
        (error.valid && !error.reason) && (
          <DepositSectionStyle.AdditionalText>
            <Translate
              _key="Sale.FinalAllocation"
            />
            &nbsp;
            <strong>{`- ${finalAllocation} ${project.ticker}`}</strong>
          </DepositSectionStyle.AdditionalText>
        )
      }
    </>
  );
};

export default AdditionalInfoText;
