import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/images/close_modal.svg';
import Button from 'shared/components/Buttons';

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  justify-content: center;
  & > p {
    margin: 0;
    font-family: ${({ theme }) => theme.interSemiBold},serif;
    color: ${({ theme }) => theme.globalBlack};
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`;

const Close = styled(CloseIcon)`
  position: absolute;
  width: 14px;
  height: 14px;
  right: 0;
  fill: black;
  :hover {
    cursor: pointer;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 360px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 100%;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

const TitleWrapper = styled.p`
  margin-bottom: .75rem;
  font-style: normal;
  font-weight: 500;
  font-size: .75rem;
  line-height: .938rem;
  color: ${({ theme }) => theme.primaryGrey};
  font-family: ${({ theme }) => theme.interMedium},serif;
  `;

const WrapperBtn = styled.div`
  display: flex;
  align-items: center;
`;

const FilterBtn = styled(Button.Filter)`
  font-weight: 500;
  font-size: .75rem;
  line-height: .938rem;
  margin-right: .5rem;
  padding: 0 .75rem;
`;

const ApplyBtn = styled(Button.Secondary)`
  font-weight: 500;
  font-size: .75rem;
  line-height: .938rem;
  margin: 0 .25rem;
`;

const Footer = styled.div`
  margin-top: 2.25rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  & > button {
    width: 100%;
    height: 100vh;
    &:last-child {
      margin-top: 1rem;
    }
  }
`;

const ClearButton = styled.button`
  font-family: ${({ theme }) => theme.interSemiBold},serif;
  font-size: 1rem;
  color: ${({ theme }) => theme.globalBlue};
  border: none;
  outline: none;
  background: none;
  text-align: left;
  margin-top: 0.75rem;
  cursor: pointer;
  font-weight: 600;
`;

export default {
  Header,
  Close,
  Body,
  Wrapper,
  TitleWrapper,
  FilterBtn,
  WrapperBtn,
  ApplyBtn,
  Footer,
  ClearButton,
};
