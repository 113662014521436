import styled from 'styled-components';

import { ReactComponent as ClearSearchIcon } from 'assets/images/clear-search.svg';
import { ReactComponent as CloseIcon } from 'assets/images/close_modal.svg';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: center;
  & > p {
    margin: 0;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`;

const StyledInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border: 2px solid ${({ theme }) => theme.borderBlue};
  border-radius: 8px;
  margin-right: 2.5rem;
  min-height: 48px;
  :focus-within {
    border: 2px solid ${({ theme }) => theme.globalBlue};
  }
`;

const Input = styled.input`
  margin: 0 48px;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  color: ${({ theme }) => theme.globalBlack};
`;

const ClearSearch = styled(ClearSearchIcon)`
  position: absolute;
  width: 1.375rem;
  height: 1.375rem;
  right: 0.875rem;
  cursor: pointer;
`;

const Search = styled(SearchIcon)`
  position: absolute;
  width: 24px;
  height: 24px;
  left: 14px;
  fill: black;
`;

const Close = styled(CloseIcon)`
  position: absolute;
  width: 14px;
  height: 14px;
  right: 0;
  fill: black;
  :hover {
    cursor: pointer;
  }
`;

const Body = styled.div`
  flex: 1;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  min-width: 540px;
  min-height: 400px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 100%;
    height: 100vh;
  `}
`;

const Row = styled.div`
  display: flex;
  margin: .75rem 0;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  :hover {
    cursor: pointer;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 48px;
  max-height: 48px;
  height: 100%;
  width: 100%;
`;

const Image = styled.img`
  height: 100%;
  max-width: 48px;
  max-height: 48px;
`;

const WrapperDescription = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 48px;
  margin-left: .75rem;
`;

const Title = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.globalBlack};
  font-family: ${({ theme }) => theme.interSemiBold}, serif;
  span {
    background: ${({ theme }) => theme.highlightBlue};
    padding: 0.188rem 0;
  }
`;

const Label = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: .75rem;
  line-height: 1.063rem;
  color: ${({ theme }) => theme.primaryGrey};
  font-family: ${({ theme }) => theme.interRegular}, serif;
`;

const NoResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  max-width: 320px;
`;

const NoResultsTitle = styled.p`
  margin: 1.5rem 0 .75rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: ${({ theme }) => theme.globalBlack};
  font-family: ${({ theme }) => theme.interSemiBold}, serif;
  text-align: center;
`;

const NoResultsLabel = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: .875rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.primaryGrey};
  text-align: center;
  font-family: ${({ theme }) => theme.interRegular}, serif;
`;

const NoResultsIcon = styled(SearchIcon)`
  width: 27px;
  height: 27px;
  fill: black;
`;

export default {
  Header,
  StyledInput,
  Search,
  Input,
  ClearSearch,
  Close,
  Body,
  Row,
  ImageContainer,
  Image,
  WrapperDescription,
  Title,
  Label,
  NoResultsWrapper,
  NoResultsTitle,
  NoResultsLabel,
  NoResultsIcon,
};
