import React, { Dispatch, SetStateAction } from 'react';

import { useSendTransactionContext } from 'providers/sendTransactionProvider/sendTransactionProvider';
import { EStatuses } from 'shared/helpers/constans';
import { maxValue } from 'views/Sale/helper';
import { IParticipantModel, ISaleModel } from 'shared/interfaces/interface';
import { useSolanaWallet } from 'providers/solana-wallet-provider/wallet-adapter';
import Big from 'big.js';
import { IAmountInputError } from 'views/Sale/interfaces';
import InputStyles from './styles';
import Translate from '../Translate/Translate';
import { enforcer, validateValue } from './helpers';
import {
  displayAmount,
  displayBalance, lamportsToAmount,
} from '../../helpers/util';

export interface IAmountInputModel {
  participantData: IParticipantModel | null;
  isParticipant: boolean;
  setAmount: Dispatch<SetStateAction<string>>
  currentSale: ISaleModel | null;
  purchase: number | string;
  amount: string;
  error: IAmountInputError
  setError: Dispatch<SetStateAction<IAmountInputError>>
  setInputError: Dispatch<SetStateAction<IAmountInputError>>
  newStatus: EStatuses
}

const AmountInputContainer: React.FC<IAmountInputModel> = ({
  participantData,
  currentSale,
  setAmount,
  purchase,
  amount,
  error,
  setError,
  setInputError,
  isParticipant,
  newStatus,
}) => {
  const { publicKey } = useSolanaWallet();
  const { balance } = useSendTransactionContext();
  const handlerChange = ({ target }: {target: HTMLInputElement}) => {
    if (!currentSale) return;
    const value = enforcer(
      target,
    );
    const validateAmount = validateValue(
      {
        isParticipant,
        value,
        availableBalance: balance,
        min: new Big(currentSale.minDeposit),
        max: new Big(currentSale.maxDeposit),
        deposited: participantData?.depositedAmount || 0,
        decimal: currentSale.depositDecimal,
      },
    );
    setAmount(value ?? '');
    setError(validateAmount ?? { valid: true, reason: null });
    setInputError(validateAmount ?? { valid: true, reason: null });
  };

  const calculateMaxValue = (
  ) => {
    if (!currentSale) return;
    const maxValueAmount = maxValue(
      isParticipant,
      balance,
      purchase,
      participantData?.depositedAmount || 0,
      currentSale.depositDecimal,
      currentSale.maxDeposit,
    );
    if (!maxValueAmount) return;
    const validateAmount = validateValue(
      {
        isParticipant,
        value: lamportsToAmount(maxValueAmount, currentSale.depositDecimal),
        availableBalance: balance,
        min: new Big(currentSale.minDeposit),
        max: new Big(currentSale.maxDeposit),
        deposited: participantData?.depositedAmount || 0,
        decimal: currentSale.depositDecimal,
      },
    );
    setAmount(displayAmount(maxValueAmount, currentSale.depositDecimal));
    setError(validateAmount ?? { valid: true, reason: null });
  };

  return (
    <InputStyles.Wrapper>
      <InputStyles.FieldBox>
        <InputStyles.TokenAccountRow>
          <InputStyles.TokenBalance>
            <InputStyles.WalletIcon />
            {publicKey
              ? displayBalance(balance.toString()) : '0'}
            {' '}
            {currentSale?.currency}
          </InputStyles.TokenBalance>
          {
            newStatus === EStatuses.ACTIVE
            && (
            <InputStyles.MaxValueBtn
              onClick={calculateMaxValue}
              disabled={newStatus !== EStatuses.ACTIVE || !publicKey}
            >
              <Translate _key="Sale.Max" />
            </InputStyles.MaxValueBtn>
            )
          }
        </InputStyles.TokenAccountRow>
        <InputStyles.FieldWrapper>
          <InputStyles.MoneyInput
            value={amount}
            valid={error.valid}
            inputMode="decimal"
            autoComplete="off"
            autoCorrect="off"
            type="text"
            placeholder="0"
            onChange={handlerChange}
            disabled={newStatus !== EStatuses.ACTIVE || !publicKey}
          />
          <InputStyles.Currency>
            {currentSale?.currency}
          </InputStyles.Currency>
        </InputStyles.FieldWrapper>
      </InputStyles.FieldBox>
    </InputStyles.Wrapper>
  );
};

export default AmountInputContainer;
