import React from 'react';
import SaleLayoutStyles from './styles';
import StatusLabel from '../StatusLabel/StatusLabel';
import Translate from '../Translate/Translate';
import ProjectImage from '../ProjectImage';
import { ESalesLogo } from '../../interfaces/interface';

export interface ISaleLayoutInfo{
  name: string;
  tokenIcon: string;
  saleStatus: string;
  currency: string;
  isSale?: boolean;
  saleType: string;
  saleId: number;
}

const SaleLayoutInfo: React.FC<ISaleLayoutInfo> = ({
  name,
  tokenIcon,
  saleStatus,
  currency,
  isSale,
  saleType,
  saleId,
}) => {
  return (
    <>
      <SaleLayoutStyles.LayoutParent>
        <ProjectImage
          saleId={saleId}
          typeLogo={ESalesLogo.HEADER}
        />
      </SaleLayoutStyles.LayoutParent>
      <SaleLayoutStyles.ProjectInfoNaming>
        <div>
          <SaleLayoutStyles.ProjectName>
            {name}
          </SaleLayoutStyles.ProjectName>
          <SaleLayoutStyles.StageRow>
            <SaleLayoutStyles.StageIcon src={tokenIcon} />
            <SaleLayoutStyles.StageTxt>
              <span>
                {' '}
                {currency}
                {' '}
                <Translate _key="Sale.Stage" />
              </span>
              <span>•</span>
              <span>
                <Translate
                  _key="Sale.Type"
                  interpolation={{
                    saleType,
                  }}
                />
              </span>
            </SaleLayoutStyles.StageTxt>
          </SaleLayoutStyles.StageRow>
        </div>
        <SaleLayoutStyles.StatusRow>
          {
            isSale && (<SaleLayoutStyles.SocialImg />)
          }
          <StatusLabel saleStatus={saleStatus} />
        </SaleLayoutStyles.StatusRow>
      </SaleLayoutStyles.ProjectInfoNaming>
    </>
  );
};

export default SaleLayoutInfo;
