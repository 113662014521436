import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { ReactComponent as TelegramImg } from 'assets/images/telegram-icon.svg';
import { ReactComponent as MediumImg } from 'assets/images/medium-icon.svg';
import { ReactComponent as TwitterImg } from 'assets/images/twitter-icon.svg';

export enum SocialEnum {
  Medium,
  Telegram,
  Twitter
}

const ImageMap:{[key:number]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>} = {
  [SocialEnum.Medium]: MediumImg,
  [SocialEnum.Telegram]: TelegramImg,
  [SocialEnum.Twitter]: TwitterImg,
};

const SocialNetworksWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SocialNetworksIcon = styled.a<PropsWithChildren<any>>`
  margin: 0 1rem;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  & > svg {
    width: ${({ isSale }) => {
    if (isSale) {
      return '2rem';
    }
    return '2.8rem;';
  }};
    height: ${({ isSale }) => {
    if (isSale) {
      return '2rem';
    }
    return '2.8rem;';
  }};
};
};
    & path {
      fill: ${({ theme, isSale }) => {
    if (isSale) {
      return theme.globalBlack;
    }
    return theme.globalWhite;
  }
}
`;

export interface ISocialModel {
  socials: {value:string, type: SocialEnum }[]
  isSale?: boolean;
}

const SocialNetwork: React.FC<ISocialModel> = ({ socials, isSale }) => {
  return (
    <SocialNetworksWrapper>
      {socials.map((social) => {
        const Image = ImageMap[social.type];
        return (
          <SocialNetworksIcon
            key={social.value}
            href={social.value}
            target="_blank"
            rel="noreferrer"
            isSale={isSale}
          >
            <Image />
          </SocialNetworksIcon>
        );
      })}
    </SocialNetworksWrapper>
  );
};

export default SocialNetwork;
