import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { Connection } from '@solana/web3.js';
import * as anchor from '@project-serum/anchor';
import { BN, Provider } from '@project-serum/anchor';
import { BocachicaMoon, IDL } from 'shared/helpers/bocachica_moon';
import { IFilter, ISaleModel, SalesStoreContextType } from 'shared/interfaces/interface';
import { ContractViewMethods, DEFAULT_FILTER } from 'shared/helpers/constans';
import { useSendTransactionContext } from '../sendTransactionProvider/sendTransactionProvider';

import {
  callSale,
  claim,
  deposit,
  getBalanceSaleById,
  getCurrentContractObject,
  getSaleData,
  getSmartContractInfo,
  refund,
  sortSales,
} from './helper';
import { launchpadExchange, nodeUrl, programId as ID } from '../../services/config';

const initialSalesState: SalesStoreContextType = {
  loading: false,
  setLoading: () => undefined,
  sales: [],
  setSales: () => undefined,
  filterType: DEFAULT_FILTER,
  setFilterType: () => undefined,
  updateSales: () => undefined,
  joinSale: async () => undefined,
  refundAction: async () => undefined,
  transferMoney: async () => undefined,
  claimAction: async () => undefined,
  accountAddress: '',
  setAccountAddress: () => undefined,
  setCurrentSaleId: () => undefined,
  updateBalance: () => undefined,
  updateSolanaDataInfo: () => undefined,
  getCurrentSale: async () => undefined,
};

export const SaleServiceContext = createContext<SalesStoreContextType>(
  initialSalesState,
);

const connection = new Connection(nodeUrl);

export const SolanaServiceProvider: React.FC = ({ children }) => {
  const {
    signTransaction,
    wallet,
    publicKey,
  } = useWallet();
  const {
    sendTransaction,
    getBalance,
    loading,
    setLoading,
  } = useSendTransactionContext();

  const [provider, setProvider] = useState<Provider | null>(null);
  const [program, setProgram] = useState<anchor.Program<BocachicaMoon> | null>(null);
  const [accountAddress, setAccountAddress] = useState<string>('');
  const [sales, setSales] = useState<ISaleModel[] | []>([]);
  const { setBalance } = useSendTransactionContext();
  const [filterType, setFilterType] = useState<IFilter>(DEFAULT_FILTER);
  const [currentSaleId, setCurrentSaleId] = useState<number | null>(null);

  useEffect(() => {
    if (connection && wallet) {
      const providerInstance = new Provider(connection, wallet as any, {
        commitment: 'processed',
      });
      setProvider(providerInstance);
    } else {
      const providerInstance = new Provider(connection, null as any, {
        commitment: 'processed',
      });
      setProvider(providerInstance);
    }
  }, [wallet]);

  const idl = IDL;

  const programId = useMemo(() => new anchor.web3.PublicKey(ID),
    []);
  const launchpadInstance = useMemo(() => new anchor.web3.PublicKey(launchpadExchange),
    []);

  useEffect(() => {
    if (!provider) return;
    setProgram(new anchor.Program(idl, programId, provider));
  }, [provider, idl, programId]);

  const joinSale = async (id: BN) => {
    return callSale(
      {
        program,
        programId,
        publicKey,
        connection,
        signTransaction,
        sendTransaction,
        method: ContractViewMethods.SALE,
        launchpadInstance,
        id,
      },
    );
  };

  const transferMoney = useCallback(async (
    value: number | BN,
    sale: any,
  ) => {
    const completedDeposit = await deposit(
      {
        value,
        program,
        provider,
        publicKey,
        sale,
        connection,
        signTransaction,
        sendTransaction,
        launchpadInstance,
        id: sale.saleId,
        programId,
      },
    );

    return completedDeposit;
  }, [
    connection,
    program,
    launchpadInstance,
    programId,
    publicKey,
    sendTransaction,
    signTransaction,
    provider,
  ]);

  const claimAction = useCallback(async (
    sale: ISaleModel,
  ) => {
    return claim(
      program,
      publicKey,
      programId,
      launchpadInstance,
      sale.saleId,
      connection,
      sale.distributeMint,
      sale.distributeAccount,
      signTransaction,
      sendTransaction,
    );
  }, [program, publicKey, programId, launchpadInstance, connection, signTransaction, sendTransaction]);

  const refundAction = useCallback(async (
    sale: ISaleModel,
  ) => {
    return refund(
      program,
      publicKey,
      programId,
      launchpadInstance,
      sale.saleId,
      connection,
      sale.depositMint,
      sale.depositAccount,
      signTransaction,
      sendTransaction,
    );
  }, [program, publicKey, programId, launchpadInstance, connection, signTransaction, sendTransaction]);

  const compareSaleData = useCallback(async () => {
    const meta = await getSmartContractInfo(
      ContractViewMethods.SALE_METADATA,
      program,
      launchpadInstance,
      programId,
    ) || {};

    const saleData = await getSmartContractInfo(
      ContractViewMethods.SALE,
      program,
      launchpadInstance,
      programId,
    );
    if (meta) {
      setAccountAddress(meta.address);
    }
    const sale = await getSaleData(
      saleData,
      meta,
      publicKey,
      connection,
      programId,
      program,
      launchpadInstance,
    );
    if (!sale) return;
    return sortSales(sale);
  }, [program, programId, publicKey]);

  const getCurrentSale = async (
    id: number,
  ) => {
    const meta = await getCurrentContractObject(
      ContractViewMethods.SALE_METADATA,
      program,
      launchpadInstance,
      programId,
      id,
    );

    const sale = await getCurrentContractObject(
      ContractViewMethods.SALE,
      program,
      launchpadInstance,
      programId,
      id,
    );

    return getSaleData(
      sale,
      meta,
      publicKey,
      connection,
      programId,
      program,
      launchpadInstance,
    );
  };

  const updateSales = async () => {
    const updatedSales = await compareSaleData();
    if (!updatedSales) return;
    setSales(updatedSales);
  };

  const updateBalance = useCallback(async () => {
    if (!sales) return;
    const balanceAmount = await getBalanceSaleById(
      sales,
      publicKey,
      currentSaleId,
      getBalance,
    );
    if (!balanceAmount) return 0;
    setBalance(balanceAmount);
  }, [currentSaleId, getBalance, publicKey, sales, setBalance]);

  const updateSolanaDataInfo = async () => {
    await updateBalance();
    await updateSales();
  };

  useEffect(() => {
    updateBalance();
  }, [publicKey, sales, updateBalance]);

  useEffect(() => {
    const initLoading = async () => {
      try {
        setLoading(true);
        const compareData = await compareSaleData();
        if (compareData && compareData.length) {
          setSales(compareData);
        }
      } catch (e) {
        throw new Error();
      } finally {
        setLoading(false);
      }
    };
    initLoading();
  }, [compareSaleData, program, publicKey, setLoading]);

  return (
    <SaleServiceContext.Provider value={{
      loading,
      setLoading,
      sales,
      setSales,
      filterType,
      setFilterType,
      updateSales,
      joinSale,
      transferMoney,
      claimAction,
      refundAction,
      accountAddress,
      setAccountAddress,
      setCurrentSaleId,
      updateBalance,
      updateSolanaDataInfo,
      getCurrentSale,
    }}
    >
      {children}
    </SaleServiceContext.Provider>
  );
};

export const useSaleServiceContext = () => useContext(SaleServiceContext);
