import styled from 'styled-components';

const Container = styled.div`
  background: ${({ theme }) => theme.backgroundBlue};
  border-radius: 1rem;
  margin-top: 1.5rem;
  padding: 1rem 0rem;
  & button {
    width: 23.125rem;
    height: 2.5rem;
    margin: 2rem auto 0;
  }
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    & button {
      width: 100%;
    }
    padding: 1rem 1rem
  `}
`;

const SectionTitle = styled.p`
  font-size: 1rem;
  font-family: ${({ theme }) => theme.interSemiBold}, serif;
  color: ${({ theme }) => theme.globalBlack};
  text-align: center;
`;

const AmountTitle = styled.p`
  padding-top: 2rem;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.primaryGrey};
  font-family: ${({ theme }) => theme.interMedium}, serif;
  text-align: center;
`;

const AmountValue = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.globalBlack};
  font-family: ${({ theme }) => theme.interSemiBold}, serif;
  padding-top: 0.375rem;
  text-align: center;
`;

export default {
  Container,
  SectionTitle,
  AmountTitle,
  AmountValue,
};
