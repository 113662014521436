import Big from 'big.js';
import { IAmountInputError, IAmountValidateArgs } from 'views/Sale/interfaces';
import { parseTokenAmount } from '../../helpers/util';

export const inputRegex = RegExp('^\\d*(?:\\\\[.])?\\d*$');
export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const validNumberError: IAmountInputError = { valid: false, reason: 'inputErrors.validNumberError' };
const smallerThanZeroError: IAmountInputError = { valid: false, reason: 'inputErrors.smallerThanZeroError' };
const smallerThanMinValueError: IAmountInputError = {
  valid: false, reason: 'inputErrors.smallerThanMinValueError',
};
const greaterThanMaxValueError: IAmountInputError = {
  valid: false, reason: 'inputErrors.greaterThanMaxValueError',
};

const greaterThanBalance: IAmountInputError = {
  valid: false, reason: 'inputErrors.insufficientFunds',
};

export const risksError: IAmountInputError = { valid: false, reason: 'inputErrors.risks' };

export const validateValue = ({
  isParticipant,
  value,
  availableBalance,
  min,
  max,
  deposited,
  decimal,
}: IAmountValidateArgs) => {
  if (!availableBalance) return smallerThanMinValueError;
  if (!value) return smallerThanZeroError;
  const numericBigValue = new Big(value ?? 0).toNumber();
  const balance = new Big(parseTokenAmount(availableBalance.toString(), decimal));
  const amount = new Big(parseTokenAmount(value.toString(), decimal));

  const maxAllocation = new Big(max);
  const minAllocation = new Big(min);
  const participateDeposited = isParticipant && deposited > 0 ? deposited : 0;
  if (Number.isNaN(numericBigValue)) return validNumberError;
  if (amount.gt(balance)) return greaterThanBalance;
  if (numericBigValue <= 0) return smallerThanZeroError;
  if (amount.lt(minAllocation.minus(participateDeposited ?? 0)) || amount.lte(0)) return smallerThanMinValueError;
  if (amount.gt(maxAllocation.minus(participateDeposited ?? 0)) || amount.gt(balance ?? 0)) return greaterThanMaxValueError;

  return ({ valid: true, reason: null });
};

export const enforcer = (
  target: HTMLInputElement,
) => {
  let value = '';
  const nextUserInput = target.value.replace(/,/g, '.');
  if (nextUserInput[0] === '.' || nextUserInput[0] === ',') {
    value = `0${nextUserInput}`;
    return;
  }
  if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
    value = nextUserInput;
  }

  return value;
};

export const validateCheckbox = (
  checked: boolean,
  error: IAmountInputError,
  inputError: IAmountInputError,
) => {
  if (!checked && error.valid) {
    return {
      valid: error.valid,
      reason: risksError.reason,
    };
  }
  if (!inputError.valid && checked) {
    return {
      reason: inputError.reason,
      valid: false,
    };
  }

  return { reason: null, valid: true };
};

export const percentageCalculator = (collectedAmount: number, totalAmount: number) => {
  if (Big(collectedAmount).eq(0)) return 0;
  const progress = Big(collectedAmount).mul(100).div(totalAmount).toNumber();
  return Math.floor(progress);
};
