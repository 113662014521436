import React from 'react';
import ModalWrapperStyles from './styles';

export interface IModalWrapper {
  children: any,
  closeModal: any,
  isCentered?: boolean,
  isFullWidth?: boolean,
  isFullHeight?: boolean,
}

const ModalWrapper:React.FC<IModalWrapper> = ({
  children,
  closeModal,
  isCentered,
  isFullWidth,
  isFullHeight,
}) => {
  return (
    <>
      <ModalWrapperStyles.Wrapper onClick={closeModal} />
      <ModalWrapperStyles.Modal
        isCentered={isCentered}
        isFullWidth={isFullWidth}
        isFullHeight={isFullHeight}
      >
        {children}
      </ModalWrapperStyles.Modal>
    </>
  );
};

export default ModalWrapper;
