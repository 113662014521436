import styled from 'styled-components';
import { ReactComponent as WalletLogo } from 'assets/images/wallet.svg';
import { ReactComponent as BochaChica } from 'assets/images/boca-logo.svg';
import { PropsWithChildren } from 'react';

const LeftRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Toggle = styled.button<{ isOpen?: boolean }>`
  transition: none;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
  height: 21px;
  width: 50px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;
  overflow: hidden;

  &:focus {
    outline: none;
  }

  div {
    border-radius: 10px;
    transition: width 200ms ease-out, height 0.3s linear, transform 0.3s linear, opacity 300ms linear;
    position: relative;
    transform-origin: 1px;
    min-height: 7px;

    span {
      background: ${({ theme }) => theme.globalWhite};
      height: 3px;
      display: block;
      margin: .125rem 0;
      border-radius: 5px;
    }

    :first-child {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(0)')};
      width: ${({ isOpen }) => (isOpen ? '22px' : '20px')};
    }

    :nth-child(2) {
      width: ${({ isOpen }) => (isOpen ? '0' : '20px')};
    }

    :nth-child(3) {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
      width: ${({ isOpen }) => (isOpen ? '22px' : '20px')};
    }
  }
`;

const BochaChicaLogo = styled(BochaChica)<PropsWithChildren<{loading?: string}>>`
  opacity: ${({ loading }) => (loading ? '0' : '1')};
  :hover{
    cursor: pointer;
  }
`;

const MobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  `}
`;

export default {
  LeftRow,
  Toggle,
  WalletLogo,
  BochaChicaLogo,
  MobileWrapper,
};
