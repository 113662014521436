import {
  css,
  DefaultTheme,
} from 'styled-components/macro';

require('@solana/wallet-adapter-react-ui/styles.css');

export const MEDIA_WIDTHS = {
  upToExtraSmall: 550,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
};

export
const mediaWidthTemplates:
{ [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    (accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `;
    return accumulator;
  },
  {},
) as any;

export interface ThemeColors {
  globalBlack: string;
  globalWhite: string;
  globalGrey: string;
  globalBlue: string;
  primaryGrey: string;
  bgGrey: string;
  negative: string;
  darkBlue: string;
  lightBlue: string;
  borderBlue: string;
  primaryBlue: string
  desaturated: string;
  grey: string;
  darkGrey: string;
  transparent: string;
  opacityGrey: string;
  highlightBlue: string;
  disableGrey: string;
  secondaryGrey: string;
  redDot: string;
  lightBlueFilter: string;
  pressedBlue: string;
  backgroundBlue: string;
  boxShadowCard: string;
  blueBgBtn: string;
  violet: string;
  darkBrown: string;
  lightBrown: string;
  lightGrey: string;
  middleGrey: string;
  middleBlue: string;
  lightGreen: string;
  birch: string;
  statusOpenCard: string;
  blueDivision: string;
}

export interface IStatusColor {
  pending: string;
  bgPending: string;
  active: string;
  bgActive: string;
  finished: string;
  bgFinished: string;
}

export interface Fonts {
  konexyPersonaluse: string;
  squares: string;
  akzidenzGrotesk: string;
  interRegular: string;
  interMedium: string;
  interSemiBold: string;
  robotoRegular: string;
}

export interface IShadowModel {
  shadowWrapper: string;
}

export const fonts: Fonts = {
  konexyPersonaluse: 'Konexypersonaluse',
  squares: 'Squares',
  akzidenzGrotesk: 'Akzidenz-Grotesk',
  interRegular: 'Inter-Regular',
  interMedium: 'Inter-Medium',
  interSemiBold: 'Inter-SemiBold',
  robotoRegular: 'Roboto-Regular',

};

export const colors: ThemeColors = {
  globalBlack: '#131313',
  globalWhite: '#FFFFFF',
  globalGrey: '#B5BBC2',
  globalBlue: '#3C75E3',
  primaryGrey: '#8E939A',
  lightGrey: '#676C73',
  darkGrey: '#B3B3B3',
  backgroundBlue: 'rgba(60,117,227, 0.1)',
  boxShadowCard: 'rgba(19, 19, 19, 0.2)',
  blueBgBtn: 'rgba(60, 117, 227, 0.1)',
  highlightBlue: '#D8E3F9',
  borderBlue: 'rgba(19, 19, 19, 0.2)',
  pressedBlue: '#2861CF',
  opacityGrey: 'rgba(181, 187, 194, 0.2)',
  disableGrey: 'rgba(142, 147, 154, 0.4)',
  secondaryGrey: 'rgba(142, 147, 154, 0.1)',
  darkBlue: '#0D0B23',
  lightBlue: '#257cff',
  lightBlueFilter: '#3C75E31A',
  redDot: '#F86069',
  primaryBlue: '60,117,227',
  bgGrey: '181,187,194',
  transparent: 'transparent',
  negative: ' #F65151',
  desaturated: '#7761ff',
  grey: '#bebebe',
  violet: '#ae5aff',
  darkBrown: '#464646, 10%',
  lightBrown: '#3F3F3F 100%',
  middleGrey: '#c4c4c4',
  middleBlue: '#71bbff',
  lightGreen: '#8fff68',
  birch: '#5bf5ff',
  statusOpenCard: '#51A81B',
  blueDivision: '#C9D9F7',
};

export const StatusColor: IStatusColor = {
  pending: '#F49302',
  bgPending: 'rgba(244, 147, 2, 0.2)',
  active: '#51A81B',
  bgActive: 'rgba(81, 168, 27, 0.2)',
  finished: '#8E939A',
  bgFinished: 'rgba(142, 147, 154, 0.2)',
};

export const ShadowColor: IShadowModel = {
  shadowWrapper: 'rgba(19, 19, 19, 0.2)',
};

function theme(): DefaultTheme {
  return {
    ...colors,
    ...StatusColor,
    ...ShadowColor,
    ...fonts,
    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  };
}

export default theme;
