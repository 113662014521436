import React from 'react';
import Translate from 'shared/components/Translate/Translate';
import FooterStyles from './styles';

export interface IFooterModel {
  loading: boolean;
}

const Footer:React.FC<IFooterModel> = ({ loading }) => (
  <FooterStyles.Container
    loading={loading ? loading.toString() : undefined}
  >
    <p>
      <Translate _key="General.Footer.BocachicaLaunchpad" />
    </p>
    <p>
      <Translate _key="General.Footer.Copyright" />
    </p>
  </FooterStyles.Container>
);

export default Footer;
