import React, {
  createContext, useCallback, useContext, useMemo, useState,
} from 'react';
import {
  EModals,
  IInternalProviderModalState,
  IModalsProps,
  initialModalState,
  ModalContextType,
  ModalProps,
  MODALS,
} from './interfaces/interfaces';
import useDisableBodyScroll from '../../shared/hooks/useDisableBodyScroll';
import useWindowDimensions from '../../shared/hooks/useWindowDimensions';
import { EDimensions } from '../../shared/helpers/constans';

const initialState: ModalContextType = {
  modal: EModals.EMPTY,
  props: null,
  showModal: () => undefined,
  closeModal: () => undefined,
};

const ModalContextHOC = createContext<ModalContextType>(initialState);

export const ModalContextProvider: React.FC = (
  { children },
) => {
  const [modalState, setModalState] = useState<IInternalProviderModalState>(initialModalState);
  const dimension = useWindowDimensions();
  const closeModal = useCallback(() => {
    setModalState(initialModalState);
  }, []);

  const showModal = <T extends EModals>(modal: T, props: ModalProps<T>) => {
    setModalState({ modal, props });
  };

  const Component: IModalsProps[EModals] = MODALS[modalState.modal];

  const modalStore = useMemo(() => ({
    ...modalState,
    showModal,
    closeModal,
  }), [closeModal, modalState]);

  useDisableBodyScroll(EModals.EMPTY !== modalState.modal && dimension === EDimensions.SMALL);

  return (
    <ModalContextHOC.Provider value={modalStore}>
      {children}
      {Component && (
      <Component
        closeModal={closeModal}
        {...modalState.props}
      />
      )}
    </ModalContextHOC.Provider>
  );
};

export const ModalProvider = () => useContext(ModalContextHOC);
