import styled from 'styled-components';

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  height: 3rem`;

const SmallImage = styled.img`
  max-width: 3rem;
  height: 3rem;
  border-radius: .25rem;
`;

export default {
  Image,
  SmallImage,
};
