export type BocachicaMoon = {
  'version': '0.1.0',
  'name': 'bocachica_moon',
  'instructions': [
    {
      'name': 'initializeLaunchpad',
      'accounts': [
        {
          'name': 'owner',
          'isMut': true,
          'isSigner': true
        },
        {
          'name': 'launchpad',
          'isMut': true,
          'isSigner': true
        },
        {
          'name': 'systemProgram',
          'isMut': false,
          'isSigner': false
        }
      ],
      'args': []
    },
    {
      'name': 'createSale',
      'accounts': [
        {
          'name': 'owner',
          'isMut': true,
          'isSigner': true
        },
        {
          'name': 'launchpad',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'systemProgram',
          'isMut': false,
          'isSigner': false
        },
        {
          'name': 'sale',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'depositMint',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'depositAccount',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'distributeMint',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'distributeAccount',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'tokenProgram',
          'isMut': false,
          'isSigner': false
        }
      ],
      'args': [
        {
          'name': 'saleId',
          'type': 'u64'
        },
        {
          'name': 'bump',
          'type': 'u8'
        },
        {
          'name': 'saleType',
          'type': {
            'defined': 'SaleType'
          }
        },
        {
          'name': 'minDeposit',
          'type': 'u64'
        },
        {
          'name': 'maxDeposit',
          'type': 'u64'
        },
        {
          'name': 'targetDeposit',
          'type': 'u64'
        },
        {
          'name': 'targetDistribute',
          'type': 'u64'
        },
        {
          'name': 'startDate',
          'type': 'u64'
        },
        {
          'name': 'endDate',
          'type': 'u64'
        },
        {
          'name': 'distributeSchedule',
          'type': {
            'vec': {
              'defined': 'DistributeSchedule'
            }
          }
        }
      ]
    },
    {
      'name': 'closeSale',
      'accounts': [
        {
          'name': 'owner',
          'isMut': true,
          'isSigner': true
        },
        {
          'name': 'launchpad',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'sale',
          'isMut': false,
          'isSigner': false
        },
        {
          'name': 'saleDepositAccount',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'saleDistributeAccount',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'ownerDepositAccount',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'ownerDistributeAccount',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'tokenProgram',
          'isMut': false,
          'isSigner': false
        }
      ],
      'args': []
    },
    {
      'name': 'setMetadata',
      'accounts': [
        {
          'name': 'owner',
          'isMut': true,
          'isSigner': true
        },
        {
          'name': 'launchpad',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'systemProgram',
          'isMut': false,
          'isSigner': false
        },
        {
          'name': 'sale',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'saleMetadata',
          'isMut': true,
          'isSigner': false
        }
      ],
      'args': [
        {
          'name': 'bump',
          'type': 'u8'
        },
        {
          'name': 'data',
          'type': 'bytes'
        },
        {
          'name': 'overwrite',
          'type': 'bool'
        }
      ]
    },
    {
      'name': 'updateSale',
      'accounts': [
        {
          'name': 'owner',
          'isMut': false,
          'isSigner': true
        },
        {
          'name': 'launchpad',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'systemProgram',
          'isMut': false,
          'isSigner': false
        },
        {
          'name': 'sale',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'distributeMint',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'distributeAccount',
          'isMut': false,
          'isSigner': false
        },
        {
          'name': 'tokenProgram',
          'isMut': false,
          'isSigner': false
        }
      ],
      'args': [
        {
          'name': 'minDeposit',
          'type': 'u64'
        },
        {
          'name': 'maxDeposit',
          'type': 'u64'
        },
        {
          'name': 'targetDeposit',
          'type': 'u64'
        },
        {
          'name': 'targetDistribute',
          'type': 'u64'
        },
        {
          'name': 'startDate',
          'type': 'u64'
        },
        {
          'name': 'endDate',
          'type': 'u64'
        },
        {
          'name': 'isClaimAvailable',
          'type': 'bool'
        },
        {
          'name': 'isRefundAvailable',
          'type': 'bool'
        },
        {
          'name': 'distributeSchedule',
          'type': {
            'option': {
              'vec': {
                'defined': 'DistributeSchedule'
              }
            }
          }
        }
      ]
    },
    {
      'name': 'joinSale',
      'accounts': [
        {
          'name': 'payer',
          'isMut': true,
          'isSigner': true
        },
        {
          'name': 'systemProgram',
          'isMut': false,
          'isSigner': false
        },
        {
          'name': 'participant',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'sale',
          'isMut': false,
          'isSigner': false
        }
      ],
      'args': [
        {
          'name': 'bump',
          'type': 'u8'
        }
      ]
    },
    {
      'name': 'deposit',
      'accounts': [
        {
          'name': 'payer',
          'isMut': false,
          'isSigner': true
        },
        {
          'name': 'tokenProgram',
          'isMut': false,
          'isSigner': false
        },
        {
          'name': 'sale',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'participant',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'depositMint',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'riskAddress',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'payerTokenAccount',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'saleTokenAccount',
          'isMut': true,
          'isSigner': false
        }
      ],
      'args': [
        {
          'name': 'amount',
          'type': 'u64'
        }
      ]
    },
    {
      'name': 'claim',
      'accounts': [
        {
          'name': 'payer',
          'isMut': false,
          'isSigner': true
        },
        {
          'name': 'tokenProgram',
          'isMut': false,
          'isSigner': false
        },
        {
          'name': 'launchpad',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'sale',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'participant',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'distributeMint',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'payerTokenAccount',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'saleTokenAccount',
          'isMut': true,
          'isSigner': false
        }
      ],
      'args': []
    },
    {
      'name': 'refund',
      'accounts': [
        {
          'name': 'payer',
          'isMut': false,
          'isSigner': true
        },
        {
          'name': 'tokenProgram',
          'isMut': false,
          'isSigner': false
        },
        {
          'name': 'launchpad',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'sale',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'participant',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'depositMint',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'payerTokenAccount',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'saleTokenAccount',
          'isMut': true,
          'isSigner': false
        }
      ],
      'args': []
    },
    {
      'name': 'leaveSale',
      'accounts': [
        {
          'name': 'payer',
          'isMut': true,
          'isSigner': true
        },
        {
          'name': 'systemProgram',
          'isMut': false,
          'isSigner': false
        },
        {
          'name': 'participant',
          'isMut': true,
          'isSigner': false
        },
        {
          'name': 'sale',
          'isMut': false,
          'isSigner': false
        }
      ],
      'args': []
    },
    {
      'name': 'createHapiAddress',
      'accounts': [
        {
          'name': 'payer',
          'isMut': true,
          'isSigner': true
        },
        {
          'name': 'systemProgram',
          'isMut': false,
          'isSigner': false
        },
        {
          'name': 'address',
          'isMut': true,
          'isSigner': false
        }
      ],
      'args': [
        {
          'name': 'addr',
          'type': {
            'array': [
              'u8',
              64
            ]
          }
        },
        {
          'name': 'category',
          'type': {
            'defined': 'HapiCategory'
          }
        },
        {
          'name': 'risk',
          'type': 'u8'
        },
        {
          'name': 'bump',
          'type': 'u8'
        },
        {
          'name': 'network',
          'type': 'publicKey'
        },
        {
          'name': 'community',
          'type': 'publicKey'
        }
      ]
    }
  ],
  'accounts': [
    {
      'name': 'address',
      'type': {
        'kind': 'struct',
        'fields': [
          {
            'name': 'community',
            'type': 'publicKey'
          },
          {
            'name': 'network',
            'type': 'publicKey'
          },
          {
            'name': 'address',
            'type': {
              'array': [
                'u8',
                64
              ]
            }
          },
          {
            'name': 'bump',
            'type': 'u8'
          },
          {
            'name': 'caseId',
            'type': 'u64'
          },
          {
            'name': 'reporter',
            'type': 'publicKey'
          },
          {
            'name': 'category',
            'type': {
              'defined': 'HapiCategory'
            }
          },
          {
            'name': 'risk',
            'type': 'u8'
          },
          {
            'name': 'confirmations',
            'type': 'u8'
          }
        ]
      }
    },
    {
      'name': 'launchpad',
      'type': {
        'kind': 'struct',
        'fields': [
          {
            'name': 'version',
            'type': 'u32'
          },
          {
            'name': 'owner',
            'type': 'publicKey'
          },
          {
            'name': 'saleCount',
            'type': 'u64'
          }
        ]
      }
    },
    {
      'name': 'participant',
      'type': {
        'kind': 'struct',
        'fields': [
          {
            'name': 'version',
            'type': 'u32'
          },
          {
            'name': 'sale',
            'type': 'publicKey'
          },
          {
            'name': 'bump',
            'type': 'u8'
          },
          {
            'name': 'payer',
            'type': 'publicKey'
          },
          {
            'name': 'isRefunded',
            'type': 'bool'
          },
          {
            'name': 'depositedAmount',
            'type': 'u64'
          },
          {
            'name': 'claimedAmount',
            'type': 'u64'
          }
        ]
      }
    },
    {
      'name': 'saleMetadata',
      'type': {
        'kind': 'struct',
        'fields': [
          {
            'name': 'saleId',
            'type': 'u64'
          },
          {
            'name': 'bump',
            'type': 'u8'
          },
          {
            'name': 'data',
            'type': 'string'
          }
        ]
      }
    },
    {
      'name': 'sale',
      'type': {
        'kind': 'struct',
        'fields': [
          {
            'name': 'version',
            'type': 'u32'
          },
          {
            'name': 'launchpad',
            'type': 'publicKey'
          },
          {
            'name': 'saleId',
            'type': 'u64'
          },
          {
            'name': 'bump',
            'type': 'u8'
          },
          {
            'name': 'saleType',
            'type': {
              'defined': 'SaleType'
            }
          },
          {
            'name': 'depositMint',
            'type': 'publicKey'
          },
          {
            'name': 'depositAccount',
            'type': 'publicKey'
          },
          {
            'name': 'minDeposit',
            'type': 'u64'
          },
          {
            'name': 'maxDeposit',
            'type': 'u64'
          },
          {
            'name': 'targetDeposit',
            'type': 'u64'
          },
          {
            'name': 'currentDeposit',
            'type': 'u64'
          },
          {
            'name': 'distributeMint',
            'type': 'publicKey'
          },
          {
            'name': 'distributeAccount',
            'type': 'publicKey'
          },
          {
            'name': 'targetDistribute',
            'type': 'u64'
          },
          {
            'name': 'startDate',
            'type': 'u64'
          },
          {
            'name': 'endDate',
            'type': 'u64'
          },
          {
            'name': 'distributeSchedule',
            'type': {
              'vec': {
                'defined': 'DistributeSchedule'
              }
            }
          },
          {
            'name': 'isClaimAvailable',
            'type': 'bool'
          },
          {
            'name': 'isRefundAvailable',
            'type': 'bool'
          }
        ]
      }
    }
  ],
  'types': [
    {
      'name': 'DistributeSchedule',
      'type': {
        'kind': 'struct',
        'fields': [
          {
            'name': 'timestamp',
            'type': 'u64'
          },
          {
            'name': 'quota',
            'type': 'u64'
          },
          {
            'name': 'steps',
            'type': 'u8'
          }
        ]
      }
    },
    {
      'name': 'HapiCategory',
      'type': {
        'kind': 'enum',
        'variants': [
          {
            'name': 'None'
          },
          {
            'name': 'WalletService'
          },
          {
            'name': 'MerchantService'
          },
          {
            'name': 'MiningPool'
          },
          {
            'name': 'Exchange'
          },
          {
            'name': 'DeFi'
          },
          {
            'name': 'OTCBroker'
          },
          {
            'name': 'ATM'
          },
          {
            'name': 'Gambling'
          },
          {
            'name': 'IllicitOrganization'
          },
          {
            'name': 'Mixer'
          },
          {
            'name': 'DarknetService'
          },
          {
            'name': 'Scam'
          },
          {
            'name': 'Ransomware'
          },
          {
            'name': 'Theft'
          },
          {
            'name': 'Counterfeit'
          },
          {
            'name': 'TerroristFinancing'
          },
          {
            'name': 'Sanctions'
          },
          {
            'name': 'ChildAbuse'
          }
        ]
      }
    },
    {
      'name': 'RiskErrorCode',
      'type': {
        'kind': 'enum',
        'variants': [
          {
            'name': 'UnexpectedAccount'
          },
          {
            'name': 'IllegalOwner'
          },
          {
            'name': 'HighAccountRisk'
          }
        ]
      }
    },
    {
      'name': 'Env',
      'type': {
        'kind': 'enum',
        'variants': [
          {
            'name': 'Devnet'
          },
          {
            'name': 'Mainnet'
          }
        ]
      }
    },
    {
      'name': 'Category',
      'type': {
        'kind': 'enum',
        'variants': [
          {
            'name': 'None'
          },
          {
            'name': 'WalletService'
          },
          {
            'name': 'MerchantService'
          },
          {
            'name': 'MiningPool'
          },
          {
            'name': 'Exchange'
          },
          {
            'name': 'DeFi'
          },
          {
            'name': 'OTCBroker'
          },
          {
            'name': 'ATM'
          },
          {
            'name': 'Gambling'
          },
          {
            'name': 'IllicitOrganization'
          },
          {
            'name': 'Mixer'
          },
          {
            'name': 'DarknetService'
          },
          {
            'name': 'Scam'
          },
          {
            'name': 'Ransomware'
          },
          {
            'name': 'Theft'
          },
          {
            'name': 'Counterfeit'
          },
          {
            'name': 'TerroristFinancing'
          },
          {
            'name': 'Sanctions'
          },
          {
            'name': 'ChildAbuse'
          }
        ]
      }
    },
    {
      'name': 'SaleType',
      'type': {
        'kind': 'enum',
        'variants': [
          {
            'name': 'Amount'
          },
          {
            'name': 'Subscription'
          }
        ]
      }
    }
  ],
  'errors': [
    {
      'code': 6000,
      'name': 'UnexpectedAccount',
      'msg': 'Unexpected account has been used'
    },
    {
      'code': 6001,
      'name': 'Unauthorized',
      'msg': 'Account is not authorized to perform this action'
    },
    {
      'code': 6002,
      'name': 'NonSequentialCaseId',
      'msg': 'Non-sequential case ID'
    },
    {
      'code': 6003,
      'name': 'ReleaseEpochInFuture',
      'msg': 'Release epoch is in future'
    },
    {
      'code': 6004,
      'name': 'InvalidMint',
      'msg': 'Invalid mint account'
    },
    {
      'code': 6005,
      'name': 'InvalidToken',
      'msg': 'Invalid token account'
    },
    {
      'code': 6006,
      'name': 'NetworkMismatch',
      'msg': 'Network mismatched'
    },
    {
      'code': 6007,
      'name': 'InvalidMaxMinDeposit',
      'msg': 'Min deposit must be less than Max deposit'
    },
    {
      'code': 6008,
      'name': 'InvalidTargetDeposit',
      'msg': 'Invalid target deposit'
    },
    {
      'code': 6009,
      'name': 'InvalidDate',
      'msg': 'Invalid date'
    },
    {
      'code': 6010,
      'name': 'InvalidDistributeSchedule',
      'msg': 'Invalid Distribute Schedule'
    },
    {
      'code': 6011,
      'name': 'SaleIsOver',
      'msg': 'Sale is already over'
    },
    {
      'code': 6012,
      'name': 'SaleInPending',
      'msg': 'Sale has not started yet'
    },
    {
      'code': 6013,
      'name': 'InvalidSale',
      'msg': 'Invalid sale'
    },
    {
      'code': 6014,
      'name': 'InvalidPayer',
      'msg': 'Invalid payer'
    },
    {
      'code': 6015,
      'name': 'InvalidDepositAmount',
      'msg': 'Invalid deposit amount'
    },
    {
      'code': 6016,
      'name': 'UnavailableClaim',
      'msg': 'The claim is unavailable for this user'
    },
    {
      'code': 6017,
      'name': 'UnavailableRefund',
      'msg': 'The refund is unavailable for this user'
    },
    {
      'code': 6018,
      'name': 'NothingToClaim',
      'msg': 'Nothing to claim for this user'
    },
    {
      'code': 6019,
      'name': 'NothingToRefund',
      'msg': 'Nothing to refund for this user'
    },
    {
      'code': 6020,
      'name': 'IllegalOwner',
      'msg': 'Account has illegal owner'
    },
    {
      'code': 6021,
      'name': 'ActiveSale',
      'msg': 'Participant cant leave active sale'
    },
    {
      'code': 6022,
      'name': 'UnwithdrawnFunds',
      'msg': 'Unwithdrawn funds remained'
    },
    {
      'code': 6023,
      'name': 'HighAccountRisk',
      'msg': 'User account has high risk'
    },
    {
      'code': 6024,
      'name': 'UnfinishedSale',
      'msg': 'Unfinished sale'
    }
  ]
};

export const IDL: BocachicaMoon = {
  version: '0.1.0',
  name: 'bocachica_moon',
  instructions: [
    {
      name: 'initializeLaunchpad',
      accounts: [
        {
          name: 'owner',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'launchpad',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'createSale',
      accounts: [
        {
          name: 'owner',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'launchpad',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'sale',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'depositMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'depositAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'distributeMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'distributeAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'saleId',
          type: 'u64',
        },
        {
          name: 'bump',
          type: 'u8',
        },
        {
          name: 'saleType',
          type: {
            defined: 'SaleType',
          },
        },
        {
          name: 'minDeposit',
          type: 'u64',
        },
        {
          name: 'maxDeposit',
          type: 'u64',
        },
        {
          name: 'targetDeposit',
          type: 'u64',
        },
        {
          name: 'targetDistribute',
          type: 'u64',
        },
        {
          name: 'startDate',
          type: 'u64',
        },
        {
          name: 'endDate',
          type: 'u64',
        },
        {
          name: 'distributeSchedule',
          type: {
            vec: {
              defined: 'DistributeSchedule',
            },
          },
        },
      ],
    },
    {
      name: 'closeSale',
      accounts: [
        {
          name: 'owner',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'launchpad',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'sale',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'saleDepositAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'saleDistributeAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'ownerDepositAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'ownerDistributeAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'setMetadata',
      accounts: [
        {
          name: 'owner',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'launchpad',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'sale',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'saleMetadata',
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'bump',
          type: 'u8',
        },
        {
          name: 'data',
          type: 'bytes',
        },
        {
          name: 'overwrite',
          type: 'bool',
        },
      ],
    },
    {
      name: 'updateSale',
      accounts: [
        {
          name: 'owner',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'launchpad',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'sale',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'distributeMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'distributeAccount',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'minDeposit',
          type: 'u64',
        },
        {
          name: 'maxDeposit',
          type: 'u64',
        },
        {
          name: 'targetDeposit',
          type: 'u64',
        },
        {
          name: 'targetDistribute',
          type: 'u64',
        },
        {
          name: 'startDate',
          type: 'u64',
        },
        {
          name: 'endDate',
          type: 'u64',
        },
        {
          name: 'isClaimAvailable',
          type: 'bool',
        },
        {
          name: 'isRefundAvailable',
          type: 'bool',
        },
        {
          name: 'distributeSchedule',
          type: {
            option: {
              vec: {
                defined: 'DistributeSchedule',
              },
            },
          },
        },
      ],
    },
    {
      name: 'joinSale',
      accounts: [
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'participant',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'sale',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'bump',
          type: 'u8',
        },
      ],
    },
    {
      name: 'deposit',
      accounts: [
        {
          name: 'payer',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'sale',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'participant',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'depositMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'riskAddress',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'payerTokenAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'saleTokenAccount',
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'amount',
          type: 'u64',
        },
      ],
    },
    {
      name: 'claim',
      accounts: [
        {
          name: 'payer',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'launchpad',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'sale',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'participant',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'distributeMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'payerTokenAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'saleTokenAccount',
          isMut: true,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'refund',
      accounts: [
        {
          name: 'payer',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'launchpad',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'sale',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'participant',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'depositMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'payerTokenAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'saleTokenAccount',
          isMut: true,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'leaveSale',
      accounts: [
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'participant',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'sale',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'createHapiAddress',
      accounts: [
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'address',
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'addr',
          type: {
            array: [
              'u8',
              64,
            ],
          },
        },
        {
          name: 'category',
          type: {
            defined: 'HapiCategory',
          },
        },
        {
          name: 'risk',
          type: 'u8',
        },
        {
          name: 'bump',
          type: 'u8',
        },
        {
          name: 'network',
          type: 'publicKey',
        },
        {
          name: 'community',
          type: 'publicKey',
        },
      ],
    },
  ],
  accounts: [
    {
      name: 'address',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'community',
            type: 'publicKey',
          },
          {
            name: 'network',
            type: 'publicKey',
          },
          {
            name: 'address',
            type: {
              array: [
                'u8',
                64,
              ],
            },
          },
          {
            name: 'bump',
            type: 'u8',
          },
          {
            name: 'caseId',
            type: 'u64',
          },
          {
            name: 'reporter',
            type: 'publicKey',
          },
          {
            name: 'category',
            type: {
              defined: 'HapiCategory',
            },
          },
          {
            name: 'risk',
            type: 'u8',
          },
          {
            name: 'confirmations',
            type: 'u8',
          },
        ],
      },
    },
    {
      name: 'launchpad',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'version',
            type: 'u32',
          },
          {
            name: 'owner',
            type: 'publicKey',
          },
          {
            name: 'saleCount',
            type: 'u64',
          },
        ],
      },
    },
    {
      name: 'participant',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'version',
            type: 'u32',
          },
          {
            name: 'sale',
            type: 'publicKey',
          },
          {
            name: 'bump',
            type: 'u8',
          },
          {
            name: 'payer',
            type: 'publicKey',
          },
          {
            name: 'isRefunded',
            type: 'bool',
          },
          {
            name: 'depositedAmount',
            type: 'u64',
          },
          {
            name: 'claimedAmount',
            type: 'u64',
          },
        ],
      },
    },
    {
      name: 'saleMetadata',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'saleId',
            type: 'u64',
          },
          {
            name: 'bump',
            type: 'u8',
          },
          {
            name: 'data',
            type: 'string',
          },
        ],
      },
    },
    {
      name: 'sale',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'version',
            type: 'u32',
          },
          {
            name: 'launchpad',
            type: 'publicKey',
          },
          {
            name: 'saleId',
            type: 'u64',
          },
          {
            name: 'bump',
            type: 'u8',
          },
          {
            name: 'saleType',
            type: {
              defined: 'SaleType',
            },
          },
          {
            name: 'depositMint',
            type: 'publicKey',
          },
          {
            name: 'depositAccount',
            type: 'publicKey',
          },
          {
            name: 'minDeposit',
            type: 'u64',
          },
          {
            name: 'maxDeposit',
            type: 'u64',
          },
          {
            name: 'targetDeposit',
            type: 'u64',
          },
          {
            name: 'currentDeposit',
            type: 'u64',
          },
          {
            name: 'distributeMint',
            type: 'publicKey',
          },
          {
            name: 'distributeAccount',
            type: 'publicKey',
          },
          {
            name: 'targetDistribute',
            type: 'u64',
          },
          {
            name: 'startDate',
            type: 'u64',
          },
          {
            name: 'endDate',
            type: 'u64',
          },
          {
            name: 'distributeSchedule',
            type: {
              vec: {
                defined: 'DistributeSchedule',
              },
            },
          },
          {
            name: 'isClaimAvailable',
            type: 'bool',
          },
          {
            name: 'isRefundAvailable',
            type: 'bool',
          },
        ],
      },
    },
  ],
  types: [
    {
      name: 'DistributeSchedule',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'timestamp',
            type: 'u64',
          },
          {
            name: 'quota',
            type: 'u64',
          },
          {
            name: 'steps',
            type: 'u8',
          },
        ],
      },
    },
    {
      name: 'HapiCategory',
      type: {
        kind: 'enum',
        variants: [
          {
            name: 'None',
          },
          {
            name: 'WalletService',
          },
          {
            name: 'MerchantService',
          },
          {
            name: 'MiningPool',
          },
          {
            name: 'Exchange',
          },
          {
            name: 'DeFi',
          },
          {
            name: 'OTCBroker',
          },
          {
            name: 'ATM',
          },
          {
            name: 'Gambling',
          },
          {
            name: 'IllicitOrganization',
          },
          {
            name: 'Mixer',
          },
          {
            name: 'DarknetService',
          },
          {
            name: 'Scam',
          },
          {
            name: 'Ransomware',
          },
          {
            name: 'Theft',
          },
          {
            name: 'Counterfeit',
          },
          {
            name: 'TerroristFinancing',
          },
          {
            name: 'Sanctions',
          },
          {
            name: 'ChildAbuse',
          },
        ],
      },
    },
    {
      name: 'RiskErrorCode',
      type: {
        kind: 'enum',
        variants: [
          {
            name: 'UnexpectedAccount',
          },
          {
            name: 'IllegalOwner',
          },
          {
            name: 'HighAccountRisk',
          },
        ],
      },
    },
    {
      name: 'Env',
      type: {
        kind: 'enum',
        variants: [
          {
            name: 'Devnet',
          },
          {
            name: 'Mainnet',
          },
        ],
      },
    },
    {
      name: 'Category',
      type: {
        kind: 'enum',
        variants: [
          {
            name: 'None',
          },
          {
            name: 'WalletService',
          },
          {
            name: 'MerchantService',
          },
          {
            name: 'MiningPool',
          },
          {
            name: 'Exchange',
          },
          {
            name: 'DeFi',
          },
          {
            name: 'OTCBroker',
          },
          {
            name: 'ATM',
          },
          {
            name: 'Gambling',
          },
          {
            name: 'IllicitOrganization',
          },
          {
            name: 'Mixer',
          },
          {
            name: 'DarknetService',
          },
          {
            name: 'Scam',
          },
          {
            name: 'Ransomware',
          },
          {
            name: 'Theft',
          },
          {
            name: 'Counterfeit',
          },
          {
            name: 'TerroristFinancing',
          },
          {
            name: 'Sanctions',
          },
          {
            name: 'ChildAbuse',
          },
        ],
      },
    },
    {
      name: 'SaleType',
      type: {
        kind: 'enum',
        variants: [
          {
            name: 'Amount',
          },
          {
            name: 'Subscription',
          },
        ],
      },
    },
  ],
  errors: [
    {
      code: 6000,
      name: 'UnexpectedAccount',
      msg: 'Unexpected account has been used',
    },
    {
      code: 6001,
      name: 'Unauthorized',
      msg: 'Account is not authorized to perform this action',
    },
    {
      code: 6002,
      name: 'NonSequentialCaseId',
      msg: 'Non-sequential case ID',
    },
    {
      code: 6003,
      name: 'ReleaseEpochInFuture',
      msg: 'Release epoch is in future',
    },
    {
      code: 6004,
      name: 'InvalidMint',
      msg: 'Invalid mint account',
    },
    {
      code: 6005,
      name: 'InvalidToken',
      msg: 'Invalid token account',
    },
    {
      code: 6006,
      name: 'NetworkMismatch',
      msg: 'Network mismatched',
    },
    {
      code: 6007,
      name: 'InvalidMaxMinDeposit',
      msg: 'Min deposit must be less than Max deposit',
    },
    {
      code: 6008,
      name: 'InvalidTargetDeposit',
      msg: 'Invalid target deposit',
    },
    {
      code: 6009,
      name: 'InvalidDate',
      msg: 'Invalid date',
    },
    {
      code: 6010,
      name: 'InvalidDistributeSchedule',
      msg: 'Invalid Distribute Schedule',
    },
    {
      code: 6011,
      name: 'SaleIsOver',
      msg: 'Sale is already over',
    },
    {
      code: 6012,
      name: 'SaleInPending',
      msg: 'Sale has not started yet',
    },
    {
      code: 6013,
      name: 'InvalidSale',
      msg: 'Invalid sale',
    },
    {
      code: 6014,
      name: 'InvalidPayer',
      msg: 'Invalid payer',
    },
    {
      code: 6015,
      name: 'InvalidDepositAmount',
      msg: 'Invalid deposit amount',
    },
    {
      code: 6016,
      name: 'UnavailableClaim',
      msg: 'The claim is unavailable for this user',
    },
    {
      code: 6017,
      name: 'UnavailableRefund',
      msg: 'The refund is unavailable for this user',
    },
    {
      code: 6018,
      name: 'NothingToClaim',
      msg: 'Nothing to claim for this user',
    },
    {
      code: 6019,
      name: 'NothingToRefund',
      msg: 'Nothing to refund for this user',
    },
    {
      code: 6020,
      name: 'IllegalOwner',
      msg: 'Account has illegal owner',
    },
    {
      code: 6021,
      name: 'ActiveSale',
      msg: 'Participant cant leave active sale',
    },
    {
      code: 6022,
      name: 'UnwithdrawnFunds',
      msg: 'Unwithdrawn funds remained',
    },
    {
      code: 6023,
      name: 'HighAccountRisk',
      msg: 'User account has high risk',
    },
    {
      code: 6024,
      name: 'UnfinishedSale',
      msg: 'Unfinished sale',
    },
  ],
};
