import React from 'react';
import HeaderLogo from 'assets/images/project-placeholder.svg';
import ProjectLogo from 'assets/images/small-project-logo.svg';
import { t } from 'i18next';
import { ESalesLogo } from '../../interfaces/interface';
import { CDN_PATH, TEST_SALE_COVER } from '../../helpers/constans';
import ProjectImageStyles from './styles';

interface IProjectImage {
  saleId: number,
  typeLogo: ESalesLogo,
}

const testCdnID = localStorage.getItem(TEST_SALE_COVER);

const ProjectImage: React.FC<IProjectImage> = (
  {
    saleId,
    typeLogo,
  },
) => {
  const id = testCdnID ? Number(testCdnID) : saleId;
  switch (typeLogo) {
    case ESalesLogo.HEADER: {
      const media = `${CDN_PATH}/${id}/header.png`;
      return (
        <ProjectImageStyles.Image
          src={media}
          alt={t('Sale.HeaderLogo')}
          onError={(e) => {
            (e.target as HTMLInputElement).onerror = null;
            (e.target as HTMLInputElement).src = HeaderLogo;
          }}
        />
      );
    }

    case ESalesLogo.LOGO: {
      const media = `${CDN_PATH}/${id}/logo.png`;
      return (
        <ProjectImageStyles.SmallImage
          src={media}
          alt={t('Sale.ProjectLogo')}
          onError={(e) => {
            (e.target as HTMLInputElement).onerror = null;
            (e.target as HTMLInputElement).src = ProjectLogo;
          }}
        />
      );
    }

    default: return <></>;
  }
};

export default ProjectImage;
