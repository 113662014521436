import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSaleServiceContext } from 'providers/sale-provider/sale-provider';
import {
  EDimensions,
  EStatuses,
  ROUTES,
  socials,
  UPDATE_SALE_INTERVAL,
} from 'shared/helpers/constans';
import { IParticipantModel, ISaleModel } from 'shared/interfaces/interface';
import ValueSale from 'shared/components/ValueSale/ValueSale';
import SaleLayoutInfo from 'shared/components/SaleLayoutInfo/SaleLayoutInfo';
import { AmountProgress } from 'shared/components/AmountProgress/AmountProgress';
import SaleDate from 'shared/components/SaleDate/SaleDate';
import SocialNetwork from 'shared/components/SocialNetwork';
import { validateCheckbox } from 'shared/components/AmountInputContainer/helpers';
import ToastService from 'services/toastService/toastService';
import BigSkeleton from 'shared/components/Placeholder/BigSkeleton';
import useWindowDimensions from 'shared/hooks/useWindowDimensions';
import useDateAndStatus from 'shared/hooks/useDateAndStatus';
import { t } from 'i18next';
import SaleStyles from './styles';
import { IAmountInputError } from './interfaces';
import { checkSaleShouldUpdate } from '../../shared/helpers/util';
import DepositSection from '../../shared/components/DepositSection/DepositSection';
import VestingSection from '../../shared/components/VestingSection';
import VestingSchedule from '../../shared/components/VestingSchedule';
import RefundSection from '../../shared/components/RefundSection/RefundSection';
import Translate from '../../shared/components/Translate/Translate';

const SalePage: React.FC = (
) => {
  const {
    sales,
    loading,
    joinSale,
    claimAction,
    refundAction,
    transferMoney,
    updateSolanaDataInfo,
    getCurrentSale,
  } = useSaleServiceContext();
  const dimension = useWindowDimensions();
  const history = useHistory();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>('0');
  const [participantData, setParticipantData] = useState<IParticipantModel | null >(null);
  const [isParticipant, setIsParticipant] = useState<boolean>(false);
  const [sale, setSale] = useState<ISaleModel | null>(null);
  const [error, setError] = useState<IAmountInputError>({ valid: true, reason: null });
  const [inputError, setInputError] = useState<IAmountInputError>({ valid: true, reason: null });
  const { id }: {id: string} = useParams();

  useEffect(() => {
    if (!id || !sales) return;
    const currentSale = sales!.find((s) => s.saleId === Number(id));
    if (!currentSale) return;
    setSale(currentSale);
    setIsParticipant(currentSale.isParticipant);
    setParticipantData(currentSale.participant);
  }, [id, loading, sales]);

  const { newStatus } = useDateAndStatus(
    sale?.startDate || 0,
    sale?.endDate || 0,
    sale?.status || EStatuses.CLOSED,
    sale?.saleType,
    sale?.currentDeposit || 0,
    sale?.targetDeposit || 0,
  );

  useEffect(() => {
    const updateSale = async () => {
      try {
        const shouldUpdate = checkSaleShouldUpdate(
          {
            startDate: sale?.startDate || 0,
            endDate: sale?.endDate || 0,
            distributionIn: sale?.distributionIn || null,
          }, newStatus,
        );
        if (!sale || !shouldUpdate) return;
        const updatedSale = await getCurrentSale(Number(id));
        if (!updatedSale) return;
        const extractedSale = updatedSale[0];
        const conditionSaleUpdate = extractedSale.targetDeposit === sale.targetDeposit
          && (newStatus !== EStatuses.CLOSED);
        const isUserParticipant = extractedSale.status === EStatuses.CLOSED && !isParticipant;
        if (conditionSaleUpdate || isUserParticipant) return;
        setSale(extractedSale);
      } catch (e) {
        console.warn(`Error: ${e} while update sale`);
      }
    };

    const interval = setInterval(updateSale, UPDATE_SALE_INTERVAL);
    return () => clearInterval(interval);
  }, [sale, id, newStatus, getCurrentSale]);

  const checkRisks = ({ target }: {target: HTMLInputElement}) => {
    const { checked } = target;
    const validate = validateCheckbox(checked, error, inputError);
    setIsChecked(checked);
    setError(validate);
  };

  const deposit = async () => {
    if (!sale) return;
    if (!isParticipant) await joinSale(sale.saleId);
    ToastService.loading(t('Sale.Deposit'));
    const transaction = await transferMoney(
      amount,
      sale,
    );
    if (!transaction) {
      ToastService.error(t('Sale.Deposit'));
      return;
    }
    ToastService.success(t('Sale.Deposit'));
    await updateSolanaDataInfo();
    setAmount('');
  };

  const makeRefund = async () => {
    await refundAction(
      sale,
    );
  };

  const makeClaim = async () => {
    ToastService.loading(t('Sale.Claim'));
    const claimedAmount = await claimAction(
      sale,
    );

    if (claimedAmount) {
      ToastService.success(t('Sale.Claim'));
      await updateSolanaDataInfo();
    }
  };

  if (loading || !sale || !sales) return <BigSkeleton />;

  const showRefund = sale.participant
    && sale.typeClaim.refundAvailable
    && sale.status === EStatuses.CLOSED;

  const showVesting = sale.status === EStatuses.CLOSED
    && sale.typeClaim.claimAvailable
    && participantData;

  const showVestingSchedule = sale.status === EStatuses.PENDING
    || sale.status === EStatuses.ACTIVE
    || sale.distributionIn?.active;

  return (
    <SaleStyles.Container>
      <SaleStyles.Wrapper>
        <SaleStyles.CloseBtn onClick={() => history.push(ROUTES.HOME)} />
        <SaleLayoutInfo
          name={sale.name}
          tokenIcon={sale.tokenIcon}
          saleStatus={newStatus}
          currency={sale.currency}
          saleType={sale.saleType}
          saleId={sale.saleId}
          isSale
        />
        {
          sale.status !== EStatuses.CLOSED && (
            <DepositSection
              amount={amount}
              participantData={participantData}
              isParticipant={isParticipant}
              setAmount={setAmount}
              currentSale={sale}
              purchase={sale.amountsValue.purchaseAmount}
              error={error}
              setError={setError}
              setInputError={setInputError}
              newStatus={newStatus}
              isChecked={isChecked}
              deposit={deposit}
              checkRisks={checkRisks}
              sale={sale}
            />
          )
        }
        {
          showRefund && (
            <RefundSection
              depositDecimal={sale.depositDecimal}
              currency={sale.project.ticker}
              makeRefund={makeRefund}
              refund={sale.amountsValue.refAmount}
            />
          )
        }
        {
         showVesting && sale.vesting && (
         <VestingSection
           vesting={sale.vesting}
           vestingType={sale.vestingType}
           makeClaim={makeClaim}
           purchase={sale.amountsValue.purchaseAmount}
           participantData={participantData}
           isClaimAvailable={sale.isClaimAvailable}
           status={sale.status}
           rewardTicker={sale.project.ticker}
           depositDecimal={sale.depositDecimal}
           distributionIn={sale.distributionIn}
         />
         )
        }
        <SaleStyles.InfoWrapper>
          <ValueSale
            participantData={participantData}
            currentSale={sale}
            purchase={sale.amountsValue.purchaseAmount}
            refund={sale.amountsValue.refAmount}
          />
          <AmountProgress
            targetDeposit={sale.targetDeposit}
            currentDeposit={sale.currentDeposit}
            currency={sale.currency}
            depositDecimal={sale.depositDecimal}
          />
          <SaleDate
            startDate={sale.startDate}
            endDate={sale.endDate}
          />
          { showVestingSchedule && sale.vesting && (
          <VestingSchedule
            vesting={sale.vesting}
            vestingType={sale.vestingType}
          />
          )}
          <SaleStyles.Description>
            {sale.project.shortDescription}
          </SaleStyles.Description>
          <SaleStyles.SocialWrapper>
            <SocialNetwork socials={socials} isSale />
            <SaleStyles.SocialBox
              href="#"
            >
              <p>
                {
                  dimension !== EDimensions.SMALL
                    ? <Translate _key="Sale.Allbridge" /> : <Translate _key="Sale.GoToAllbridge" />
                }
              </p>
              <SaleStyles.ExternalLink />
            </SaleStyles.SocialBox>
          </SaleStyles.SocialWrapper>
        </SaleStyles.InfoWrapper>
      </SaleStyles.Wrapper>
    </SaleStyles.Container>
  );
};

export default SalePage;
