import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { ReactComponent as BlockchainIconNear } from 'assets/images/near-icon.svg';
import { ReactComponent as BlockchainIconEthereum } from 'assets/images/ethereum-icon.svg';
import { ReactComponent as BlockchainIconSolana } from 'assets/images/solana-icon.svg';
import { blockhainDomains, BlockhainImgEnum } from 'shared/helpers/constans';

const DomainImageMap:{[key:number]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>} = {
  [BlockhainImgEnum.Near]: BlockchainIconNear,
  [BlockhainImgEnum.Solana]: BlockchainIconSolana,
  [BlockhainImgEnum.Ethereum]: BlockchainIconEthereum,
};

const DomainsWrapper = styled.div`
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-top: 1.25rem;
  `}
`;

const BlockchainDomainButton = styled.a<PropsWithChildren<{loading?: string}>>`
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  padding: 0.563rem;
  background: ${({ theme }) => theme.opacityGrey};
  color: ${({ theme, loading }) => (loading ? theme.transparent : theme.globalGrey)};
  border-radius: 1rem;
  text-decoration: none;
  font-family: ${({ theme }) => theme.interSemiBold}, serif;
  & > svg {
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.5rem;
    & path {
      fill: ${({ theme, loading }) => (loading ? theme.transparent : theme.globalGrey)};
    }
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: ${({ theme, loading }) => (loading ? theme.transparent : theme.globalBlack)};
    background: ${({ theme }) => theme.globalWhite};
    & svg {
      & path {
        fill: ${({ theme, loading }) => (loading ? theme.transparent : theme.globalGrey)};
      }
    }
  }
  &:nth-of-type(2) {
    color: ${({ theme, loading }) => (loading ? theme.transparent : theme.globalBlack)};
    background: ${({ theme }) => theme.globalWhite};
    & svg {
      & path {
        fill: ${({ theme, loading }) => (loading ? theme.transparent : theme.globalBlack)};
      }
    }
  }
`;

const BlockhainDomainName = styled.span`
  font-weight: 600;
  font-size: 0.75rem;
`;

export interface IBlockhainDomains {
  loading: boolean;
}

const BlockhainDomainsImage: React.FC<IBlockhainDomains> = ({
  loading,
}) => {
  return (
    <DomainsWrapper>
      {blockhainDomains
        .map((domain) => {
          const { value, type, name } = domain;
          const DomainImage = DomainImageMap[type];
          return (
            <BlockchainDomainButton
              key={value}
              href={value}
              target="_blank"
              rel="noreferrer"
              loading={loading ? loading.toString() : undefined}
            >
              <DomainImage />
              <BlockhainDomainName>
                {name}
              </BlockhainDomainName>
            </BlockchainDomainButton>
          );
        })}
    </DomainsWrapper>
  );
};

export default BlockhainDomainsImage;
