import styled from 'styled-components';

import { ReactComponent as CircleIcon } from 'assets/images/circle-icon.svg';
import { ReactComponent as ClaimedIcon } from 'assets/images/claimed-icon.svg';
import { ReactComponent as NotClaimedIcon } from 'assets/images/not-claimed-icon.svg';

const VestingSection = styled.div`
  background: ${({ theme }) => theme.backgroundBlue};
  border-radius: 1rem;
  margin-top: 1.5rem;
  padding: 1rem 4rem;
  & button {
    width: 23.125rem;
    height: 2.5rem;
    margin: 2rem auto 0;
  }
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    & button {
      width: 100%;
    }
    padding: 1rem 1rem
  `}
`;

const SectionTitle = styled.p`
  font-size: 1rem;
  font-family: ${({ theme }) => theme.interSemiBold}, serif;
  color: ${({ theme }) => theme.globalBlack};
  text-align: center;
`;

const AmountTitle = styled.p`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.primaryGrey};
  font-family: ${({ theme }) => theme.interMedium}, serif;
`;

const AmountValue = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.globalBlack};
  font-family: ${({ theme }) => theme.interSemiBold}, serif;
  padding-top: 0.375rem;
`;

const AmountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  & div {
    &:nth-of-type(2) {
      text-align: center;
    }
  }
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-wrap: wrap;
    justify-content: start;
    & div {
      &:nth-child(-n+2){
        flex: 0 0 50%;
      }
     &:nth-child(2){
        text-align: right;
      }
      &:last-child {
      padding-top: 1.5rem;
  `}
`;

const VestingList = styled.div`
  width: 100%;
  margin-top: 2rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;
  `}
`;

const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
  & > p {
    font-weight: 500;
    font-family: ${({ theme }) => theme.interMedium}, serif;
    font-size: .75rem;
    line-height: .938rem;
    color: ${({ theme }) => theme.primaryGrey};
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: .5rem;
  & > div:last-child {
    display: none;
  }
`;

const Row = styled.div<{ isEnded?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: .75rem;
  padding: 0 .063rem;
  svg {
    margin-right: .75rem;
  }
  p {
    font-size: .875rem;
    font-weight: 600;
    font-family: ${({ theme }) => theme.interSemiBold}, serif;
    line-height: 1.063rem;
    color: ${({ theme, isEnded }) => (isEnded ? theme.globalBlack : theme.lightGrey)};
  }
  & > p:last-child {
    flex: 1;
    text-align: right;
    font-weight: 400;
    font-family: ${({ theme }) => theme.interRegular}, serif;
  }
`;

const Division = styled.div`
  margin: .75rem 0;
  height: .063rem;
  border-radius: .25rem;
  background-color: ${({ theme }) => theme.blueDivision};
`;

const Circle = styled(CircleIcon)`
`;

const DistributionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2.25rem;
  margin-bottom: 4rem;
`;

export default {
  VestingSection,
  SectionTitle,
  AmountTitle,
  AmountValue,
  AmountWrapper,
  VestingList,
  HeaderList,
  List,
  Row,
  Division,
  Circle,
  ClaimedIcon,
  NotClaimedIcon,
  DistributionContainer,
};
