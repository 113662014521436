import React, { useEffect, useState } from 'react';

import { colors } from 'theme/index';
import { calcStrokeDasharray } from '../../helpers/util';
import { ONE_SECOND, radiusProgressCircle } from '../../helpers/constans';

interface IProgressCircle {
  startPeriod: number,
  endPeriod: number,
}

function checkFullProgress(fullProgress: number, currentProgress: number) {
  if (currentProgress > fullProgress) return fullProgress;
  if (currentProgress < 0) return 0;
  return currentProgress;
}

const ProgressCircle: React.FC<IProgressCircle> = ({
  startPeriod,
  endPeriod,
}) => {
  const strokeDasharray = calcStrokeDasharray(radiusProgressCircle);
  const periodDifference = endPeriod - startPeriod;
  const diffFromCurrentTime = endPeriod - Date.now();
  const calcStrokeDashoffset = (strokeDasharray * diffFromCurrentTime) / periodDifference;
  const [strokeDashoffset, setStrokeDashoffset] = useState<number>(
    checkFullProgress(strokeDasharray, calcStrokeDashoffset),
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const updatedDiffFromCurrentTime = endPeriod - Date.now();
      const updatedStrokeDashoffset = (strokeDasharray * updatedDiffFromCurrentTime) / periodDifference;
      setStrokeDashoffset(checkFullProgress(strokeDasharray, updatedStrokeDashoffset));
    }, ONE_SECOND);
    return () => clearInterval(intervalId);
  });

  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8.5"
        r={radiusProgressCircle}
        stroke={colors.globalBlue}
        strokeWidth="1.33"
      />
      <circle
        cx="8"
        cy="8.5"
        r={radiusProgressCircle}
        strokeWidth="1.33"
        stroke={colors.globalBlue}
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashoffset}
        style={{
          transform: 'rotate(-90deg)',
          transformOrigin: 'center',
        }}
      />
    </svg>
  );
};

export default ProgressCircle;
