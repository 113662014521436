import React from 'react';
import Big from 'big.js';
import Translate from '../Translate/Translate';
import { EStatuses, ZERO } from '../../helpers/constans';
import RoundStyles from './styles';
import Timer from '../Timer';
import { detectActiveVesting, getUserDataByVesting } from '../../helpers/vesting';
import { ISaleModel } from '../../interfaces/interface';

interface IHelperSection {
  sale: ISaleModel;
  status: EStatuses;
}

const ClaimWrapper = ({
  title,
  allTokensClaimed,
}: {
  title: string,
  allTokensClaimed?: boolean,
}) => {
  return (
    <RoundStyles.ClaimWrapper allTokensClaimed={allTokensClaimed}>
      {!allTokensClaimed && (
        <RoundStyles.Circle />
      )}
      <Translate _key={title} />
    </RoundStyles.ClaimWrapper>
  );
};

const AdditionalSection: React.FC<IHelperSection> = ({ sale, status }: IHelperSection) => {
  switch (status) {
    case EStatuses.ACTIVE: {
      return (
        <Timer
          status={status}
          isHome
          time={sale.endDate}
        />
      );
    }
    case EStatuses.PENDING: {
      return (
        <Timer
          status={status}
          isHome
          time={sale.startDate}
        />
      );
    }
    case EStatuses.CLOSED: {
      if (sale.distributionIn?.active && sale.typeClaim.claimAvailable) {
        return (
          <Timer
            time={sale.distributionIn.timestamp}
            isDistributionIn={sale.distributionIn.active}
          />
        );
      }
      if (sale.typeClaim.refundAvailable) return <ClaimWrapper title="Card.Refund" />;
      const userDataByVesting = sale.vesting && sale.participant
        ? getUserDataByVesting(
          sale.depositDecimal,
          sale.vesting,
          sale.vestingType,
          sale.amountsValue.purchaseAmount,
          sale.typeClaim.claimAvailable,
          sale.participant,
        )
        : null;
      if (sale.typeClaim.claimAvailable
        && userDataByVesting
        && Big(userDataByVesting.availableTokens).gt(ZERO)
      ) return <ClaimWrapper title="Card.Claim" />;

      const allTokensClaimed = userDataByVesting
        && Big(userDataByVesting.totalLocked).eq(ZERO)
        && Big(userDataByVesting.availableTokens).eq(ZERO)
        && !Big(userDataByVesting.claimed).eq(ZERO);
      if (allTokensClaimed) {
        return (
          <ClaimWrapper
            title="Card.AllTokens"
            allTokensClaimed={allTokensClaimed}
          />
        );
      }

      const activeVesting = userDataByVesting && sale.typeClaim.claimAvailable
        ? detectActiveVesting(userDataByVesting.vestingArray, sale.vestingType)
        : null;
      if (!activeVesting) return null;
      return (
        <Timer
          time={activeVesting.timestamp}
          vestingType={sale.vestingType}
        />
      );
    }

    default: {
      return null;
    }
  }
};

export default AdditionalSection;
