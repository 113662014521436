import React from 'react';
import { useHistory } from 'react-router-dom';
import headerStyles from '../styles';
import { ROUTES } from '../../../helpers/constans';

export interface ILeftHeaderSide {
  isOpened: boolean;
  setIsOpened: (v: boolean) => void;
  checkLoading: string | undefined
}

const LeftHeaderSide: React.FC<ILeftHeaderSide> = (
  {
    isOpened,
    setIsOpened,
    checkLoading,
  },
) => {
  const history = useHistory();
  return (
    <headerStyles.LeftRow>
      <headerStyles.Toggle isOpen={isOpened} onClick={() => setIsOpened(!isOpened)}>
        <div><span /></div>
        <div><span /></div>
        <div><span /></div>
      </headerStyles.Toggle>
      <headerStyles.BochaChicaLogo
        loading={checkLoading}
        onClick={() => history.push(ROUTES.HOME)}
      />
    </headerStyles.LeftRow>
  );
};

export default LeftHeaderSide;
