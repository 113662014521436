import React, { PropsWithChildren, useMemo } from 'react';
import styled from 'styled-components';
import { EStatuses } from '../helpers/constans';
import Timestamp from './Timestamp/Timestamp';
import Translate from './Translate/Translate';
import { ETypeVesting } from '../interfaces/interface';

const TimerBlock = styled.div<PropsWithChildren<{
  isHome: boolean | undefined,
  isVesting?: boolean,
  isDistributionIn: boolean
}>>`
  height: 1.75rem;
  border-radius: 4px;
  background: ${({ isVesting, theme }) => (isVesting ? 'transparent' : `rgba(${theme.bgGrey}, 0.2)`)};
  max-width: ${({ isVesting }) => (isVesting ? '23.125rem' : 'none')};
  margin-top: ${({ isVesting }) => (isVesting ? '1.125rem' : '0')};
  color: ${({ theme }) => theme.globalBlack};
  font-family: ${({ theme }) => theme.interSemiBold}, serif;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  & span {
    font-family: ${({ theme }) => theme.interRegular}, serif;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.globalBlack};
    margin-right: ${({ isHome, isDistributionIn }) => (isHome || isDistributionIn ? '0.5rem' : '0')};
  }`;

export interface ITimer {
  status?: EStatuses;
  time: number;
  isHome?: boolean;
  vestingType?: ETypeVesting | null,
  isDistributionIn?: boolean,
}

const getVestingTitle = (vestingType: ETypeVesting) => {
  switch (vestingType) {
    case ETypeVesting.OneTime: return <Translate _key="Sale.LeftToUnlock" />;
    case ETypeVesting.Stepwise: return <Translate _key="Sale.LeftToNextUnlock" />;
    default: return null;
  }
};

const getStatusTitle = (status?: EStatuses) => {
  switch (status) {
    case EStatuses.PENDING:
      return <Translate _key="Sale.StartsIn" />;
    case EStatuses.ACTIVE:
      return <Translate _key="Sale.EndsIn" />;
    default:
      return null;
  }
};

const Timer: React.FC<ITimer> = (
  {
    status,
    time,
    isHome,
    vestingType,
    isDistributionIn,
  },
) => {
  const vestingStatusTitle = useMemo(() => (vestingType
    ? getVestingTitle(vestingType)
    : getStatusTitle(status)), [status, vestingType]);
  const distributionIn = isDistributionIn ? <Translate _key="Sale.DistributionIn" /> : null;
  const title = vestingStatusTitle || distributionIn;
  if (!title) return null;
  return (
    <TimerBlock
      isVesting={Boolean(vestingType)}
      isHome={isHome}
      isDistributionIn
    >
      <span>
        {title}
      </span>
      <Timestamp
        time={time}
      />
    </TimerBlock>
  );
};

export default Timer;
