import React from 'react';
import { useHistory } from 'react-router-dom';
import { toSwap } from '../../helpers/constans';
import Translate from '../Translate/Translate';
import StatusLabel from '../StatusLabel/StatusLabel';

import RowStyle from '../Modals/SearchModal/styles';
import { ESalesLogo, ISaleModel } from '../../interfaces/interface';
import ProjectImage from '../ProjectImage';

const SearchResultRow: React.FC<any> = ({
  close,
  sales,
  searchValue,
}) => {
  const history = useHistory();

  const highlightText = (value: string, name: string) => {
    if (!value) return name;
    const regexp = new RegExp(value, 'ig');
    const matchValue = name.match(regexp);
    if (matchValue) {
      return name.split(regexp).map((s, index, array) => {
        if (index < array.length - 1) {
          const c = matchValue.shift();
          return (
            <>
              {s}
              <span key={s}>{c}</span>
            </>
          );
        }
        return s;
      });
    }
    return name;
  };

  return (
    sales.map((sale:ISaleModel) => (
      <RowStyle.Row
        key={sale.saleId}
        onClick={() => {
          close();
          history.push(toSwap(sale.saleId));
        }}
      >
        <RowStyle.ImageContainer>
          <ProjectImage saleId={sale.saleId} typeLogo={ESalesLogo.LOGO} />
        </RowStyle.ImageContainer>
        <RowStyle.WrapperDescription>
          <RowStyle.Title>
            {
              highlightText(searchValue, sale.name)
            }
          </RowStyle.Title>
          <RowStyle.Label>
            {' '}
            {sale.currency}
            {' '}
            <Translate _key="Sale.Stage" />
          </RowStyle.Label>
        </RowStyle.WrapperDescription>
        <StatusLabel saleStatus={sale.status} />
      </RowStyle.Row>
    ))
  );
};

export default SearchResultRow;
