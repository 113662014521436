import React from 'react';
import useCountdown from 'shared/hooks/useCountdown';
import TimestampStyle from './styles';
import Translate from '../Translate/Translate';

export interface ITimestampModel {
  time: number;
}

const Timestamp: React.FC<ITimestampModel> = (
  { time } : ITimestampModel,
) => {
  const countdown = useCountdown(time / 1000);
  return (
    <TimestampStyle.Time>
      <Translate
        _key="Sale.TimestampDay"
        interpolation={{
          day: +countdown.days === 0 ? 0 : countdown.days,
        }}
      />
      <TimestampStyle.Divider />
      <Translate
        _key="Sale.Timestamp"
        interpolation={{
          hours: countdown.hours,
          minutes: countdown.minutes,
          seconds: countdown.seconds,
        }}
      />
    </TimestampStyle.Time>
  );
};
export default Timestamp;
