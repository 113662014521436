import React from 'react';
import styled from 'styled-components';
import { IAmountInputError } from 'views/Sale/interfaces';
import { ReactComponent as ErrorLogo } from 'assets/images/error-icon.svg';
import Translate from '../Translate/Translate';

const Error = styled.span`
  color: ${({ theme }) => theme.negative};
  text-align: center;
  font-size: 0.75rem;
  font-family: ${({ theme }) => theme.interRegular}, serif;
  margin: 0 auto;
`;

const ErrorIcon = styled(ErrorLogo)`
  margin-right: .25rem;
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ErrorWarning: React.FC<{ error: IAmountInputError}> = (
  {
    error,
  },
) => {
  return (
    <Error>
      {
        error.reason && (
          <ErrorWrapper>
            <ErrorIcon />
            <Translate _key={error.reason} />
          </ErrorWrapper>

        )
      }
    </Error>
  );
};

export default ErrorWarning;
