import React, { Fragment } from 'react';
import VestingStyles from './styles';
import { EVestingStatus, IUserVesting } from '../../interfaces/interface';
import Translate from '../Translate/Translate';
import CurrentLogo from './CurrentLogo';
import { displayAmount } from '../../helpers/util';

interface IVestingList {
  rewardTicker: string;
  vesting: IUserVesting[];
  depositDecimal: number
}

const VestingList: React.FC<IVestingList> = (
  {
    vesting,
    rewardTicker,
    depositDecimal,
  },
) => {
  return (
    <VestingStyles.VestingList>
      <VestingStyles.HeaderList>
        <p>
          <Translate _key="Sale.Vesting.Amount" />
        </p>
        <p>
          <Translate _key="Sale.Vesting.UnlockDate" />
        </p>
      </VestingStyles.HeaderList>
      <VestingStyles.List>
        {vesting.map((row) => (
          <Fragment key={row.timestamp}>
            <VestingStyles.Row isEnded={row.status === EVestingStatus.ENDED}>
              <CurrentLogo
                status={row.status}
                startPeriod={row.previousVestingDate}
                endPeriod={row.timestamp}
              />
              <p>
                <Translate
                  _key="Sale.PriceValue"
                  interpolation={{
                    price: displayAmount(row.amount, depositDecimal),
                    currency: rewardTicker,
                  }}
                />
              </p>
              <p>
                <Translate
                  _key="Sale.Vesting.Date"
                  interpolation={{
                    day: row.dateObj.day,
                    month: row.dateObj.month,
                    year: row.dateObj.year,
                  }}
                />
              </p>
            </VestingStyles.Row>
            <VestingStyles.Division />
          </Fragment>
        ))}
      </VestingStyles.List>
    </VestingStyles.VestingList>
  );
};

export default VestingList;
