import styled from 'styled-components';
import { ReactComponent as SocialIcon } from 'assets/images/social.svg';

const LayoutParent = styled.div`
  position: relative;
  height: 3rem;
`;

const ProjectLayoutImg = styled.img`
  width: 100%;
  object-fit: cover;
  height: 3rem;
`;

const ProjectInfoNaming = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
`;

const ProjectName = styled.p`
  font-size: 1.25rem;
  font-family: ${({ theme }) => theme.interSemiBold}, serif;
  color: ${({ theme }) => theme.globalBlack};
`;

const StageRow = styled.div`
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  & span {
    &:nth-of-type(2) {
      margin: 0 0.5rem;
    }
  }
`;

const StageIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.813rem;
`;

const SocialImg = styled(SocialIcon)`
  width: 1.25rem;
  height: 1.438rem;
  margin-right: 1.125rem;
`;

const StageTxt = styled.div`
  color: ${({ theme }) => theme.primaryGrey};
  font-size: 0.75rem;
  font-family: ${({ theme }) => theme.interMedium}, serif;
`;

const StatusRow = styled.div`
  display: flex;
  align-items: center;
`;

export default {
  LayoutParent,
  ProjectLayoutImg,
  ProjectInfoNaming,
  ProjectName,
  StageRow,
  StageIcon,
  StageTxt,
  StatusRow,
  SocialImg,
};
