import React, {
  useMemo,
} from 'react';

import {
  getPhantomWallet,
}
  from '@solana/wallet-adapter-wallets';

import { nodeUrl } from 'services/config';
import {
  SolanaConnectionProvider,
  SolanaWalletModalProvider,
  SolanaWalletProvider,
} from './wallet-adapter';
import { SendTransactionProvider } from '../sendTransactionProvider/sendTransactionProvider';

const SolanaProvider: React.FC = ({ children }) => {
  const endpoint = nodeUrl;
  const wallets = useMemo(
    () => [
      getPhantomWallet(),
    ],
    [],
  );

  return (
    <SolanaConnectionProvider endpoint={endpoint}>
      <SolanaWalletProvider wallets={wallets} autoConnect>
        <SolanaWalletModalProvider>
          <SendTransactionProvider
            endpoint={endpoint}
          >
            {children}
          </SendTransactionProvider>
        </SolanaWalletModalProvider>
      </SolanaWalletProvider>
    </SolanaConnectionProvider>
  );
};

export default SolanaProvider;
