import React from 'react';
import styled from 'styled-components';
import Translate from '../Translate/Translate';
import { percentageCalculator } from '../AmountInputContainer/helpers';
import { displayAmount } from '../../helpers/util';

export const AmountBlock = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.globalBlack};
  font-family: ${({ theme }) => theme.interSemiBold}, serif;
  margin-top: 3rem;
  & p {
    color: ${({ theme }) => theme.primaryGrey};
    font-family: ${({ theme }) => theme.interMedium}, serif;
  }
  &  progress {
    margin-top: 0.688rem;
    width: 100%;
    height: 1rem;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    border-radius: 0.25rem;
    &::-webkit-progress-bar {
      background: rgba( ${({ theme }) => theme.primaryBlue}, 0.2);
      border-radius: 4px;
    }
    &::-webkit-progress-value {
      background: rgba(${({ theme }) => theme.primaryBlue});
    }
  }
`;

export const BottomInfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.globalBlack};
  margin-top: 0.75rem;
  font-size: 1rem;
`;

export interface IAmountProgress {
  targetDeposit: number;
  currentDeposit: number;
  currency: string;
  depositDecimal: number;
}

export const AmountProgress: React.FC<IAmountProgress> = (
  {
    targetDeposit,
    currentDeposit,
    currency,
    depositDecimal,
  },
) => (
  <AmountBlock>
    <div>
      <p>
        <Translate _key="General.Home.Progress" />
      </p>
    </div>
    <progress value={currentDeposit} max={targetDeposit} />
    <BottomInfoBlock>
      <span>
        {
          percentageCalculator(
            Number(currentDeposit),
            Number(targetDeposit),
          )
        }
        %
      </span>
      <span>
        {`${displayAmount(currentDeposit, depositDecimal)} 
        / ${displayAmount(targetDeposit, depositDecimal)}`}
        {' '}
        {currency}
      </span>
    </BottomInfoBlock>
  </AmountBlock>
);
