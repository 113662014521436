import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from '../../../assets/images/arrow-icon.svg';

const RoundItem = styled.div<{ isOpen?:boolean }>`
  padding: 1rem;
  font-family: ${({ theme }) => theme.interSemiBold}, serif;
  background: ${({ theme }) => theme.globalWhite};
  box-shadow: 0 0 0 3px ${({ theme, isOpen }) => (isOpen ? theme.statusOpenCard : theme.transparent)};
  border-radius: 1rem;
  cursor: pointer;
  margin-top: 1.5rem;
  width: 100%;
  &:first-child {
    margin-top: 0;
  }
`;

const NameValue = styled.p`
  font-size: 0.75rem;
  font-family: ${({ theme }) => theme.interMedium}, serif;
  color: ${({ theme }) => theme.primaryGrey};
  text-align: right;
`;

const CurrencyValue = styled.p`
  font-family: ${({ theme }) => theme.interSemiBold}, serif;
  font-size: 1rem;
  color: ${({ theme }) => theme.globalBlack};
  padding-top: 0.375rem;
`;

const NavigationRow = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: end;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
  `}
  & div {
    &:nth-of-type(-n+2) {
      ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 50%;
    `}
    }
      &:nth-of-type(2n) {
        ${({ theme }) => theme.mediaWidth.upToSmall`
        text-align: right;
    `}
      }
      &:nth-of-type(3n) {
        ${({ theme }) => theme.mediaWidth.upToSmall`
          margin-top: 1rem;
          width: 85%;
      `}
    }
  }
`;

const Arrow = styled(ArrowIcon)`
  cursor: pointer;
`;

const Footer = styled.footer`
  margin-top: 1rem;
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `}
`;

const SaleInfoWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  max-width: 22.5rem;
  & > div:first-child {
    align-items: flex-start;
  }
  & > div:last-child {
    align-items: flex-end;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    max-width: unset;
  `}
`;

const RightSide = styled.div<{ hideTimer?: boolean }>`
  display: flex;
  align-items: flex-end;
  div {
    :first-child {
      margin-right: ${({ hideTimer }) => (hideTimer ? '0' : '1rem')};
      ${({ theme }) => theme.mediaWidth.upToSmall`
        margin: 0;
      `}
    }
    :last-child {
      display: flex;
    }
  }
  ${({ theme, hideTimer }) => theme.mediaWidth.upToSmall`
    margin-top: 1rem;
    width: 100%;
    justify-content: ${hideTimer ? 'flex-end' : 'space-between'};
  `}
`;

const SaleInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  & > p {
    margin: 0;

    :first-child {
      margin-bottom: .5rem;
      font-weight: 500;
      font-size: .75rem;
      line-height: .938rem;
      color: ${({ theme }) => theme.grey};
    }

    :last-child {
      font-weight: 500;
      font-size: 1rem;
      line-height: .938rem;
      color: ${({ theme }) => theme.black};
    }
  }
`;

const ClaimWrapper = styled.div<{ allTokensClaimed?: boolean }>`
  display: flex;
  align-items: center;
  height: 1.75rem;
  padding: 0 .5rem;
  background-color: ${({ theme, allTokensClaimed }) => (allTokensClaimed
    ? `rgba(${theme.bgGrey}, 0.2)`
    : theme.backgroundBlue)};
  border-radius: 4px;
  min-width: 14rem;
  font-family: ${({ theme }) => theme.interRegular}, serif;
  color: ${({ theme }) => theme.globalBlack};
  font-style: normal;
  font-weight: 500;
  font-size: .75rem;
  line-height: .938rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `}
`;

const Circle = styled.div`
  margin-right: .5rem;
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.globalBlue};
  border-radius: 50%;
`;

export default {
  RoundItem,
  NavigationRow,
  Arrow,
  CurrencyValue,
  NameValue,
  Footer,
  SaleInfoWrapper,
  RightSide,
  SaleInfoBlock,
  ClaimWrapper,
  Circle,
};
