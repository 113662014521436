import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ModalContextProvider } from 'providers/modal-provider/modal-provider';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';
import {
  BrowserRouter as Router,
}
  from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import './shared/i18n/i18n';
import { SolanaServiceProvider } from './providers/sale-provider/sale-provider';
import AppRoutes from './routes';
import SolanaProvider from './providers/solana-wallet-provider/solana-provider';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Suspense fallback="loading">
          <SolanaProvider>
            <SolanaServiceProvider>
              <ModalContextProvider>
                <AppRoutes />
              </ModalContextProvider>
            </SolanaServiceProvider>
          </SolanaProvider>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
