import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/images/close_modal.svg';
import { ReactComponent as NearIcon } from 'assets/images/User.svg';

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  justify-content: space-between;
  color: ${({ theme }) => theme.globalBlack};
  font-family: ${({ theme }) => theme.interSemiBold}, serif;
  & > p {
    margin: 0;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`;

const Close = styled(CloseIcon)`
  width: 14px;
  height: 14px;
  fill: black;
  :hover {
    cursor: pointer;
  }
`;

const WalletWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  & > p {
    margin-left: 1rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375rem;
    color: ${({ theme }) => theme.globalBlack};
    font-family: ${({ theme }) => theme.interSemiBold}, serif;
  }
`;

const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 .5rem 2rem;
  & > div:first-child {
    margin-bottom: 1.5rem;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > p {
    margin: 0;
    font-weight: 500;
    font-size: .75rem;
    line-height: 1.063rem;
    color: ${({ theme }) => theme.globalBlack};
    font-family: ${({ theme }) => theme.interMedium}, serif;
  }
  & > p:first-child {
    color: ${({ theme }) => theme.primaryGrey};
    font-family: ${({ theme }) => theme.interMedium}, serif;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > button {
    width: 100%;
    height: 100vh;
  }

  & > button:first-child {
    margin-bottom: 1rem;
  }
`;

export default {
  Header,
  Close,
  WalletWrapper,
  NearIcon,
  BalanceWrapper,
  Row,
  Footer,
};
