import React from 'react';
import Translate from '../Translate/Translate';

import SaleDataStyles from './styles';
import { ParseDate } from '../../helpers/util';

export interface ISaleDateModel {
  startDate: number;
  endDate: number;
}

const SaleDate: React.FC<ISaleDateModel> = (
  {
    startDate,
    endDate,
  },
) => {
  const start = ParseDate(startDate);
  const finish = ParseDate(endDate);
  return (
    <>
      <SaleDataStyles.DeadlineWrapper>
        <div>
          <span>
            <Translate _key="Sale.StartDate" />
          </span>
          <p>
            {`${start.day} ${start.month} ${start.year} ${start.hours}:${start.minutes}`}
            {' '}
            UTC
          </p>
        </div>
        <div>
          <span>
            <Translate _key="Sale.FinishDate" />
          </span>
          <p>
            {`${finish.day} ${finish.month} ${finish.year} ${finish.hours}:${finish.minutes}`}
            {' '}
            UTC
          </p>
        </div>
      </SaleDataStyles.DeadlineWrapper>
    </>
  );
};

export default SaleDate;
