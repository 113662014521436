import React, { useMemo, useState } from 'react';
import Button from 'shared/components/Buttons';
import { useSaleServiceContext } from 'providers/sale-provider/sale-provider';
import { PublicKey } from '@solana/web3.js';
import isEqual from 'lodash/isEqual';
import ModalWrapper from '../ModalWrapper';
import { DEFAULT_FILTER, EDimensions } from '../../../helpers/constans';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import Translate from '../../Translate/Translate';

import FilterModalStyles from './styles';
import { capitalizeFirstLetter, toArray } from '../../../helpers/util';
import {
  EFilterByParticipation, EFilterByResult, EFilterByStatus, IFilter,
} from '../../../interfaces/interface';

export interface IFilterModal {
  closeModal: () => void;
  publicKey: PublicKey
}

const FilterModal: React.FC<IFilterModal> = (
  {
    closeModal,
    publicKey,
  },
) => {
  const { filterType, setFilterType } = useSaleServiceContext();
  const [filter, setFilter] = useState<IFilter>(filterType);
  const dimension = useWindowDimensions();
  const isGlobalDefaultFilter = useMemo(() => isEqual(DEFAULT_FILTER, filterType), [filterType]);
  const isLocalDefaultFilter = useMemo(() => isEqual(DEFAULT_FILTER, filter), [filter]);
  const sameFilters = useMemo(() => isEqual(filterType, filter), [filter, filterType]);

  const handleConfirm = () => {
    setFilterType(filter);
    closeModal();
  };

  const handleCancelConfirm = () => {
    setFilterType(filterType);
    closeModal();
  };

  const handleClearFilter = () => {
    setFilter(DEFAULT_FILTER);
  };

  return (
    <ModalWrapper
      closeModal={closeModal}
      isCentered={dimension !== EDimensions.SMALL}
      isFullWidth={dimension === EDimensions.SMALL}
    >
      <FilterModalStyles.Header>
        <p><Translate _key="General.Modal.Filters" /></p>
        <FilterModalStyles.Close onClick={closeModal} />
      </FilterModalStyles.Header>
      <FilterModalStyles.Body>
        <FilterModalStyles.Wrapper>
          <FilterModalStyles.TitleWrapper><Translate _key="General.Filter.Status" /></FilterModalStyles.TitleWrapper>
          <FilterModalStyles.WrapperBtn>
            {toArray(EFilterByStatus).map((status) => (
              <FilterModalStyles.FilterBtn
                key={status}
                isActive={status === filter.filterByStatus}
                onClick={() => setFilter({
                  ...filter,
                  filterByStatus: status,
                })}
              >
                {capitalizeFirstLetter(status)}
              </FilterModalStyles.FilterBtn>
            ))}
          </FilterModalStyles.WrapperBtn>
        </FilterModalStyles.Wrapper>
        <FilterModalStyles.Wrapper>
          <FilterModalStyles.TitleWrapper><Translate _key="General.Filter.PoolParticipation" /></FilterModalStyles.TitleWrapper>
          <FilterModalStyles.WrapperBtn>
            {toArray(EFilterByParticipation).map((status) => (
              <FilterModalStyles.FilterBtn
                key={status}
                isActive={status === filter.filterByParticipation}
                onClick={() => setFilter({
                  ...filter,
                  filterByParticipation: status,
                })}
                disabled={!publicKey}
              >
                {capitalizeFirstLetter(status)}
              </FilterModalStyles.FilterBtn>
            ))}
          </FilterModalStyles.WrapperBtn>
        </FilterModalStyles.Wrapper>
        <FilterModalStyles.Wrapper>
          <FilterModalStyles.TitleWrapper><Translate _key="General.Filter.Result" /></FilterModalStyles.TitleWrapper>
          <FilterModalStyles.WrapperBtn>
            {toArray(EFilterByResult).slice(0, -1).map((status) => (
              <FilterModalStyles.FilterBtn
                key={status}
                isActive={status === filter.filterByResult}
                onClick={() => setFilter({
                  ...filter,
                  filterByResult: status,
                })}
                disabled={!publicKey}
              >
                {capitalizeFirstLetter(status)}
              </FilterModalStyles.FilterBtn>
            ))}
          </FilterModalStyles.WrapperBtn>
        </FilterModalStyles.Wrapper>
      </FilterModalStyles.Body>
      {
        (!isGlobalDefaultFilter || !sameFilters) && !isLocalDefaultFilter && (
          <FilterModalStyles.ClearButton
            onClick={handleClearFilter}
          >
            <Translate _key="General.Action.ClearFilters" />
          </FilterModalStyles.ClearButton>
        )
      }
      <FilterModalStyles.Footer>
        <Button.Primary
          onClick={handleConfirm}
          disabled={sameFilters}
          isApply
        >
          <Translate _key="General.Action.Apply" />
        </Button.Primary>
        <Button.Secondary
          onClick={handleCancelConfirm}
        >
          <Translate _key="General.Action.Cancel" />
        </Button.Secondary>
      </FilterModalStyles.Footer>
    </ModalWrapper>
  );
};

export default FilterModal;
