import styled, { keyframes } from 'styled-components';
import { ReactComponent as External } from 'assets/images/external-link.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';

const Container = styled.div`
  max-width: 43rem;
  margin: 0 auto;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%
 `}
`;

const Wrapper = styled.div`
  background: ${({ theme }) => theme.globalWhite};
  position: relative;
  box-shadow: 0 0.25rem 0.5rem -0.25rem ${({ theme }) => theme.shadowWrapper};
  border-radius: 1rem;
  padding: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0;
    padding: 1rem 1rem 5rem;
 `}
`;

const Approve = styled.div`
  margin-top: 1.5rem;
  display: flex;
  align-items: start;
  position: relative;
`;

const bounce = keyframes`
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
`;

const AcceptInput = styled.label`
  position: relative;
  input, svg {
    width: 1rem;
    height: 1rem;
    display: block;
  }
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    background: ${({ theme }) => theme.globalWhite};
    border: none;
    margin: .063rem 0 0 .25rem;
    cursor: pointer;
    border-radius: 4px;
    transition: box-shadow .3s;
    box-shadow: inset 0 0 0 .063rem ${({ theme }) => theme.primaryGrey};
    :hover {
      box-shadow: inset 0 0 0 .125rem ${({ theme }) => theme.primaryGrey};
    }
    :checked {
      box-shadow: inset 0 0 0 .688rem ${({ theme }) => theme.globalBlue};
      & + svg {
        animation: ${bounce} .4s linear forwards .2s;
      }
    }
    :disabled {
      box-shadow: inset 0 0 0 .063rem ${({ theme }) => theme.primaryGrey};
      cursor: not-allowed;
    }
  }
  svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: ${({ theme }) => theme.globalWhite};
    position: absolute;
    top: 1px;
    left: 4px;
    transform: scale(0) translateZ(0);
  }
`;

const ApproveDscr = styled.div`
  font-family: ${({ theme }) => theme.interRegular}, serif;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.lightGrey};
  padding-left: 0.813rem;
`;

const InfoWrapper = styled.div`
  padding: 0 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0;
 `}
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
  `}
`;

const DeadlineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  & span {
    color: ${({ theme }) => theme.primaryGrey};
    font-family: ${({ theme }) => theme.interMedium}, serif;
    font-size: 0.75rem;
  }
  & p {
    color: ${({ theme }) => theme.globalBlack};
    font-family: ${({ theme }) => theme.interSemiBold}, serif;
    font-size: 1rem;
    padding-top: 0.375rem;
  }
`;

const Description = styled.div`
  color: ${({ theme }) => theme.lightGrey};
  font-size: 0.875rem;
  line-height: 140%;
  font-family: ${({ theme }) => theme.interRegular}, serif;
  margin-top: 3rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 2.25rem;
  `}
`;

const SocialWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 4.375rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    justify-content: center;
    & div {
     margin-top: 1.938rem;
    }
  `}
  & p {
    font-size: 1rem;
    color: ${({ theme }) => theme.globalBlue};
    font-family: ${({ theme }) => theme.interSemiBold}, serif;
    margin-right: 0.938rem;
  }
`;

const ExternalLink = styled(External)`
  width: 1.5rem;
  height: 1.5rem;
`;

const SocialBox = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 3rem;
    justify-content: center;
    order: -1;
    background: rgba(60, 117, 227, 0.1);
    border-radius: 0.5rem;
  `}
`;

const CloseBtn = styled(CloseIcon)`
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1%;
  right: 1%;
  cursor: pointer;
  z-index: 1;
`;

export default {
  Container,
  Wrapper,
  ApproveDscr,
  Approve,
  AcceptInput,
  InfoWrapper,
  ActionContainer,
  DeadlineWrapper,
  Description,
  SocialWrapper,
  ExternalLink,
  SocialBox,
  CloseBtn,
};
