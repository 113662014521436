import { Dispatch, SetStateAction } from 'react';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import { BN, Program, Provider } from '@project-serum/anchor';
import { BocachicaMoon } from '../helpers/bocachica_moon';
import { EStatuses } from '../helpers/constans';

export enum EAppEnvironment {
  DEV = 'dev',
  TEST = 'test',
}

export enum EFilterByStatus {
  ALL = 'ALL',
  OPEN = 'OPEN',
  SOON = 'SOON',
  CLOSED = 'CLOSED',
}

export enum EFilterByParticipation {
  ALL = 'ALL',
  JOINED = 'JOINED',
}

export enum EFilterByResult {
  ALL = 'ALL',
  CLAIM = 'CLAIM',
  REFUND = 'REFUND',
  BOTH = 'BOTH',
}

export type SalesStoreContextType = {
  loading: boolean;
  setLoading: Dispatch<boolean>
  sales: ISaleModel[] | [];
  setSales: Dispatch<SetStateAction<ISaleModel[]>>;
  filterType: IFilter;
  setFilterType: Dispatch<IFilter>;
  updateSales: () => Promise<void> | undefined;
  transferMoney: (...args: any[]) => Promise<boolean | undefined>;
  refundAction: (...args: any[]) => Promise<IRefundAction | boolean | undefined>;
  claimAction: (...args: any[]) => Promise<IClaimAction | boolean | undefined>;
  joinSale: (...args: any[]) => Promise<void | undefined | boolean>;
  accountAddress: string;
  setAccountAddress: Dispatch<SetStateAction<string>>;
  setCurrentSaleId: Dispatch<SetStateAction<number | null>>;
  updateBalance: () => Promise<void | number> | undefined;
  updateSolanaDataInfo: () => Promise<void> | undefined;
  getCurrentSale: (...args: any[]) => Promise<ISaleModel[] | undefined>;
}

export interface IProjectModel {
  title: string;
  ticker: string;
  logoUrl: string;
  description: string;
  telegramUrl: string;
  twitterUrl: string;
  mediumUrl: string;
  shortDescription: string;
}

export interface IDistributeScheduleModel {
  timestamp: number;
  quota: number;
  steps: number;
}

export interface ISaleModel {
  typeClaim: {
    claimAvailable: boolean,
    refundAvailable: boolean
  };
  amountsValue: {
    claimAmount: number,
    purchaseAmount: number,
    refAmount: string,
  }
  participant: IParticipantModel | null;
  isParticipant: boolean;
  isClaimAvailable: boolean;
  isRefundAvailable: boolean;
  depositDecimal: number;
  currentDeposit: number;
  distributeSchedule: IDistributeScheduleModel[];
  endDate: number;
  maxDeposit: number;
  minDeposit: number
  saleId: number;
  saleType: string;
  startDate: number;
  status: EStatuses;
  targetDeposit: number;
  tokenIcon: string;
  name: string;
  currency: string;
  allocationType: string;
  project: IProjectModel;
  depositMint: PublicKey;
  depositAccount: PublicKey;
  distributeAccount: PublicKey;
  distributeMint: PublicKey;
  targetDistribute: number;
  filterByStatus: EFilterByStatus,
  filterByParticipation?: EFilterByParticipation,
  filterByResult?: EFilterByResult,
  vesting: IVesting[] | null,
  vestingType: ETypeVesting | null,
  distributionIn: IDistributionIn | null
}

export interface IParticipantModel {
  claimedAmount: number;
  depositedAmount: number;
  payer: string;
  sale: string;
  isRefunded: boolean;
}

export interface IJoinSaleAction {
  programId: PublicKey;
  publicKey: PublicKey;
  connection: Connection;
  signTransaction: ((transaction: Transaction) => Promise<Transaction>) | undefined;
  program: Program<BocachicaMoon> | null;
  sendTransaction: (...args: any[]) => Promise<string>;
  method: string;
  launchpadInstance: PublicKey,
  id: BN | number;
}

export interface IDepositAction {
  value: number | BN,
  program: Program<BocachicaMoon> | null,
  provider: Provider | null;
  publicKey: PublicKey | null,
  sale: any,
  connection: Connection,
  signTransaction: ((transaction: Transaction) => Promise<Transaction>) | undefined,
  sendTransaction: (...args: any[]) => Promise<string>,
  launchpadInstance: PublicKey,
  id: BN | number,
  programId: PublicKey,
}

export interface IClaimAction {
  program: Program<BocachicaMoon> | null,
  publicKey: PublicKey | null,
  programId: PublicKey,
  launchpadInstance: PublicKey,
  saleId: number | BN,
  connection: Connection,
  distributeMint: PublicKey,
  distributeAccount: PublicKey,
  signTransaction: ((transaction: Transaction) => Promise<Transaction>) | undefined,
  sendTransaction: (...args: any[]) => Promise<string>,
}

export interface IRefundAction {
  program: Program<BocachicaMoon> | null,
  publicKey: PublicKey | null,
  programId: PublicKey,
  launchpadInstance: PublicKey,
  saleId: number | BN,
  connection: Connection,
  depositMint: PublicKey,
  depositAccount: PublicKey,
  signTransaction: ((transaction: Transaction) => Promise<Transaction>) | undefined,
  sendTransaction: (...args: any[]) => Promise<string>,
}

export interface ISmartContractData {
  address: string | PublicKey,
  data: any[];
}

export interface ISmartMetaObject {
  bump?: number;
  data?: string;
  saleId?: PublicKey;
}

export interface IFilter {
  filterByStatus: EStatuses,
  filterByParticipation: EFilterByParticipation,
  filterByResult: EFilterByResult,
}

export interface IClaimModel {
  claimAvailable: boolean;
  refundAvailable: boolean;
}

export interface ICheckSaleUpdate {
  endDate: number;
  startDate: number;
  distributionIn: IDistributionIn | null;
}

export interface VestingOutput {
  steps: number,
  quota: number,
  timestamp: number,
}

export interface IParseDate {
  year: number;
  month: string;
  day: string;
  hours: string;
  minutes: string;
}

export interface IVesting {
  dateObj: IParseDate;
  steps: number;
  quota: number;
  timestamp: number;
  status: EVestingStatus;
  previousVestingDate?: number,
}

export interface IUserVesting extends IVesting {
  amount: string;
  status: EVestingStatus;
}

export interface IDistributionIn {
  active: boolean,
  timestamp: number
}

export interface IUserDataByVesting {
  totalLocked: string;
  availableTokens: string;
  claimed: string;
  vestingArray: IUserVesting[];
  userTokensArray: {
    title: string;
    value: string;
  }[];
}

export enum ETypeVesting {
  OneTime = 'OneTime',
  Stepwise = 'Stepwise',
}

export enum EVestingStatus {
  SOON = 'SOON',
  ACTIVE = 'ACTIVE',
  ENDED = 'ENDED',
  AVAILABLE_TO_CLAIM = 'AVAILABLE_TO_CLAIM',
  EMPTY = 'EMPTY',
}

export enum ESalesLogo {
  HEADER,
  LOGO,
}
