import * as anchor from '@project-serum/anchor';
import { BN, Program } from '@project-serum/anchor';
import {
  EFilterByParticipation, ETypeVesting,
  IDepositAction,
  IJoinSaleAction,
  IParticipantModel,
  ISaleModel,
  ISmartContractData,
  ISmartMetaObject, IVesting,
} from 'shared/interfaces/interface';
import {
  detectStatus, getTypeClaim, getUserDataFilter, lamportsToAmount,
} from 'shared/helpers/util';
import {
  ContractViewMethods,
  ERoundType,
  EStatuses,
  SaleType,
  THOUSANDS_MULTIPLIER,
  TransactionCommitmentStatus,
} from 'shared/helpers/constans';
import {
  AccountInfo, Connection, ParsedAccountData, PublicKey, Transaction,
} from '@solana/web3.js';
import { BocachicaMoon } from 'shared/helpers/bocachica_moon';
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { initHapiCore } from '@hapi.one/core-cli';
import { hapiNetwork, hapiProgramId, programId as ID } from 'services/config';
import Big from 'big.js';
import {
  getCliffInfo, getVestingArray, getVestingType,
} from 'shared/helpers/vesting';
import ToastService from 'services/toastService/toastService';
import { t } from 'i18next';
import { calculateUserAmounts } from '../../views/Sale/helper';

export const getFindProgramAddressBySale = async (
  method: string,
  launchpadInstance: PublicKey,
  saleId: any,
  programId: PublicKey,
) => {
  const account = await anchor.web3.PublicKey.findProgramAddress(
    [
      Buffer.from(method),
      launchpadInstance.toBytes(),
      new Uint8Array(new BN(saleId).toArray('le', 8)),
    ],
    programId,
  );
  return account;
};

export const getFindProgramAddressByParticipant = async (
  accountAddress: any,
  programId: PublicKey,
  publicKey: PublicKey,
) => {
  if (!accountAddress || !publicKey) return;
  const account = await anchor.web3.PublicKey.findProgramAddress(
    [
      Buffer.from(ContractViewMethods.PARTICIPANT),
      accountAddress.toBytes(),
      publicKey.toBytes(),
    ],
    programId,
  );
  return account;
};

export const findSplTokenAccount = async (
  saleMint: PublicKey,
  connection: Connection,
  publicKey: PublicKey,
): Promise<{ pubkey: PublicKey; account: AccountInfo<ParsedAccountData> } | undefined> => {
  if (!saleMint) return;
  const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
    publicKey,
    {
      programId: TOKEN_PROGRAM_ID,
    },
  );
  if (tokenAccounts.value.length > 0) {
    // eslint-disable-next-line array-callback-return
    const account = tokenAccounts.value.find((profile: any) => {
      const { mint } = profile.account.data.parsed.info;
      if (mint === saleMint.toString()) {
        return profile;
      }
    });
    return account;
  }
  return undefined;
};
export const getSmartContractInfo = async (
  method: string,
  program: Program<BocachicaMoon> | null,
  launchpadInstance: PublicKey,
  programId: PublicKey,
): Promise<any> => {
  try {
    if (!program) return;
    let address: any;
    const launchpadData = await program.account.launchpad.fetch(launchpadInstance);
    const dataCountSales = launchpadData.saleCount.toNumber();
    const arraySalesAccounts = new Array(dataCountSales).fill('');
    const saleAccount = await Promise.allSettled(arraySalesAccounts.map(async (_, i) => {
      const saleId = i + 1;
      const [accountAddress] = await getFindProgramAddressBySale(
        method,
        launchpadInstance,
        saleId,
        programId,
      );
      address = accountAddress;
      if (method === ContractViewMethods.SALE) {
        return program.account.sale.fetch(accountAddress);
      }
      if (ContractViewMethods.SALE_METADATA === method) {
        return program.account.saleMetadata.fetch(address);
      }
    }));
    return {
      data: saleAccount.map(({ value }: any) => value),
      address,
    };
  } catch (e) {
    throw new Error(`error getting ${method}`);
  }
};

export const getCurrentContractObject = async (
  method: string,
  program: Program<BocachicaMoon> | null,
  launchpadInstance: PublicKey,
  programId: PublicKey,
  id: number,
) => {
  try {
    if (!program) return;
    const data = [];
    let response;
    const [accountAddress] = await getFindProgramAddressBySale(
      method,
      launchpadInstance,
      id,
      programId,
    );
    switch (method) {
      case ContractViewMethods.SALE:
        response = await program.account.sale.fetch(accountAddress);
        data.push(response);
        break;
      case ContractViewMethods.SALE_METADATA:
        response = await program.account.saleMetadata.fetch(accountAddress);
        data.push(response);
        break;
      default:
        data.push([]);
        break;
    }
    return {
      address: '',
      data,
    };
  } catch (e) {
    throw new Error(`error getting ${method}`);
  }
};

export const callSale = async (
  args: any,
) => {
  try {
    const {
      programId,
      publicKey,
      connection,
      signTransaction,
      program,
      sendTransaction,
      method,
      launchpadInstance,
      id,
    }: IJoinSaleAction = args;

    if (!program) return;

    const [accountAddress] = await getFindProgramAddressBySale(
      method,
      launchpadInstance,
      id,
      programId,
    );

    const [participantAccount, participantBump]: any = await getFindProgramAddressByParticipant(
      accountAddress,
      programId,
      publicKey,
    );
    try {
      const tx = program.transaction.joinSale(participantBump, {
        accounts: {
          payer: publicKey,
          systemProgram: anchor.web3.SystemProgram.programId,
          participant: participantAccount,
          sale: accountAddress,
        },

      });
      const { blockhash } = await connection.getLatestBlockhash();
      tx.recentBlockhash = blockhash;
      tx.feePayer = publicKey;

      let signedTransaction: anchor.web3.Transaction;
      if (signTransaction) {
        signedTransaction = await signTransaction(tx);
        const signature = await sendTransaction(signedTransaction);
        const confirmTransaction = await connection.confirmTransaction(signature, TransactionCommitmentStatus.FINALIZED);
        return confirmTransaction && confirmTransaction.value.err === null;
      }
    } catch (e) {
      console.error(`instruction ${e}`);
    }
  } catch (e) {
    console.error(`join ${e}`);
  }
};

export const createAssociatedTokenAccountInternal = async (
  owner: PublicKey,
  connection: Connection,
  saleMint: PublicKey,
  signTransaction: ((transaction: Transaction) => Promise<Transaction>) | undefined,
  sendTransaction: (...args: any[]) => Promise<string>,
) => {
  try {
    const associatedAccount = await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      saleMint,
      owner,
    );

    const transaction = new Transaction();
    transaction.add(
      Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        saleMint,
        associatedAccount,
        owner,
        owner,
      ),
    );
    const { blockhash } = await connection.getLatestBlockhash();
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = owner;
    let signedTransaction: anchor.web3.Transaction;
    if (signTransaction) {
      signedTransaction = await signTransaction(transaction);
      const signature = await sendTransaction(signedTransaction);
      const confirmTransaction = await connection.confirmTransaction(signature, TransactionCommitmentStatus.FINALIZED);
      return confirmTransaction && confirmTransaction.value.err === null;
    }
    return associatedAccount;
  } catch (e) {
    console.log(`Create ${e}`);
  }
};

export const deposit = async (
  args: any,
) => {
  try {
    const {
      value,
      program,
      provider,
      publicKey,
      sale,
      connection,
      signTransaction,
      sendTransaction,
      launchpadInstance,
      id,
      programId,
    }: IDepositAction = args;
    if (!program || !publicKey || !provider) return;
    const [accountAddress] = await getFindProgramAddressBySale(
      ContractViewMethods.SALE,
      launchpadInstance,
      id,
      programId,
    );

    const [participantAccount]: any = await getFindProgramAddressByParticipant(
      accountAddress,
      programId,
      publicKey,
    );

    if (!accountAddress || !participantAccount) return;
    const { depositMint } = sale;
    const depositToken = new Token(
      connection,
      depositMint,
      TOKEN_PROGRAM_ID,
      publicKey as any,
    );

    const { decimals } = await depositToken.getMintInfo();
    const to = await depositToken.getAccountInfo(sale.depositAccount);
    const amount = value * 10 ** decimals;
    const tokenAccount = await findSplTokenAccount(sale.depositMint, connection, publicKey);
    if (!tokenAccount) {
      ToastService.warn(t('Sale.Deposit'));
      return;
    }
    const network = new PublicKey(hapiNetwork);
    const HAPI_PROGRAM_ID = new PublicKey(
      hapiProgramId,
    );
    const hapiProgram = initHapiCore(HAPI_PROGRAM_ID, provider);
    const [riskAddress]: any = await hapiProgram.pda.findAddressAddress(
      network,
      Buffer.from(publicKey.toBytes()),
    );
    if (!riskAddress) return;
    const tx = await program.transaction.deposit(new BN(amount), {
      accounts: {
        payer: publicKey,
        tokenProgram: depositToken.programId,
        sale: accountAddress,
        participant: participantAccount,
        depositMint: sale.depositMint,
        riskAddress,
        payerTokenAccount: tokenAccount.pubkey,
        saleTokenAccount: to.address,
      },
    });
    const { blockhash } = await connection.getLatestBlockhash();
    tx.recentBlockhash = blockhash;
    tx.feePayer = publicKey;

    let signedTransaction: anchor.web3.Transaction;
    if (signTransaction) {
      signedTransaction = await signTransaction(tx);
      const signature = await sendTransaction(signedTransaction);
      const confirmTransaction = await connection.confirmTransaction(signature, TransactionCommitmentStatus.FINALIZED);
      return confirmTransaction && confirmTransaction.value.err === null;
    }
  } catch (e) {
    ToastService.error(t('Sale.Deposit'));
  }
};

export const claim = async (
  program: Program<BocachicaMoon> | null,
  publicKey: PublicKey | null,
  programId: PublicKey,
  launchpadInstance: PublicKey,
  saleId: number | BN,
  connection: Connection,
  distributeMint: PublicKey,
  distributeAccount: PublicKey,
  signTransaction: ((transaction: Transaction) => Promise<Transaction>) | undefined,
  sendTransaction: (...args: any[]) => Promise<string>,
) => {
  try {
    if (!program || !publicKey) return;
    const distributeToken = new Token(
      connection,
      distributeMint,
      TOKEN_PROGRAM_ID,
      publicKey as any,
    );

    const [accountAddress]: [PublicKey, number] = await getFindProgramAddressBySale(
      ContractViewMethods.SALE,
      launchpadInstance,
      saleId,
      programId,
    );
    const to = await distributeToken.getAccountInfo(distributeAccount);

    const [participantAccount]: any = await getFindProgramAddressByParticipant(
      accountAddress,
      programId,
      publicKey,

    );
    const tokenAccount = await findSplTokenAccount(distributeMint, connection, publicKey);
    let recipientAccount;
    if (tokenAccount) {
      recipientAccount = tokenAccount;
    } else {
      const createdAccount = await createAssociatedTokenAccountInternal(publicKey, connection, distributeMint, signTransaction, sendTransaction);
      if (createdAccount) {
        recipientAccount = await findSplTokenAccount(distributeMint, connection, publicKey);
      }
    }
    if (!tokenAccount || !recipientAccount) return;
    const tx = await program.transaction.claim({
      accounts: {
        payer: publicKey,
        tokenProgram: distributeToken.programId,
        launchpad: launchpadInstance,
        sale: accountAddress,
        participant: participantAccount,
        distributeMint,
        payerTokenAccount: recipientAccount.pubkey,
        saleTokenAccount: to.address,
      },
    });

    const { blockhash } = await connection.getLatestBlockhash();
    tx.recentBlockhash = blockhash;
    tx.feePayer = publicKey;

    let signedTransaction: anchor.web3.Transaction;
    if (signTransaction) {
      signedTransaction = await signTransaction(tx);
      const signature = await sendTransaction(signedTransaction);
      const confirmTransaction = await connection.confirmTransaction(signature, TransactionCommitmentStatus.FINALIZED);
      return confirmTransaction && confirmTransaction.value.err === null;
    }
  } catch (e) {
    console.log(e);
    ToastService.error(t('Sale.Claim'));
  }
};

export const refund = async (
  program: Program<BocachicaMoon> | null,
  publicKey: PublicKey | null,
  programId: PublicKey,
  launchpadInstance: PublicKey,
  saleId: number | BN,
  connection: Connection,
  depositMint: PublicKey,
  depositAccount: PublicKey,
  signTransaction: ((transaction: Transaction) => Promise<Transaction>) | undefined,
  sendTransaction: (...args: any[]) => Promise<string>,
) => {
  try {
    if (!program || !publicKey) return;
    const depositToken = new Token(
      connection,
      depositMint,
      TOKEN_PROGRAM_ID,
      publicKey as any,
    );
    const tokenAccount = await findSplTokenAccount(depositMint, connection, publicKey);
    if (!tokenAccount) return;

    const [accountAddress]: [PublicKey, number] = await getFindProgramAddressBySale(
      ContractViewMethods.SALE,
      launchpadInstance,
      saleId,
      programId,
    );
    const to = await depositToken.getAccountInfo(depositAccount);

    const [participantAccount]: any = await getFindProgramAddressByParticipant(
      accountAddress,
      programId,
      publicKey,
    );
    const tx = await program.transaction.refund({
      accounts: {
        payer: publicKey,
        tokenProgram: depositToken.programId,
        launchpad: launchpadInstance,
        sale: accountAddress,
        participant: participantAccount,
        depositMint,
        payerTokenAccount: tokenAccount.pubkey,
        saleTokenAccount: to.address,
      },
    });

    const { blockhash } = await connection.getLatestBlockhash();
    tx.recentBlockhash = blockhash;
    tx.feePayer = publicKey;

    let signedTransaction: anchor.web3.Transaction;
    if (signTransaction) {
      signedTransaction = await signTransaction(tx);
      const signature = await sendTransaction(signedTransaction);
      const confirmTransaction = await connection.confirmTransaction(signature, TransactionCommitmentStatus.FINALIZED);
      return confirmTransaction && confirmTransaction.value.err === null;
    }
  } catch (e) {
    console.error(`Claim ${e}`);
  }
};

export const formatParticipant = (
  participant: any,
  depositDecimal: number,
): IParticipantModel => {
  const claimedAmount = participant.claimedAmount.toNumber() > 0
    ? participant.claimedAmount.toNumber() : 0;
  const depositedAmount = participant.depositedAmount.toNumber() > 0
    ? participant.depositedAmount.toNumber() : 0;
  return {
    claimedAmount,
    depositedAmount,
    payer: participant.payer.toString(),
    sale: participant.sale.toString(),
    isRefunded: participant.isRefunded,
  };
};

export const getParticipant = async (
  publicKey: PublicKey | null,
  programId: PublicKey,
  program: Program<BocachicaMoon> | null,
  launchpadInstance: PublicKey,
  depositDecimal: number,
  saleId: BN,
) => {
  try {
    if (!publicKey || !program) return;
    const [accountAddress] = await getFindProgramAddressBySale(
      ContractViewMethods.SALE,
      launchpadInstance,
      saleId,
      programId,
    );

    const [participantAccount]: any = await getFindProgramAddressByParticipant(
      accountAddress,
      programId,
      publicKey,
    );
    if (!participantAccount) return;
    const participant = await program.account.participant.fetch(participantAccount);
    if (!participant) return;
    return formatParticipant(participant, depositDecimal);
  } catch (e) {
    console.warn(e);
  }
};

export const sortSales = (sales: ISaleModel[]) => sales.sort((a: { status: string }, b:{ status: string }) => {
  if (b.status === EStatuses.ACTIVE || (b.status === EStatuses.PENDING && a.status !== EStatuses.ACTIVE)) return 1;
  return -1;
});

export const formatSale = (sale: any, publicKey: PublicKey | null): ISaleModel => {
  const targetDeposit = sale.targetDeposit.toNumber();
  const currentDeposit = sale.currentDeposit.toNumber() > 0 ? sale.currentDeposit.toNumber() : 0;
  const saleType = JSON.stringify(SaleType.Amount) === JSON.stringify(sale.saleType) ? ERoundType.AMOUNT : ERoundType.SUBSCRIPTION;
  const status = detectStatus(sale.startDate.toNumber(), sale.endDate.toNumber(), saleType, currentDeposit, targetDeposit);
  const distributeSchedule = Object.values(sale.distributeSchedule).map((ds: any) => ({
    timestamp: ds.timestamp.toNumber() * THOUSANDS_MULTIPLIER,
    quota: ds.quota.toNumber(),
    steps: ds.steps,
  }));

  const startDate = sale.startDate.toNumber() * THOUSANDS_MULTIPLIER;
  const endDate = sale.endDate.toNumber() * THOUSANDS_MULTIPLIER;

  const vestingType: ETypeVesting | null = getVestingType(distributeSchedule);
  const vesting: IVesting[] | null = getVestingArray(distributeSchedule, vestingType, endDate);
  const distributionIn = getCliffInfo(distributeSchedule);
  const { project, ...onlyMeta } = sale.meta;
  const formatProject = {
    ...project,
    totalSupply: lamportsToAmount(+project?.totalSupply || 0, project?.decimals || 0),
  };
  const targetDistribute = sale.targetDistribute.toNumber();
  const amountsValue = calculateUserAmounts(
    sale.participant,
    {
      saleType,
      currentDeposit: currentDeposit ? Number(currentDeposit) : 0,
      targetDeposit: targetDeposit ? Number(targetDeposit) : 0,
      distributeSchedule,
      targetDistribute,
      depositDecimal: sale.depositDecimal,
    },
    publicKey,
  );

  const typeClaim = getTypeClaim(
    status,
    sale.isClaimAvailable,
    sale.isRefundAvailable,
    new Big(amountsValue.purchaseAmount),
    new Big(amountsValue.refAmount),
    sale && sale.participant ? sale.participant.isRefunded : false,
  );

  return {
    amountsValue,
    typeClaim,
    filterByStatus: status.toLowerCase(),
    filterByResult: getUserDataFilter(typeClaim).toLowerCase(),
    filterByParticipation: sale.filterByParticipation.toLowerCase(),
    participant: sale.participant,
    isParticipant: sale.isParticipant,
    isClaimAvailable: sale.isClaimAvailable,
    isRefundAvailable: sale.isRefundAvailable,
    depositDecimal: sale.depositDecimal,
    distributeMint: sale.distributeMint,
    depositAccount: sale.depositAccount,
    distributeAccount: sale.distributeAccount,
    depositMint: sale.depositMint,
    currentDeposit,
    startDate,
    endDate,
    saleId: sale.saleId.toNumber(),
    maxDeposit: sale.maxDeposit.toNumber(),
    minDeposit: sale.minDeposit.toNumber(),
    targetDeposit,
    targetDistribute,
    saleType,
    status,
    distributeSchedule,
    vesting,
    vestingType,
    distributionIn,
    ...onlyMeta,
    project: formatProject,
  };
};

export const getBalanceSaleById = async (
  sales: ISaleModel[],
  publicKey: PublicKey | null,
  currentSaleId: number | null,
  getBalance: (publicKey: PublicKey, mint: PublicKey | undefined) => Promise<null | number | undefined>,
) => {
  if (!currentSaleId || !publicKey) return 0;
  const sale = sales.find((s) => s.saleId === currentSaleId);
  return getBalance(publicKey, sale?.depositMint);
};

export const getSaleData = async (
  saleData: ISmartContractData | undefined,
  meta: ISmartContractData | undefined,
  publicKey: PublicKey | null,
  connection: Connection,
  programId: PublicKey,
  program: Program<BocachicaMoon> | null,
  launchpadInstance: PublicKey,
) => {
  if (!meta || !saleData) return [];
  try {
    return Promise.all(
      saleData.data.map(async (sale: any) => {
        const findMetaItem: ISmartMetaObject = meta.data.find((metaItem: any) => {
          if (metaItem) {
            return metaItem.saleId.toNumber() === sale.saleId.toNumber();
          }
          return null;
        }) || null;
        const depositToken = new Token(
          connection,
          sale.depositMint,
          TOKEN_PROGRAM_ID,
          publicKey as any,
        );

        const { decimals } = await depositToken.getMintInfo();

        const participant = await getParticipant(
          publicKey,
          programId,
          program,
          launchpadInstance,
          decimals,
          sale.saleId.toNumber(),
        ) || null;

        const newSale = {
          ...sale,
          meta: findMetaItem ? JSON.parse(<string>findMetaItem.data).metadata : null,
          depositDecimal: decimals,
          isParticipant: !!participant,
          filterByParticipation: participant ? EFilterByParticipation.JOINED : EFilterByParticipation.ALL,
          participant,
        };

        return formatSale(newSale, publicKey);
      }),
    );
  } catch (e) {
    console.warn(e);
  }
};
