import {
  SocialEnum,
}
  from 'shared/components/SocialNetwork';
import { BN } from '@project-serum/anchor';

import BG_PLACEHOLDER from 'assets/images/placeholder-bg.svg';
import BG_NORMAL from 'assets/images/background.svg';

import { EFilterByParticipation, EFilterByResult } from '../interfaces/interface';

export const ZERO = '0';
export const ROUTES = {
  HOME: '/',
  SWAP_BY_ID: '/:id',
};
export const toSwap = (id: number) => `/${id}`;
export enum ERoundType {
  SUBSCRIPTION= 'Subscription',
  AMOUNT = 'Amount',
}

export enum EStatuses {
  PENDING = 'Soon',
  ACTIVE = 'Open',
  CLOSED = 'Closed',
  ALL='ALL',
}

export enum BlockhainImgEnum {
  Near,
  Solana,
  Ethereum,
}
export const socials = [
  { value: 'https://medium.com/boca-chica', type: SocialEnum.Medium },
  { value: 'https://t.me/BocaChicaAnnouncement', type: SocialEnum.Telegram },
  { value: 'https://twitter.com/bocachica_io', type: SocialEnum.Twitter },
];

export const blockhainDomains: {
  value: string, type: BlockhainImgEnum, name: string}[] = [
    {
      value: 'https://mars.bocachica.io/',
      type: BlockhainImgEnum.Near,
      name: 'NEAR',
    },
    {
      value: 'https://moon.bocachica.io/',
      type: BlockhainImgEnum.Solana,
      name: 'Solana',
    },
    {
      value: '#',
      type: BlockhainImgEnum.Ethereum,
      name: 'Ethereum',
    },

  ];

export const TIMESTAMP = {
  ONE_DAY: 60 * 60 * 24,
  ONE_HOUR: 60 * 60,
  ONE_MINUTE: 60,
};

export const SaleType = {
  Amount: { amount: {} },
  Subscription: { subscription: {} },
};

export const defaultParticipant = {
  claimedAmount: 0,
  depositedAmount: 0,
  payer: '',
  sale: '',
  isRefunded: false,
};

export enum ContractViewMethods {
  SALE = 'sale',
  SALE_METADATA = 'sale_metadata',
  PARTICIPANT = 'participant'
}

export const FULL_CAPACITY: BN | number = 100000;
export const THOUSANDS_MULTIPLIER = 1000;
export const ONE_SECOND = 1000;
export const ONE_MINUTE = 60 * ONE_SECOND;
export const UPDATE_SALE_INTERVAL = 3 * ONE_SECOND;

export enum TransactionCommitmentStatus{
  FINALIZED = 'finalized',
}

export enum EDimensions {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  EXTRA_LARGE = 'EXTRA_LARGE',
  UNKNOWN = 'UNKNOWN',
}

export const DEFAULT_FILTER = {
  filterByStatus: EStatuses.ALL,
  filterByParticipation: EFilterByParticipation.ALL,
  filterByResult: EFilterByResult.ALL,
};

export const BG_IMAGES = {
  placeholder: BG_PLACEHOLDER,
  normal: BG_NORMAL,
};

export const CDN_PATH = {
  url: 'https://bocachica-static.fra1.digitaloceanspaces.com/img/moon',
};

export const NUMBER_PLACEHOLDER_CARD = Array.from(Array(5).keys());
export const NUMBER_PLACEHOLDER_ROW = Array.from(Array(6).keys());
export const NUMBER_PLACEHOLDER_VALUE = Array.from(Array(2).keys());

export const radiusProgressCircle = 7.335;

export const TEST_SALE_COVER = 'TEST_SALE_COVER';
