import React, {
  createContext, Dispatch, SetStateAction, useCallback, useContext, useState,
} from 'react';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';

type SendTransactionFunction = (...args: any[]) => Promise<string>;

interface ISendTransactionContext {
  sendTransaction: SendTransactionFunction;
  getBalance: (...args: any[]) => Promise<null | number | undefined>;
  getSolBalance: (...args: any[]) => Promise<null | number | undefined>;
  endpoint: string;
  balance: number;
  setBalance: Dispatch<SetStateAction<number>>
  loading: boolean;
  setLoading: Dispatch<boolean>
}
const SendTransactionContext = createContext<ISendTransactionContext>(
  {} as ISendTransactionContext,
);

export const useSendTransactionContext = () => useContext(SendTransactionContext);

export const SendTransactionProvider: React.FC<{
  endpoint: string;
}> = ({
  endpoint, children,
}) => {
  const [balance, setBalance] = useState< number >(0);
  const [loading, setLoading] = useState<boolean>(true);
  const getBalance = useCallback(async (key: PublicKey, mint: any) => {
    try {
      if (!key || !mint) return;
      let tokenOwner;

      const connection = new Connection(endpoint);
      const { value } = await connection.getTokenAccountsByOwner(
        new PublicKey(key),
        { mint: new PublicKey(mint) },
      );

      if (value.length > 0) {
        const walletTokenAddress = value[0].pubkey;
        const {
          value: { uiAmount },
        } = await connection.getTokenAccountBalance(walletTokenAddress);
        tokenOwner = uiAmount;
      } else {
        tokenOwner = 0;
      }

      return tokenOwner;
    } catch (e) {
      console.warn(e);
    }
  }, [endpoint]);

  const getSolBalance = useCallback(async (key: PublicKey) => {
    const connection = new Connection(endpoint);
    return connection.getBalance(new PublicKey(key));
  }, [endpoint]);

  const sendTransaction = async (transaction: Transaction): Promise<string> => {
    try {
      const connection = new Connection(endpoint);

      const transactionSignature = await connection.sendRawTransaction(
        transaction.serialize(),
      );

      await connection.getTransaction(transactionSignature, {
        commitment: 'finalized',
      });

      return transactionSignature;
    } catch (error) {
      throw new Error(error as string);
    }
  };

  return (
    <SendTransactionContext.Provider value={{
      sendTransaction,
      getBalance,
      getSolBalance,
      endpoint,
      balance,
      setBalance,
      loading,
      setLoading,
    }}
    >
      {children}
    </SendTransactionContext.Provider>
  );
};
