import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionContainer = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 2fr 1fr;
  gap: 1rem;
  & > div:first-child { 
    grid-area: 1 / 1 / 2 / 2;
  }
  & > p { 
    grid-area: 2 / 1 / 3 / 2;
  }
  & > button { 
    grid-area: 1 / 2 / 2 / 3;
  }
  & > div:last-child { 
    grid-area: 2 / 2 / 3 / 3;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    flex-direction: column;
  `}
`;

const AdditionalText = styled.p`
  color: ${({ theme }) => theme.globalBlack};
  font-size: .75rem;
  display: flex;
  font-family: ${({ theme }) => theme.interRegular}, serif;
  justify-content: center;
  align-items: center;
`;

export default {
  Container,
  ActionContainer,
  AdditionalText,
};
