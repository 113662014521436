import Big from 'big.js';
import {
  ETypeVesting,
  EVestingStatus,
  IDistributionIn,
  IParticipantModel,
  IUserDataByVesting,
  IUserVesting,
  IVesting,
  VestingOutput,
} from '../interfaces/interface';
import { displayAmount, ParseDate } from './util';
import { ZERO } from './constans';

export const getVestingType = (vesting: VestingOutput[] | null): ETypeVesting => (
  vesting && vesting.length === 1 && vesting.every((el) => el.steps === 1)
    ? ETypeVesting.OneTime
    : ETypeVesting.Stepwise
);

export const detectedVestingStatus = (
  vestingDate: number,
  vestingType: ETypeVesting | null,
  previousVestingDate?: number,
): EVestingStatus => {
  const currentDate = Date.now();
  switch (vestingType) {
    case ETypeVesting.OneTime: {
      if (currentDate < vestingDate) return EVestingStatus.ACTIVE;
      return EVestingStatus.ENDED;
    }
    case ETypeVesting.Stepwise: {
      if (
        !previousVestingDate && vestingDate > currentDate
      ) return EVestingStatus.ACTIVE;
      if (
        previousVestingDate
        && previousVestingDate < currentDate
        && vestingDate > currentDate
      ) return EVestingStatus.ACTIVE;
      if (previousVestingDate && previousVestingDate > currentDate) return EVestingStatus.SOON;

      return EVestingStatus.ENDED;
    }
    default: {
      return EVestingStatus.ENDED;
    }
  }
};

const calculateVestingStep = (
  vestingDate: number[],
  vestingItem: VestingOutput,
  index: number,
  previousVestingDate: number,
) => {
  const progressionDifference = (vestingDate[index] - previousVestingDate) / vestingItem.steps;
  const quotaPerStep = vestingItem.quota / vestingItem.steps;
  let i = previousVestingDate;
  const newElement: IVesting[] = [];
  for (let s = 0; i !== vestingDate[index]; s += 1) {
    const date = i + progressionDifference;
    const newDateObj = ParseDate(date);
    newElement.push({
      previousVestingDate: i,
      steps: 1,
      quota: quotaPerStep,
      timestamp: date,
      dateObj: newDateObj,
      status: EVestingStatus.ENDED,
    });
    i += progressionDifference;
  }
  return newElement;
};

const createVestingArray = (
  vesting: VestingOutput[],
  vestingDate: number[],
  saleEndDate: number,
) => {
  return vesting.map((vestingItem, index) => {
    const previousVestingDate = vestingDate[index - 1]
      ? vestingDate[index - 1]
      : saleEndDate;
    if (vestingItem.steps > 1) {
      return calculateVestingStep(
        vestingDate,
        vestingItem,
        index,
        previousVestingDate,
      );
    }
    const status = detectedVestingStatus(vestingItem.timestamp, ETypeVesting.Stepwise, previousVestingDate);

    const dateObj = ParseDate(vestingItem.timestamp);
    return {
      ...vestingItem,
      previousVestingDate,
      dateObj,
      status,
    };
  });
};

export const getVestingArray = (
  vesting: VestingOutput[] | null,
  vestingType: ETypeVesting | null,
  saleEndDate: number,
): IVesting[] | null => {
  if (!vesting) return null;
  switch (vestingType) {
    case ETypeVesting.OneTime:
      return vesting
        .map((el) => {
          const status = detectedVestingStatus(el.timestamp, vestingType);
          const dateObj = ParseDate(el.timestamp);
          return {
            ...el,
            dateObj,
            status,
          };
        });
    case ETypeVesting.Stepwise: {
      const vestingDate = vesting.map((el) => (el.timestamp));
      return createVestingArray(
        vesting,
        vestingDate,
        saleEndDate,
      )
        .flat()
        .filter((el) => el.quota !== 0);
    }
    default: {
      return null;
    }
  }
};

export const getAmountUserTokensArray = (
  depositDecimal: number,
  totalLocked: string,
  availableTokens: string,
  claimed: number | string,
) => [
  {
    title: 'Sale.TotalLocked',
    value: displayAmount(totalLocked, depositDecimal),
  },
  {
    title: 'Sale.AvailableTokens',
    value: displayAmount(availableTokens, depositDecimal),
  },
  {
    title: 'Sale.Claimed',
    value: displayAmount(claimed, depositDecimal),
  },
];

export const getUserDataByVesting = (
  depositDecimal: number,
  vestingArray: IVesting[],
  vestingType: ETypeVesting | null,
  purchase: string | number,
  isClaimAvailable: boolean,
  participantData: IParticipantModel,
): IUserDataByVesting => {
  let vestingClaimedAmount = participantData.claimedAmount.toFixed();
  const vestingArrayWithClaim: IUserVesting[] = vestingArray.map((vesting) => {
    const amount = Big(purchase).mul(vesting.quota).div(100000).toFixed();
    const status = detectedVestingStatus(vesting.timestamp, vestingType, vesting.previousVestingDate);
    switch (status) {
      case EVestingStatus.ENDED: {
        if (!Big(vestingClaimedAmount).lte(ZERO)) {
          vestingClaimedAmount = Big(vestingClaimedAmount).minus(amount).toFixed();
          return {
            ...vesting,
            amount,
            status: EVestingStatus.EMPTY,
          };
        }
        return {
          ...vesting,
          amount,
          status: EVestingStatus.AVAILABLE_TO_CLAIM,
        };
      }

      default:
        return {
          ...vesting,
          amount,
          status,
        };
    }
  });

  const { claimedAmount } = participantData;
  const availableTokens = vestingArrayWithClaim.reduce((acc: string, vestingItem) => {
    if (vestingItem.status === EVestingStatus.AVAILABLE_TO_CLAIM) {
      return Big(vestingItem.amount).plus(acc).toFixed();
    }
    return acc;
  }, ZERO);
  const totalLocked = Big(purchase).minus(availableTokens).minus(claimedAmount).toFixed();
  const userTokensArray = getAmountUserTokensArray(
    depositDecimal,
    totalLocked,
    availableTokens,
    claimedAmount.toString(),
  );

  return {
    totalLocked,
    availableTokens,
    claimed: claimedAmount.toString(),
    vestingArray: vestingArrayWithClaim,
    userTokensArray,
  };
};

export const detectActiveVesting = (
  vestingArray: IUserVesting[],
  vestingType: ETypeVesting | null,
): IVesting | null => {
  switch (vestingType) {
    case ETypeVesting.OneTime: {
      const vesting = vestingArray[0];
      return vesting.status === EVestingStatus.ACTIVE ? vesting : null;
    }

    case ETypeVesting.Stepwise: {
      const activeVesting = vestingArray.find((el) => el.status === EVestingStatus.ACTIVE);
      return activeVesting || null;
    }
    default: {
      return null;
    }
  }
};

export const getCliffInfo = (formattedVesting: VestingOutput[] | null): IDistributionIn | null => {
  if (!formattedVesting) return null;
  const cliff = formattedVesting[0] || null;
  if (!cliff || formattedVesting[0].quota !== 0) return null;
  return {
    active: cliff.timestamp > Date.now(),
    timestamp: cliff.timestamp,
  };
};

export const checkVestingShouldUpdate = async (
  vestingArray: IUserVesting[],
  vestingType: ETypeVesting | null,
  vestingEnded: boolean,
  lastCheck: boolean,
  isUpdatedAfterCliff: boolean,
  cliff: IDistributionIn | null,
) => {
  if (vestingEnded) return !lastCheck;
  if (!isUpdatedAfterCliff && cliff && cliff.active && cliff.timestamp < Date.now()) return true;
  const newActiveVesting = detectActiveVesting(vestingArray, vestingType);
  if (!newActiveVesting || newActiveVesting.timestamp > Date.now()) return false;
  return true;
};
