import styled from 'styled-components';
import { PropsWithChildren } from 'react';

export const ButtonConnect = styled.button<PropsWithChildren<{loading?: string}>>`
  width: 8.063rem;
  height: 2.25rem;
  background: ${({ theme, loading }) => (loading ? theme.opacityGrey : theme.globalBlue)};
  border-radius: 0.5rem;
  outline: none;
  border: none;
  font-family: ${({ theme }) => theme.interSemiBold},serif;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, loading }) => (loading ? theme.transparent : theme.globalWhite)};
  cursor: pointer;
  font-weight: 600;
  font-size: .75rem;
  line-height: .938rem;
  & > svg {
    margin-right: .5rem;
    opacity: ${({ loading }) => (loading ? '0' : '1')};
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-weight: 400;
  `}
`;

const Primary = styled.button<{ isActive?: boolean, isApply?: boolean }>`
  font-family: ${({ theme }) => theme.interMedium},serif;
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.globalBlue};
  color: ${({ theme }) => theme.globalWhite};
  border-radius: 0.5rem;
  padding: 0 .5rem;

  font-weight: 600;
  font-size: 1rem;
  line-height: 1.188rem;

  max-height: 48px;
  min-height: 36px;
  transition: background .3s;
  :hover {
    background: ${({ theme, isActive, isApply }) => {
    if (isActive) {
      return theme.highlightBlue;
    }
    if (isApply) {
      return theme.pressedBlue;
    }
    return theme.highlightBlue;
  }};
  }
  :disabled{
    cursor: default;
    background: ${({ theme }) => theme.globalGrey};
  }
`;

const Secondary = styled(Primary)`
  color: ${({ theme }) => theme.globalBlue};
  background: ${({ theme }) => theme.blueBgBtn};
`;

const Filter = styled(Primary)<{ isActive?: boolean }>`
  border-radius: 0.25rem;
  color: ${({ theme, isActive }) => (isActive ? theme.globalWhite : theme.globalBlue)};
  background: ${({ theme, isActive }) => (isActive ? theme.globalBlue : theme.blueBgBtn)};
  :hover {
    background: ${({ theme, isActive }) => (isActive ? theme.pressedBlue : theme.highlightBlue)};
  }
  :disabled{
    cursor: not-allowed;
    color: ${({ theme, isActive }) => (isActive ? theme.globalWhite : theme.globalBlue)};
    background: ${({ theme, isActive }) => (isActive ? theme.globalBlue : theme.lightBlueFilter)};
    opacity: ${({ isActive }) => (!isActive ? '0.4' : '1')};
  }
`;

export default {
  Primary,
  Secondary,
  Filter,
};
