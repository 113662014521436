import { IConfig } from './config';
import development from './env-defaults/development';
import mainnet from './env-defaults/mainnet';

enum EEnvironment {
  MAINNET = 'mainnet-beta',
  DEVELOPMENT = 'dev',
}

const environments: { [key in EEnvironment]: IConfig } = {
  [EEnvironment.MAINNET]: mainnet,
  [EEnvironment.DEVELOPMENT]: development,
};

const currentEnvironment: EEnvironment = process.env.REACT_APP_MAIN_ENV as EEnvironment || EEnvironment.DEVELOPMENT;

export const {
  network,
  networkId,
  nodeUrl,
  programId,
  launchpadExchange,
  hapiNetwork,
  hapiProgramId,
  environment,
}: IConfig = {
  ...environments[currentEnvironment],
};
