import { toast } from 'react-toastify';
import { t } from 'i18next';

export default class ToastService {
  static loading(action: string): void {
    const content = t('General.Toast.InProgress', { action });
    toast.loading(content);
  }

  static success(action: string): void {
    const content = t('General.Toast.Success', { action });
    toast.dismiss();
    toast.success(content);
  }

  static error(action: string): void {
    const content = t('General.Toast.Failed', { action });
    toast.dismiss();
    toast.error(content);
  }

  static warn(action: string): void {
    const content = t('General.Toast.Failed', { action });
    toast.dismiss();
    toast.warn(content);
  }
}
