import styled from 'styled-components';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import { ReactComponent as FilterIcon } from 'assets/images/sliders.svg';
import { PropsWithChildren } from 'react';

const ActionWrapper = styled.div<PropsWithChildren<{loading?: string}>>`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.interSemiBold}, serif;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.globalWhite};
  opacity: ${({ loading }) => (loading ? '0' : '1')};
`;

const LogoContainer = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
`;

const RedDot = styled.div`
  position: absolute;
  width: .5rem;
  height: .5rem;
  right: -1px;
  border-radius: 50%;
  background: ${({ theme }) => theme.redDot};
  border: 2px solid ${({ theme }) => theme.globalBlack};
`;

const SearchImg = styled(SearchIcon)`
  cursor: pointer;
`;

const FilterImg = styled(FilterIcon)`
  cursor: pointer;
`;

export default {
  ActionWrapper,
  SearchImg,
  FilterImg,
  LogoContainer,
  RedDot,
};
