import { useEffect, useState } from 'react';

import { detectStatus, detectTimestamp } from '../helpers/util';
import { EStatuses, ONE_SECOND, THOUSANDS_MULTIPLIER } from '../helpers/constans';

const useDateAndStatus = (
  startDate: number,
  endDate: number,
  saleStatus: EStatuses,
  saleType: string | undefined,
  currentDeposit: number | string,
  targetDeposit: number | string,
) => {
  const status = detectStatus(startDate / THOUSANDS_MULTIPLIER, endDate / THOUSANDS_MULTIPLIER, saleType, currentDeposit, targetDeposit);
  const timestamp = detectTimestamp(startDate, endDate, saleStatus);
  const [timeLeft, setTimeLeft] = useState<number>(timestamp);
  const [newStatus, setNewStatus] = useState<EStatuses>(saleStatus || status);

  useEffect(() => {
    const updateStatus = () => {
      if (saleStatus === EStatuses.CLOSED) return;
      const newCalcDate = detectTimestamp(startDate, endDate, saleStatus);
      const newCalcStatus = detectStatus(startDate / THOUSANDS_MULTIPLIER, endDate / THOUSANDS_MULTIPLIER, saleType, currentDeposit, targetDeposit);
      setTimeLeft(newCalcDate);
      setNewStatus(newCalcStatus);
    };
    updateStatus();
    const interval = setInterval(updateStatus, ONE_SECOND);
    return () => clearInterval(interval);
  }, [saleStatus, endDate, startDate, status, saleType, currentDeposit, targetDeposit]);
  return ({ timeLeft, newStatus });
};

export default useDateAndStatus;
